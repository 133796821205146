import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type {
  TradeCaptureBulkRequestWrapper,
  TradeCaptureBulk,
  BulkTradeCaptureRequestLegProperties,
} from 'api/tradeCapture/bulk/tradeCaptureModel';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { IFxBulkInputs, FxBulkLegInputs, BulkLineProduct } from 'state/fxBulks/fxBulksModel';
import type { Legs } from 'api/tradeCapture/tradeCaptureModel';
import type { DateInputCultureInfo } from 'state/userPreferences';
import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import { clearUndefined } from 'utils/clearUndefined';
import { isDefinedAndNonEmpty, isEmpty } from '@sgme/fp';

interface WithBulkId {
  bulkId: string;
}

export type BulkStoreModelChanges = Partial<IFxBulkInputs> & Legs<FxBulkLegInputs>;
export type TradeCaptureToBackendMetaSelectorsKeys =
  | 'getBulkTradeCaptureIdVersion'
  | 'getUserPreferenceData'
  | 'getBulkLastLegIdRequested'
  | 'getBulkExcludedLegs';

export type TradeCaptureToBackendMetaSelectorSelectors = Pick<
  Selectors,
  TradeCaptureToBackendMetaSelectorsKeys
>;

export type TradeCaptureToBackendMetadata = {
  patch: BulkStoreModelChanges;
} & WithBulkId;

export const metaSelectorTradeCaptureToBackendWith: MapStateToMetadataHOF<
  TradeCaptureBulkRequestWrapper,
  TradeCaptureToBackendMetadata,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> =
  sl =>
  (state, { bulkId, patch }) => {
    const idVersion = getNextIdVersion(sl.getBulkTradeCaptureIdVersion(state, bulkId));
    const changedBulkFields = metaSelectorBulkToTradeCapturePatchWith(sl)(state, patch);

    const legIdToAdd = sl.getBulkLastLegIdRequested(state, bulkId);
    const defaultLeg = {
      [legIdToAdd]: {
        productName: 'FxSpot' as BulkLineProduct,
      },
    };

    const excludedLegs = sl.getBulkExcludedLegs(state, bulkId);

    const legs =
      patch.legs === undefined || isEmpty(Object.keys(patch.legs))
        ? defaultLeg
        : Object.entries(patch.legs).reduce(
            (acc, [legId, leg]) => {
              if (!excludedLegs.includes(legId)) {
                acc[legId] = metaSelectorBulkLegToTradeCapturePatchWith(sl)(state, leg || {});
              }
              return acc;
            },
            {} as {
              [legId: string]: Partial<BulkTradeCaptureRequestLegProperties>;
            },
          );
    return {
      idVersion,
      changedFields: {
        ...changedBulkFields,
        legs,
      },
    };
  };

// ---------------------------------------------------------------------------------
// Private metaSelector
// ---------------------------------------------------------------------------------
function getNextIdVersion(lastIdVersion: number | null): number {
  return lastIdVersion == null ? 0 : lastIdVersion + 1;
}

const metaSelectorBulkToTradeCapturePatchWith: MapStateToMetadataHOF<
  Partial<TradeCaptureBulk>,
  Partial<IFxBulkInputs>,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> = _sl => (_state, patch) =>
  clearUndefined({
    currencyPair: patch.currencyPair,
    negotiatedCurrency: patch.amountCurrency,
  });

const metaSelectorBulkLegToTradeCapturePatchWith: MapStateToMetadataHOF<
  Partial<BulkTradeCaptureRequestLegProperties>,
  Partial<FxBulkLegInputs>,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> = sl => (state, patch) => {
  const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);

  const isLegSwap = patch.product === 'FxSwap' || patch.product === 'FxNdSwap';

  const paymentDateString = dateToPatch(patch.paymentDate, patch.paymentDateTenor, cultureInfo);

  const farPaymentDateString = dateToPatch(
    patch.farPaymentDate,
    patch.farPaymentDateTenor,
    cultureInfo,
  );

  const { amountCurrency } = patch;

  const amount1String = amountCurrency === 1 ? patch.amount : undefined;
  const amount2String = amountCurrency === 2 ? patch.amount : undefined;

  const farAmount1String = amountCurrency === 1 ? patch.farAmount : undefined;
  const farAmount2String = amountCurrency === 2 ? patch.farAmount : undefined;

  return clearUndefined({
    productName: patch.product,
    isUneven: isLegSwap ? patch.isUneven : undefined,
    way: patch.way,
    maturityDateString: isLegSwap ? undefined : paymentDateString,
    nearPaymentDateString: isLegSwap ? paymentDateString : undefined,
    farPaymentDateString: isLegSwap ? farPaymentDateString : undefined,

    amount1String: isLegSwap ? undefined : amount1String,
    nearAmount1String: isLegSwap ? amount1String : undefined,
    farAmount1String: isLegSwap ? farAmount1String : undefined,

    amount2String: isLegSwap ? undefined : amount2String,
    nearAmount2String: isLegSwap ? amount2String : undefined,
    farAmount2String: isLegSwap ? farAmount2String : undefined,
  });
};

function dateToPatch(
  date: string | undefined | null,
  tenor: string | undefined | null,
  cultureInfo: DateInputCultureInfo,
) {
  if (date === null) {
    return null;
  }
  if (isDefinedAndNonEmpty(date)) {
    return parseWithCultureInfo(cultureInfo, date);
  }
  if (isDefinedAndNonEmpty(tenor)) {
    return tenor;
  }
  return undefined;
}
