import type { AppState } from 'state/model';
import type { IWorkspaceTileState } from 'state/clientWorkspace';
import { throwIfUndefined } from 'utils/maps';
import type { CollapsableKey } from '../clientWorkspaceModel';
import type { Instrument } from 'state/referenceData';

export function getTileState(state: AppState, tileId: string): IWorkspaceTileState {
  return throwIfUndefined(
    state.clientWorkspace.tiles[tileId],
    `State for tile ${tileId} does not exist`,
  );
}

export function getTilesKeysByInstrument(state: AppState, instrument: Instrument): string[] {
  return Object.entries(state.clientWorkspace.tiles)
    .filter(([_key, tile]) => tile && tile.instrument === instrument)
    .map(([key, _tile]) => key);
}

export function isTilePresent(state: AppState, tileId: string) {
  return state.clientWorkspace.tiles.hasOwnProperty(tileId);
}

export function isKeyCollapsedInTile(state: AppState, tileId: string, keyName: CollapsableKey) {
  return !getTileState(state, tileId).expanded.includes(keyName);
}

export function getTileOverriddenClientId(state: AppState, quoteId: string) {
  return getTileState(state, quoteId).overrideClientId;
}

export function isTileClientIdOverridden(state: AppState, quoteId: string) {
  return getTileOverriddenClientId(state, quoteId) !== null;
}
