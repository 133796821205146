/**
 * @private
 * these exports are shared helper for exported selectors
 *
 * don't export this file in barrel ('./index.ts')
 */

import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';

export function getCashState(state: AppState, tileId: string) {
  return throwIfUndefined(
    state.fxCashs.cashs[tileId],
    `Cash state for tile ${tileId} does not exist`,
  );
}
