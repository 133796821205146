import { AccumulatorCollapseButton } from '../../../FxAccumulatorTile/Extension/AccumulatorCollapseButton';
import styled from 'styled-components';

interface CollapseBlockProps {
  isCollapsed: boolean;
  children: JSX.Element;
  title: JSX.Element;
  toggleCollapsed: React.MouseEventHandler<HTMLElement>;
}

const SummaryWIthoutBefore = styled.summary`
  &::before {
    display: none;
  }
`;

export function CollapsableBlockComponent({
  isCollapsed,
  children,
  title,
  toggleCollapsed,
}: CollapseBlockProps) {
  return (
    <details className="border mx-3 my-1" open={isCollapsed}>
      <SummaryWIthoutBefore
        className="d-flex justify-content-between pr-2 list-unstyled"
        onClick={toggleCollapsed}
      >
        <span className="nav-link active">{title}</span>
        <div className="pt-2">
          <AccumulatorCollapseButton isCollapsed={isCollapsed} />
        </div>
      </SummaryWIthoutBefore>
      <div>{children}</div>
    </details>
  );
}
