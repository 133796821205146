import * as optionActions from './fxOptions/actions';
import * as optionThunks from './fxOptions/fxOptionsThunks';
import * as cashActions from './fxCashs/fxCashsActions';
import * as cashThunks from './fxCashs/fxCashsThunks';
import * as swapActions from './fxSwaps/fxSwapsActions';
import * as swapThunks from './fxSwaps/thunks';
import * as bulkActions from './fxBulks/fxBulksActions';
import * as bulkThunks from './fxBulks/fxBulksThunks';
import * as accumulatorActions from './fxAccumulators/actions';
import * as accumulatorThunks from './fxAccumulators/thunks';
import * as espActions from './esp/espStreamsActions';
import * as espThunks from './esp/espStreamsThunks';
import * as tileActions from './tile/tileActions';
import * as tileThunks from './tile/tileThunks';
import * as referenceDataActions from './referenceData/referenceDataActions';
import * as referenceDataThunks from './referenceData/referenceDataThunks';
import * as streamingConnectionActions from './streamingConnection/streamingConnectionActions';
import * as initStreamThunks from './streamingConnection/initStreamThunk';
import * as clientWorkspaceActions from './clientWorkspace/clientWorkspaceActions';
import * as clientWorkspaceThunks from './clientWorkspace/clientWorkspaceThunks';
import * as gridLayoutActions from './gridLayout/gridLayoutActions';
import * as gridLayoutThunks from './gridLayout/gridLayoutThunks';
import * as sessionActions from './session/sessionActions';
import * as userPreferencesActions from './userPreferences/userPreferencesActions';
import * as userPreferencesThunks from './userPreferences/thunks';
import * as blotterActions from './blotter/action/blotterActions';
import * as blotterDetailsPanelActions from './blotter/action/blotterDetailsPanelActions';
import * as blotterOrderActions from './blotter/action/blotterOrderActions';
import * as blotterClickActionThunks from './blotter/thunks/blotterActionsThunks';
import * as blotterModeThunks from './blotter/thunks/blotterModeThunks';
import * as blotterPanelThunks from './blotter/thunks/blotterPanelThunks';
import * as blotterTileThunks from './blotter/thunks/blotterTileThunks';
import * as freeLayoutActions from './freeLayout/freeLayoutActions';
import * as orderActions from './fxOrders/fxOrdersActions';
import * as orderThunks from './fxOrders/thunks';
import * as mifid2PanelActions from './mifidPanel/mifidPanelActions';
import * as globalErrorActions from './globalError/globalErrorActions';
import * as globalActions from './globalActions';
import * as toastsActions from './toasts/toastsActions';
import * as smartRfsActions from './smartRfs/smartRfsActions';

export const actionCreators = {
  ...optionActions,
  ...optionThunks,
  ...cashActions,
  ...cashThunks,
  ...swapActions,
  ...swapThunks,
  ...bulkActions,
  ...bulkThunks,
  ...accumulatorActions,
  ...accumulatorThunks,
  ...espActions,
  ...espThunks,
  ...tileActions,
  ...tileThunks,
  ...referenceDataActions,
  ...referenceDataThunks,
  ...streamingConnectionActions,
  ...initStreamThunks,
  ...clientWorkspaceActions,
  ...clientWorkspaceThunks,
  ...gridLayoutActions,
  ...gridLayoutThunks,
  ...sessionActions,
  ...userPreferencesActions,
  ...userPreferencesThunks,
  ...blotterActions,
  ...blotterDetailsPanelActions,
  ...blotterOrderActions,
  ...blotterClickActionThunks,
  ...blotterModeThunks,
  ...blotterPanelThunks,
  ...blotterTileThunks,
  ...freeLayoutActions,
  ...orderActions,
  ...orderThunks,
  ...mifid2PanelActions,
  ...globalErrorActions,
  ...globalActions,
  ...toastsActions,
  ...smartRfsActions,
};

export type ActionCreators = typeof actionCreators;
export type { Action } from './globalActions';
