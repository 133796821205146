import type { Collection } from '../../../typings/utils';
import type { IFormData } from '../../share/form';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type { IPatch } from 'state/share/patchModels';

export type FxOptionHedgeStateMap = Readonly<Collection<FxOptionHedgeState>>;

export type FxOptionHedgeState = IFormData<IFxHedgeValues, IFxHedgeInputs>;

export interface IFxHedgeValues {
  /** hedgePrice is spot ref or forward ref (hedge rate in the next UI).  (midRate in stream options redux) */
  rate: number | null;
  paymentDate: string | null;
  amount: number | null;
  currency: CurrencyChoice;
}

export interface IFxHedgeInputs {
  /** hedgePrice is spot ref or forward ref (hedge rate in the next UI).  (midRate in stream options redux) */
  rate: string | null;
  amount: string | null;
  currency: CurrencyChoice;
}

// ID used when the feature toggle of multi-hedges is disabled to create a single hedge on the client from
// the date returned by trade capture in the option model
export const PRE_MULTI_HEDGES_HEDGE_ID = 'hedge-preMultiHedges';

export type FxOptionHedgesPatchedValuesRecords = Record<string, FxOptionHedgePatchedValues>;

export type FxOptionHedgePatchedValues = IPatch<IFxHedgeValues>;
