import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { assertUnreachable } from '@sgme/fp';
import { selectors } from 'state/selectors';
import type { ExecutionStatus as ExecutionStatusModel } from 'state/executions/executionsStateModel';
import { tileContainerPadding } from 'styles/constants';

import { withQuoteId } from '../contexts';
import { EmphasizedIcon } from './EmphasizedIcon';
import { createSwitchChildren, defaultSwitch } from './SwitchChildren';
import { mapStateToPropsInstrument } from './connect/connectInstrument';
import { connectTileExecutionStatus } from './connect';
import { tileInstruments, type Instrument } from 'state/referenceData/referenceDataModel';
import * as e2e from 'components/Form/Inputs/e2e';

const Overlay = styled.div`
  top: ${tileContainerPadding}px;
  bottom: ${tileContainerPadding}px;
  left: ${tileContainerPadding}px;
  right: ${tileContainerPadding}px;
  z-index: 8;
`;

interface ExecutionOverlayProps {
  executionStatus: ExecutionStatusModel | null;
  overlay?: boolean;
  instrument?: Instrument;
}

const ExecutionOverlay: React.FunctionComponent<ExecutionOverlayProps> = ({
  executionStatus,
  overlay,
  instrument,
}) => {
  if (executionStatus === null) {
    return null;
  }

  const color = getColorForStatus(executionStatus, instrument);
  const instrumentMessage =
    instrument === 'Order' || instrument === 'BlotterOrder'
      ? 'orderSubmission'
      : 'executionOverlay';
  return color !== undefined ? (
    <Overlay
      className={`d-flex flex-column align-items-center justify-content-center py-3 ${
        overlay === true ? 'position-absolute bg-lvl1' : ''
      } text-${color}`}
      data-e2e={e2e.overlay('executionStatus')}
      data-e2e-status={executionStatus}
    >
      <div className="mb-3">
        <EmphasizedIcon icon={getSymbolForStatus(executionStatus)} color={color} />
      </div>
      <h4 data-e2e="order-status-text">
        <FormattedMessage id={`${instrumentMessage}.status.${executionStatus}.title`} />
      </h4>
      <p className="text-secondary">
        <FormattedMessage id={`${instrumentMessage}.status.${executionStatus}.message`} />
      </p>
    </Overlay>
  ) : null;
};

const getColorForStatus = (status: ExecutionStatusModel, instrument: Instrument | undefined) => {
  switch (status) {
    case 'Pending':
    case 'StillPending':
      return 'warning';
    case 'Success':
      return instrument === 'Order' ? 'info' : 'success';
    case 'BackOfficeValidated':
    case 'NotIntegrated':
      return 'success';
    case 'Error':
    case 'TimeoutedDeal':
      return 'danger';
    default:
      assertUnreachable(status, 'Execution status not handled');
  }
};

const getSymbolForStatus = (status: ExecutionStatusModel) => {
  switch (status) {
    case 'Pending':
    case 'StillPending':
      return 'hourglass_empty';
    case 'Success':
    case 'BackOfficeValidated':
    case 'NotIntegrated':
      return 'check';
    case 'TimeoutedDeal':
    case 'Error':
      return 'warning';
    default:
      assertUnreachable(status, 'Execution status not handled');
  }
};

const TileExecutionStatus = withQuoteId(connectTileExecutionStatus(ExecutionOverlay));

const InstrumentedOverlay = createSwitchChildren<'instrument', Instrument>(
  'instrument',
  'ExecutionStatusOverlay',
  {
    ...defaultSwitch(tileInstruments, <TileExecutionStatus overlay />),
    Bulk: <TileExecutionStatus />,
  },
);

export const ExecutionStatus = withQuoteId(
  connect(mapStateToPropsInstrument(selectors))(InstrumentedOverlay),
);
