
    window.sgbsVersion = '4.31.0';
    
    const majorVersion = window.sgbsVersion.split('.')[0];
    const params = new URLSearchParams(self.location.search);
    const theme = params.get('theme') ?? localStorage.getItem('sgmeFxTheme');
    const variation = theme === 'DARK' ? 'dark' : 'standard';
    let cdnUrls;
    if (location.href.includes('fr.world.socgen') || location.href.includes('azureedge') || location.href.includes('local')) {
      const sgbsCdnUrl = `https://shared.fr.world.socgen/sg-bootstrap/v${majorVersion}/`;
      cdnUrls = {
        sgbs: `${sgbsCdnUrl}${window.sgbsVersion}/sg-bootstrap-${variation}.min.css`,
        aggrid: `${sgbsCdnUrl}ag-grid-theme/${window.sgbsVersion}/28-${variation}.min.css`,
        icons: `${sgbsCdnUrl}icons/index.min.css`,
      }
    } else {
      const sgbsCdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${window.sgbsVersion}`;
      cdnUrls = {
        sgbs: `${sgbsCdnUrl}/core/sg-bootstrap-${variation}.min.css`,
        aggrid: `${sgbsCdnUrl}/ag-grid-theme/28-${variation}.min.css`,
        icons: `${sgbsCdnUrl}/icons/index.min.css`,
      }
    }
    
    Object.entries(cdnUrls).forEach(([id, url]) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.id = id;
      link.href = url;
      document.head.appendChild(link);
    });
  