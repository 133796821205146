import { useCallback } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import type { InstrumentChooserProps } from './typings';
import { selector, button, itemSelector } from 'components/Form/Inputs/e2e';
import { useToggle } from 'components/share/hooks/useToggle';

export const InstrumentChooserRaw: React.FunctionComponent<InstrumentChooserProps> = ({
  instrument,
  availableInstruments,
  ...onInstrumentChange
}) => {
  const [isOpen, toggle] = useToggle();
  return (
    <Dropdown toggle={toggle} isOpen={isOpen} data-e2e={selector('instrument')}>
      <DropdownToggle
        caret
        color="flat-primary"
        data-nodrag
        className="btn-discreet btn-alt-state p-0"
        data-e2e={button('instrument')}
      >
        <FormattedMessage id={'instrument.short.' + instrument} />
      </DropdownToggle>
      <DropdownMenu>
        {availableInstruments.map(instrumentItem => (
          <InstrumentChoice
            key={instrumentItem}
            instrument={instrumentItem}
            {...onInstrumentChange}
          />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const InstrumentChoice: React.FunctionComponent<
  Pick<InstrumentChooserProps, 'instrument' | 'onInstrumentChange'>
> = ({ instrument, onInstrumentChange }) => {
  const onClick = useCallback(
    () => onInstrumentChange(instrument),
    [instrument, onInstrumentChange],
  );
  return (
    <DropdownItem onClick={onClick} data-e2e={itemSelector(`${instrument}-instrument`)}>
      <FormattedMessage id={`instrument.${instrument}`} />
    </DropdownItem>
  );
};
