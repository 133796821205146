import type { AppState } from 'state/model';
import type { CurrencyPair } from 'state/referenceData/referenceDataModel';
import { type Selectors, selectors } from 'state/selectors';
import type { MapStateToPropsHOF } from 'typings/redux-utils';
import { fieldData } from '../../utils/fieldSelectors';
import { connect } from 'react-redux';
import type { Size } from 'components/Form/Inputs/typings';

export interface CurrencyPickerConnectOwnProps {
  quoteId: string;
  size?: Size;
}

export interface CurrencyPickerConnectStateProps {
  currencyPair?: CurrencyPair;
}

export interface CurrencyPickerConnectDispatchProps {
  changeCurrencyPair(ccyPair: string): void;
}

// you can whitelist selectors after implementation
export type CurrencyPickerConnectSelectors = Selectors;
/*
export type CurrencyPickerConnectSelectorsKeys = 'aSelector'; // example
export type CurrencyPickerConnectSelectors = Pick<Selectors, CurrencyPickerConnectSelectorsKeys>;
*/
export const mapStateToPropsCurrencyPicker: MapStateToPropsHOF<
  CurrencyPickerConnectStateProps,
  CurrencyPickerConnectOwnProps,
  AppState,
  CurrencyPickerConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const currencyPair = fieldData(sl.getTileCurrencyPair(state, quoteId)).data;
    const allCcyPairs = sl.getAllCcyPairs(state);
    return {
      currencyPair: currencyPair === null ? undefined : allCcyPairs[currencyPair],
    };
  };

export const connectCurrencyPicker = connect(mapStateToPropsCurrencyPicker(selectors));
