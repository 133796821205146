import type { BidAskPair, CurrencyChoice, Possible, Way } from 'state/share/productModel/litterals';
import type { IFxExecutionState, IFxExecutionData } from 'state/executions/executionsStateModel';
import type { PredealCheck } from 'state/share/predealChecksModel';
import type { Collection } from 'typings/utils';
import type { IFormData } from 'state/share/form';
import type { IFxTileMetadata } from '../tile/fxTileModel';
import type { Patch } from 'state/share/patchModels';

// data types
// ////////////

export type QuoteType = 'Tradeable' | 'Indicative' | 'Withdraw';

// entity types
// //////////////

// all properties are sent to TC - put transient info in metadata

export type CashTileStatus = 'NO_PRICE' | 'ESP_PRICING' | 'RFS_PRICING';

export interface IFxCashMarginMetadata {
  askMargin: number | null;
  bidMargin: number | null;
  askForwardMargin: number | null;
  bidForwardMargin: number | null;
}

export interface IFxCashMetadata extends IFxTileMetadata, IFxCashMarginMetadata {
  markupCurrency: CurrencyChoice;
  isEspDefaultTiering: boolean | null;
}

export interface IFxCashNdfValues {
  isNonDeliverable?: boolean;
  fixingDate: string | null;
  fixingSource: string | null;
  fixingCurrency: string | null;
  possibleFixingCurrencies: Possible<string> | null;
  possibleFixingSources: Possible<string> | null;
  isCrossed: boolean | null;
  possibleXCurrencies: Possible<string> | null;
  possibleSndFixingSources: Possible<string> | null;
  xCurrency: string | null;
  sndFixingSource: string | null;
}

export interface IFxCashValues extends IFxCashNdfValues {
  productName: CashProductName;
  currencyPair: string | null;
  currency1: string | null;
  currency2: string | null;
  maturityDate: string | null;
  maturityDateTenor: string | null;
  amount: number | null;
  amountCurrency: CurrencyChoice;
}

export interface IFxCashNdfInputs {
  isNonDeliverable: boolean | null;
  fixingSource: string | null;
  fixingCurrency: string | null;
  xCurrency: string | null;
  sndFixingSource: string | null;
}

export interface IFxCashInputs extends IFxCashNdfInputs {
  currencyPair: string | null;
  amount: string | null;
  amountCurrency: CurrencyChoice;
  maturityDate: string | null;
  maturityDateTenor: string | null;
}

export type FxCashProperties = IFormData<IFxCashValues, IFxCashInputs>;

export type FxCashState = Readonly<
  IFxCashMetadata & {
    dirtyFields: ReadonlyArray<keyof IFxCashValues>;
  } & FxCashProperties
>;

// for now, we only support a single strategy
// therefore '0' is the only leg created.
// this will change once multiple strategies will be implemented
export const emptyFxCashState: FxCashState = {
  values: {
    productName: 'FxSpot',
    currencyPair: null,
    currency1: null,
    currency2: null,
    amount: null,
    amountCurrency: 1,
    maturityDate: null,
    maturityDateTenor: null,
    isNonDeliverable: false,
    fixingSource: null,
    fixingDate: null,
    fixingCurrency: null,
    possibleFixingCurrencies: null,
    possibleFixingSources: null,
    isCrossed: null,
    possibleSndFixingSources: null,
    possibleXCurrencies: null,
    sndFixingSource: null,
    xCurrency: null,
  },
  errors: {},
  warnings: {},
  dirtyFields: [],
  inputs: {},
  propertiesRequested: false,
  propertiesRequestError: null,
  currentSessionId: null,
  isPriceable: false,
  currentStreamId: null,
  tradeCaptureIdVersion: null,
  lastStreamError: null,
  askMargin: null,
  bidMargin: null,
  askForwardMargin: null,
  bidForwardMargin: null,
  markupCurrency: 2,
  currentEspStreamId: null,
  isEspDefaultTiering: null,
  lastExecutedQuoteId: null,
  currentExecutionId: null,
  rfsStartedAt: null,
  priceRecords: [],
};

export type CashProductName = 'FxSpot' | 'FxFwd' | 'FxNdf';

// state types

export interface IFxCashsState {
  cashs: FxCashStateMap;
  streams: FxCashStreamMap;
}
export type FxCashsState = Readonly<IFxCashsState>;

export const emptyFxCashsState: FxCashsState = {
  cashs: {},
  streams: {},
};

export type IFxCashStateMap = Collection<FxCashState>;
export type FxCashStateMap = Readonly<IFxCashStateMap>;

type IFxCashStreamMap = Collection<FxCashStreamState>;
export type FxCashStreamMap = Readonly<IFxCashStreamMap>;

// ////////////////////

export type FxCashPatch = {
  productName: CashProductName;
} & Patch<IFxCashValues>;

// ////////////////////////

export interface IQuote {
  quoteId: string;
  quoteType: QuoteType;
  rfsWindow: number;
  traderId: string | null;
  frontTimestamp: Date;
  backTimestamp: number;
  spotWithoutMargin: BidAskPair;
  spotWithMargin: BidAskPair;
  forwardPointsWithoutMargin: BidAskPair;
  forwardPointsWithMargin: BidAskPair;
  defaultForwardMarginPoints: BidAskPair;
  defaultSpotMargin: BidAskPair;
  midAllInRate: number | null;
  predealChecks: readonly PredealCheck[];
}

export interface IQuoteCurrency {
  askAmount: number;
  bidAmount: number;
}

interface IFxCashStreamAwaitingState {
  status: 'AWAITING';
}

export interface IFxCashStreamStreamingState {
  status: 'PRICING';
  quote: Readonly<IQuote>;
  initialRfsWindow: number;
  tiering: string | null;
}

export type IFxCashStreamState = IFxCashStreamAwaitingState | IFxCashStreamStreamingState;

export type FxCashStreamState = Readonly<IFxCashStreamState>;

export interface IFxCashExecutionData extends IFxExecutionData {
  instrument: 'Cash';
  way: Way;
  notionalAmount: number;
  notionalCurrency: string;
  dealtRate: number;
  dealtRatePrecision: number;
  expiryDate: Date;
  expiryDateTenor: string;
  productName: CashProductName;
  swapPoints: number | undefined;
  spot: number | undefined;
  ndReference: string | undefined;
  ndFixingDate: string | undefined;
}

export type FxCashExecutionState = IFxExecutionState & IFxCashExecutionData;

export interface ISpotMargin {
  ask: number;
  bid: number;
}

export function assertIsPricing(
  streamState: IFxCashStreamState,
): asserts streamState is IFxCashStreamStreamingState {
  if (streamState.status !== 'PRICING') {
    throw 'error: stream is not pricing';
  }
}

export interface CashTileOpenFromBlotter {
  instrument: 'Cash';
  productName: CashProductName;
  currencyPair?: string | null;
  isNonDeliverable: boolean;
  amount?: number;
  amountCurrency?: CurrencyChoice;
  maturityDateTenor?: string;
}
