import { FormattedMessage, useIntl } from 'react-intl';
import { formatDateTimeWithLocale } from 'utils/dateFormats';
import { connectNegotiation } from './connect';
import type { Mifid2NegotiationStatus, Mifid2ClosedNegotiationStatus } from 'state/mifidPanel';
import styled from 'styled-components';
import { EmphasizedIcon } from 'components/share/EmphasizedIcon';
import { MifidQuote } from './MifidQuote';
import { isEmpty } from '@sgme/fp';

export interface NegotiationProps {
  negotiationId: string;
  startTime: string;
  quotes: readonly string[];
  status: Mifid2NegotiationStatus;
  onRecordTimestamp(): void;
  onDealDone(): void;
  onDealMissed(): void;
  onRemoveNegotiation(): void;
}

const OverlayDiv = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

const Overlay: React.FunctionComponent<{
  negotiationId: string;
  status: Mifid2ClosedNegotiationStatus;
  onRemoveNegotiation(): void;
}> = ({ negotiationId, status: [status, hasFailed], onRemoveNegotiation }) => (
  <OverlayDiv className="position-absolute p-2 d-flex flex-column align-items-center justify-content-center text-center bg-primary-alt">
    <EmphasizedIcon
      icon={status === 'Done' ? 'check' : 'warning'}
      color={status === 'Done' ? 'success' : 'danger'}
    />
    <span className="mt-2 mb-1 font-weight-bold">
      <FormattedMessage id="mifidPanel.negotiation" values={{ id: negotiationId }} />
    </span>
    <span>
      {!hasFailed ? (
        <FormattedMessage
          id="mifidPanel.closeNegotiation"
          values={{
            status,
            color: children => (
              <span className={`font-weight-bold text-${status === 'Done' ? 'success' : 'danger'}`}>
                {children}
              </span>
            ),
          }}
        />
      ) : (
        <>
          <span className="text-danger">
            <FormattedMessage id="mifidPanel.closeNegotiationFailed" values={{ status }} />
          </span>
          <button className="mt-2 btn btn-danger" onClick={onRemoveNegotiation}>
            <FormattedMessage id="mifidPanel.closeNegotiationBtn" />
          </button>
        </>
      )}
    </span>
  </OverlayDiv>
);

const NegotiationRaw: React.FunctionComponent<NegotiationProps> = ({
  negotiationId,
  startTime,
  quotes,
  status,
  onRecordTimestamp,
  onDealDone,
  onDealMissed,
  onRemoveNegotiation,
}) => {
  const { locale } = useIntl();
  return (
    <div className="position-relative border border-lv2 mb-2 p-2">
      {status !== 'Open' && <Overlay {...{ negotiationId, status, onRemoveNegotiation }} />}
      <p className="flex-grow-1 font-weight-bold">
        <FormattedMessage id="mifidPanel.negotiation" values={{ id: negotiationId }} />
      </p>
      <p className="mb-2">
        <FormattedMessage
          id="mifidPanel.initiatedOn"
          values={{ startTime: formatDateTimeWithLocale(new Date(startTime), locale) }}
        />
      </p>
      <ul className="list-unstyled">
        {quotes.map((quote, idx) => (
          <li key={idx}>
            <MifidQuote negotiationId={negotiationId} quoteId={quote} />
          </li>
        ))}
      </ul>
      <button
        type="button"
        className="btn btn-outline-primary w-100 mb-2"
        onClick={onRecordTimestamp}
      >
        <FormattedMessage id="mifidPanel.recordTimestamp" />
      </button>
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-success flex-grow-1 mr-2"
          onClick={onDealDone}
          disabled={isEmpty(quotes)}
        >
          <FormattedMessage id="mifidPanel.done" />
        </button>
        <button type="button" className="btn btn-danger flex-grow-1" onClick={onDealMissed}>
          <FormattedMessage id="mifidPanel.missed" />
        </button>
      </div>
    </div>
  );
};

export const Negotiation = connectNegotiation(NegotiationRaw);
