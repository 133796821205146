import { noop } from '@sgme/fp';
import { type Matomo, MatomoUtils } from 'utils';

let _matomo: Matomo;

export function getMatomo(): Matomo {
  if (_matomo === undefined) {
    if (window.sgmeConfiguration.piwik) {
      _matomo = new MatomoUtils(window.sgmeConfiguration.piwik);
    } else {
      _matomo = {
        init: noop,
        track: noop,
        setWithUserConsent: noop,
        setWithoutUserConsent: noop,
        setCustomVariable: noop,
        trackPageView: noop,
      };
    }
  }
  return _matomo;
}
