import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';
import type { IFxCashValues } from 'state/fxCashs/fxCashsModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import { isDefinedAndNonEmpty } from '@sgme/fp';

/**
 * @deprecated delegate to meta selector
 */
export function cashAmountChanged(cashId: string, amount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { data: amountCurrency } = fieldData(sl.getCashAmountCurrency(getState(), cashId));
    dispatch(ac.cashPropertiesChanged(cashId, { amount, amountCurrency }));
  };
}

export function cashCurrencyChanged(cashId: string, amount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: oldCurrency } = fieldData(sl.getCashAmountCurrency(state, cashId));
    const { data: oldAmount } = fieldData(sl.getCashAmount(state, cashId));
    const amountCurrency = ((oldCurrency % 2) + 1) as CurrencyChoice;
    if (isDefinedAndNonEmpty(oldAmount)) {
      dispatch(ac.cashPropertiesChanged(cashId, { amount, amountCurrency }));
    } else {
      dispatch(ac.cashAmountCurrencyMaskChanged(cashId, amountCurrency));
    }
  };
}

export function cashCurrencyPairChangeThunk(cashId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    dispatch(ac.espTileStreamUnsubscribeThunk(cashId));
    const state = getState();
    const amountAndCurrency = sl.isTileFirstCurrencyPairSelection(state, cashId)
      ? { amount: sl.getUserDefaultNotional(state), amountCurrency: 1 as CurrencyChoice }
      : {
          amount: null,
          amountCurrency: fieldData(sl.getCashAmountCurrency(state, cashId)).data,
        };
    dispatch(ac.cashPropertiesChanged(cashId, { currencyPair, ...amountAndCurrency }));
  };
}

export function cashPropertiesDirtyStateChangedThunk(
  cashId: string,
  fieldName: keyof IFxCashValues,
  isDirty: boolean,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    /**
     * @todo change selector
     */
    const { dirtyFields } = sl.getCashState(getState(), cashId);

    if (isDirty && !dirtyFields.includes(fieldName)) {
      dispatch(ac.cashPropertiesDirty(cashId, fieldName));
    } else if (!isDirty && dirtyFields.includes(fieldName)) {
      dispatch(ac.cashPropertiesPristine(cashId, fieldName));
    }
  };
}
