import { Popover } from 'reactstrap';
import styled from 'styled-components';
import { RefToggle } from 'components/share/RefToggle';
import { withTabId, TabIdProvider } from 'components/NavMenu';
import { TabPopover } from '../TabPopover';
import { CloseTabButton } from './CloseTabButton';
import { TabName } from './TabName';
import { PrimeBrokerBadge } from '../PrimeBrokerBadge';
import { connectTabItem } from './connect';
import * as e2e from 'components/Form/Inputs/e2e';

export interface TabItemProps {
  showClose: boolean;
  tabId: string;
  active: boolean;
  clientName: string;
  primeBroker: string | null;
  selectTab(): void;
}

const TabItemContainer = styled.div`
  max-width: 285px;
`;

export const TabItemRaw: React.FunctionComponent<TabItemProps> = props => (
  <RefToggle<HTMLElement>>
    {popover => (
      <>
        <li
          className="nav-item nav-hover cursor-default"
          role="tab"
          data-e2e={e2e.tab('workspace')}
          onClick={props.active ? undefined : props.selectTab}
        >
          <TabItemContainer
            className={`nav-link p-1${props.active ? ' active' : ' text-secondary'}`}
          >
            <div className="d-flex justify-content-between">
              <TabName isEditable={props.active} />
              {props.showClose && <CloseTabButton />}
            </div>
            <div
              data-e2e={e2e.selector('client')}
              className="d-flex justify-content-between"
              ref={props.active ? popover.ref : undefined}
              onClick={props.active ? popover.open : undefined}
            >
              <div className="flex-grow-1 text-truncate pl-2" data-e2e={e2e.label('clientName')}>
                <span data-e2e={e2e.label('clientName')}>{props.clientName}</span>
                <PrimeBrokerBadge
                  primeBroker={props.primeBroker}
                  emphasis={props.active}
                  showTooltip={props.active && !popover.isOpen}
                />
              </div>
              {!props.active ? null : (
                <button type="button" className="btn btn-flat-secondary btn-icon p-0 ml-1">
                  <i className="icon px-2">more_vert</i>
                </button>
              )}
            </div>
          </TabItemContainer>
        </li>
        {popover.target !== null && (
          <Popover
            placement="bottom"
            trigger="legacy"
            isOpen={popover.isOpen}
            target={popover.target}
            toggle={popover.close}
          >
            {/* popover uses a portal which breaks context propagation */}
            <TabIdProvider value={props.tabId}>
              <TabPopover close={popover.close} />
            </TabIdProvider>
          </Popover>
        )}
      </>
    )}
  </RefToggle>
);
TabItemRaw.displayName = 'TabItem';

export const TabItem = withTabId(connectTabItem(TabItemRaw));
