import { withQuoteId } from 'components/contexts';
import { connectTileStatus } from './connect';
import { TileError, TileEmpty } from 'components/GenericTile';

interface TileStatusCommonProps {
  hasError: boolean;
  isEmpty: boolean;
  children: React.ReactNode;
}

interface TileStatusError {
  error: true;
  empty?: false;
  close?: true;
}

interface TileStatusEmpty {
  error?: false;
  empty: true;
  close?: false;
}

export type TileStatusProps = TileStatusCommonProps & (TileStatusError | TileStatusEmpty);

export function TileStatusRaw({
  hasError,
  isEmpty,
  children,
  error = false,
  empty = false,
  close = false,
}: TileStatusProps) {
  if (hasError && error) {
    return <TileError close={close} />;
  }

  if (isEmpty && empty) {
    return <TileEmpty />;
  }

  return children as React.ReactElement<any>;
}

export const TileStatus = withQuoteId(connectTileStatus(TileStatusRaw));
