import type { MapStateToPropsFactoryHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { isInstrumentChoice, type InstrumentChoice } from 'state/referenceData/referenceDataModel';
import { createSelector } from 'reselect';
import { assertUnhandled } from 'utils/error';
import { assertUnreachable } from '@sgme/fp';

export interface InstrumentChooserConnectOwnProps {
  quoteId: string;
}

export interface InstrumentChooserConnectStateProps {
  instrument: InstrumentChoice;
  availableInstruments: readonly InstrumentChoice[];
}

export interface InstrumentChooserConnectDispatchProps {
  onInstrumentChange(instrument: InstrumentChoice): void;
}

// you can whitelist selectors after implementation
export type InstrumentChooserConnectSelectors = Selectors;
/*
export type InstrumentChooserConnectSelectorsKeys = 'aSelector'; // example
export type InstrumentChooserConnectSelectors = Pick<Selectors, InstrumentChooserConnectSelectorsKeys>;
*/

const filterTileInstruments = (instruments: readonly InstrumentChoice[]) =>
  instruments.filter(isInstrumentChoice);

export const mapStateToPropsInstrumentChooser: MapStateToPropsFactoryHOF<
  InstrumentChooserConnectStateProps,
  InstrumentChooserConnectOwnProps,
  AppState,
  InstrumentChooserConnectSelectors
> = sl => () => {
  const getFilteredInstruments = createSelector(sl.getAvailableInstruments, filterTileInstruments);
  const getAvailableInstruments = createSelector(
    [getFilteredInstruments, sl.getProductsAccess],
    (filteredInstruments, productAccess) =>
      productAccess.forwardAccu
        ? filteredInstruments
        : filteredInstruments.filter(instrument => instrument !== 'ForwardAccumulator'),
  );
  const getInstrumentChoiceForTile = getInstrumentChoiceForTileWith(sl);
  return (state, { quoteId }) => {
    const instrument = getInstrumentChoiceForTile(state, quoteId);
    return {
      availableInstruments: getAvailableInstruments(state),
      instrument,
    };
  };
};

const getInstrumentChoiceForTileWith =
  (sl: Selectors) =>
  (state: AppState, quoteId: string): InstrumentChoice => {
    const { instrument } = sl.getTileState(state, quoteId);
    switch (instrument) {
      case 'Accumulator':
        const productName = sl.getAccumulatorProductName(state, quoteId);
        switch (productName) {
          case 'FxTargetAccumulator':
            return 'TargetAccumulator';
          case 'FxForwardAccumulator':
            return 'ForwardAccumulator';
          default:
            assertUnreachable(productName, 'this product name is not handled');
        }
        break;
      case 'Cash':
      case 'Swap':
      case 'Option':
        return instrument;
      case 'Order':
      case 'BlotterOrder':
      case 'Bulk':
        return assertUnhandled('orders and bulk should not have instrument chooser', instrument);
    }
  };

// you can whitelist action creators after implementation
export type InstrumentChooserConnectActionCreators = ActionCreators;
/*
export type InstrumentChooserConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type InstrumentChooserConnectActionCreators = Pick<ActionCreators, InstrumentChooserConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsInstrumentChooser: MapDispatchToPropsHOF<
  InstrumentChooserConnectDispatchProps,
  InstrumentChooserConnectOwnProps,
  InstrumentChooserConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onInstrumentChange: instrument => {
      dispatch(ac.tileInstrumentChangedThunk(quoteId, instrument));
    },
  });
