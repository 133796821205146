import { Tooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { isEmptyOrNotDefined } from '@sgme/fp';
import { RefToggle } from 'components/share/RefToggle';
import { IsInternalSales } from 'components/share/UserType';
import { label } from 'components/Form/Inputs/e2e';

export interface PrimeBrokerBadgeProps {
  primeBroker: string | null;
  emphasis?: boolean;
  showTooltip: boolean;
}

export const PrimeBrokerBadge: React.FunctionComponent<PrimeBrokerBadgeProps> = ({
  primeBroker,
  emphasis,
  showTooltip,
}) =>
  isEmptyOrNotDefined(primeBroker) ? null : (
    <IsInternalSales>
      <RefToggle<HTMLElement>>
        {({ ref, target, isOpen, toggle }) => (
          <>
            <span
              className={`ml-auto ml-1 badge badge-pill badge-sm ${
                emphasis ? 'badge-primary' : 'badge-secondary'
              }`}
              ref={ref}
              data-e2e={label('primeBroker')}
            >
              <FormattedMessage id="primebroker.badge" />
            </span>
            {target != null && showTooltip && (
              <Tooltip
                container={target.parentElement!}
                placement="bottom"
                delay={0}
                fade={false}
                {...{ target, isOpen, toggle }}
              >
                <span data-e2e={label('primeBrokerName')}>{primeBroker}</span>
              </Tooltip>
            )}
          </>
        )}
      </RefToggle>
    </IsInternalSales>
  );
