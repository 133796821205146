import { memo } from 'react';
import { DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { connectTabsMenu, connectTabMenuItem } from './connect';
import type { IWorkspaceTabs } from 'state/clientWorkspace';
import { MenuButton } from './MenuButton';

interface TabsMenuProps {
  tabs: IWorkspaceTabs;
  activeTab: string | null;
}

interface TabMenuItemProps {
  tabName: string;
  selected: boolean;
  onSwitchToTab(): void;
}

function TabMenuItemRaw({ tabName, selected, onSwitchToTab }: TabMenuItemProps): JSX.Element {
  return (
    <DropdownItem onClick={onSwitchToTab} className={selected ? 'active' : undefined}>
      {tabName}
    </DropdownItem>
  );
}

const TabMenuItem = connectTabMenuItem(TabMenuItemRaw);

function TabsMenuRaw({ tabs, activeTab }: TabsMenuProps): JSX.Element {
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="span">
        <MenuButton e2eHandle="tabs-list" color="secondary" data-toggle="dropdown">
          <i className="icon icon-md px-2">keyboard_arrow_down</i>
        </MenuButton>
      </DropdownToggle>
      <DropdownMenu
        right
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: data => ({
              ...data,
              styles: {
                ...data.styles,
                overflow: 'auto',
                maxHeight: '50vh',
                marginTop: '25px',
              },
            }),
          },
        }}
      >
        {Object.entries(tabs).map(([tabId, tab]) => (
          <TabMenuItem
            key={tabId}
            tabId={tabId}
            tabName={tab!.name}
            selected={tabId === activeTab}
          />
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export const TabsMenu = connectTabsMenu(memo(TabsMenuRaw));
