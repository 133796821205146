import type { SaveState } from 'state/clientWorkspace';
import { MenuButton } from './MenuButton';
import { connectSaveWorkspaceButton } from './connect';
import { FormattedMessage } from 'react-intl';
import { useFeatureToggles } from 'components/share/hooks/useFeatureToggle';

export interface SaveWorkspaceButtonProps {
  saveWorkspaceState: SaveState;
  saveWorkspace(): void;
}

const icons: Record<SaveState, React.ReactNode> = {
  READY: <i className="icon icon-md">save</i>,
  PENDING: (
    <div className="pt-3 px-1">
      <div className="spinner spinner-md"></div>
    </div>
  ),
  SUCCESS: <i className="icon icon-md text-success py-1">check_circle</i>,
  FAIL: <i className="icon icon-md text-danger py-1">highlight_off</i>,
};
const tooltipIds: Record<SaveState, string | undefined> = {
  READY: 'navMenuButtons.saveWorkspace',
  PENDING: undefined,
  SUCCESS: 'navMenuButtons.savedWorkspace',
  FAIL: undefined,
};

export const SaveWorkspaceButtonRaw: React.FunctionComponent<SaveWorkspaceButtonProps> = ({
  saveWorkspace,
  saveWorkspaceState,
}) => {
  const tooltipId = tooltipIds[saveWorkspaceState];
  const { allowSaveWorkspace } = useFeatureToggles();
  return (
    <MenuButton
      color="primary"
      e2eHandle="save-workspace"
      disabled={!allowSaveWorkspace || saveWorkspaceState !== 'READY'}
      onClick={saveWorkspace}
      tooltip={tooltipId === undefined ? undefined : <FormattedMessage id={tooltipId} />}
    >
      {icons[saveWorkspaceState]}
    </MenuButton>
  );
};

export const SaveWorkspaceButton = connectSaveWorkspaceButton(SaveWorkspaceButtonRaw);
