import { InstrumentChooser } from './InstrumentChooser';
import { PredealChecks } from './PredealChecks';
import { NDCheckbox } from './NDCheckbox';
import { CurrencyPicker } from '../CurrencyPicker';
import { TileMenu } from './TileMenu';
import { AccountChanged } from './AccountChanged';
import styled from 'styled-components';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

const CurrencyContainer = styled.div`
  width: 28%;
`;

export function TileHeader(): JSX.Element {
  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <AccountChanged />
      <div className="row no-gutters p-2 align-items-center cursor-default user-select-none">
        <div className="col">
          <InstrumentChooser />
          <PredealChecks />
        </div>
        <CurrencyContainer>
          <CurrencyPicker />
        </CurrencyContainer>
        <div className="col d-flex justify-content-between align-items-center">
          <NDCheckbox className="ml-3" />
          <TileMenu />
        </div>
      </div>
    </IntlComponentBoundary>
  );
}
