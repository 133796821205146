import * as executionSelectors from './executions/executionsSelectors';
import * as optionSelectors from './fxOptions/selectors';
import * as swapSelectors from './fxSwaps/selectors';
import * as cashSelectors from './fxCashs/selectors';
import * as bulkSelectors from './fxBulks/selectors';
import * as accumulatorSelectors from './fxAccumulators/selectors';
import * as orderSelectors from './fxOrders/selectors';
import * as espSelectors from './esp/espStreamsSelectors';
import * as clientWorkspaceSelectors from './clientWorkspace/selectors';
import * as referenceDataSelectors from './referenceData/referenceDataSelectors';
import * as streamingConnectionSelectors from './streamingConnection/streamingConnectionSelectors';
import * as globalErrorSelectors from './globalError/globalErrorSelectors';
import * as gridLayoutSelectors from './gridLayout/selectors';
import * as freeLayoutSelectors from './freeLayout/selectors';
import * as sessionSelectors from './session/sessionSelectors';
import * as tileLegacySelectors from './tile/tileSelectors';
import * as tileSelectors from './tile/selectors';
import * as sharedSelectors from './sharedSelectors';
import * as userPreferencesSelectors from './userPreferences/selectors';
import * as blotterSelectors from './blotter/selectors';
import * as mifid2PanelSelectors from './mifidPanel/mifidPanelSelectors';
import * as clientSelectors from './share/clientSelectors';

export const selectors = {
  ...executionSelectors,
  ...optionSelectors,
  ...swapSelectors,
  ...cashSelectors,
  ...bulkSelectors,
  ...accumulatorSelectors,
  ...orderSelectors,
  ...espSelectors,
  ...clientWorkspaceSelectors,
  ...referenceDataSelectors,
  ...streamingConnectionSelectors,
  ...globalErrorSelectors,
  ...gridLayoutSelectors,
  ...freeLayoutSelectors,
  ...sessionSelectors,
  ...tileSelectors,
  ...tileLegacySelectors,
  ...sharedSelectors,
  ...userPreferencesSelectors,
  ...blotterSelectors,
  ...mifid2PanelSelectors,
  ...clientSelectors,
} as const;

export type Selectors = typeof selectors;
