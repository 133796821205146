import type { AppState } from 'state/model';
import { getCashState } from 'state/fxCashs/selectors/fxCashsSelectors';
import type { BidAskPair } from 'state/share/productModel/litterals';

export function getCashForwardMargin(state: AppState, tileId: string): BidAskPair {
  const { bidForwardMargin, askForwardMargin } = getCashState(state, tileId);
  return {
    bid: bidForwardMargin ?? 0,
    ask: askForwardMargin ?? 0,
  };
}

export function getCashMargin(state: AppState, tileId: string) {
  const { bidMargin, askMargin, bidForwardMargin, askForwardMargin } = getCashState(state, tileId);
  return {
    bidSpotMargin: bidMargin ?? 0,
    askSpotMargin: askMargin ?? 0,
    bidForwardMargin: bidForwardMargin ?? 0,
    askForwardMargin: askForwardMargin ?? 0,
  };
}
