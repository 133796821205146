import type { Reducer } from 'redux';
import type { BlotterEditOrderData } from '../blotterModel';
import type { Action } from 'state/actions';
import { addKey, patchValueByProperty, removeKey, updateKey } from 'utils/stateMap';
import type {
  BlotterAllOrderAlgoInputs,
  BlotterAllOrderAlgoValues,
  BlotterAllOrderLimitValues,
  BlotterOrderAlgoProperties,
  BlotterOrderLimitProperties,
  OrderBlotterAlgoEntry,
  OrderBlotterEntry,
} from '../blotterEntryModel';
import { isOrderBlotterEntry } from '../blotterEntryModel';
import { preparePricesAndMargin } from './intradayReducer';
import { strictEntries } from '../../../utils/object';
import { isDefined } from '@sgme/fp';
import type { BlotterEditedOrderValidationReceived } from '../action';
import { clearUndefined } from '../../../utils/clearUndefined';
import { isFixingOrder } from '../../fxOrders/fxOrdersModel';
import { getFixingOrderValues } from '../../fxOrders/reducers/fxOrdersProductReducer';

const initialState: BlotterEditOrderData = {};

export const editedOrdersReducer: Reducer<BlotterEditOrderData> = (
  blotterData: BlotterEditOrderData = initialState,
  action: Action,
): BlotterEditOrderData => {
  switch (action.type) {
    case 'BLOTTER_EDITED_ORDER_PROPERTIES_CHANGED':
      return updateKey(blotterData, action.orderId, trade => {
        const patch = {
          ...action.patch,
          ...(action.allowOrderTakeProfitMargin ? {} : preparePricesAndMargin(action, trade)),
        };

        return isOrderBlotterEntry(trade) ? patchValueByProperty(trade, 'inputs', () => patch) : null;
      });
    case 'BLOTTER_EDITED_ORDER_VALIDATION_RECEIVED':
      // todo - in our case we know blotterData is a OrderBlotterEntry - check how to tighten it
      return updateKey(blotterData, action.orderId, (order: OrderBlotterEntry) => {
        const isTakeProfitOrder = order.inputs.product === 'TakeProfit' || order.values.product === 'TakeProfit';

        const mappedInputs = strictEntries(order.inputs as BlotterAllOrderAlgoInputs).reduce((acc, [key, value]) => {
          if (isDefined(value)) {
            return { ...acc, [key]: key === 'notional' ? Number(value) : value };
          }

          return acc;
        }, {} as Partial<BlotterAllOrderLimitValues | BlotterAllOrderAlgoValues>);

        type OrderInitialPatch =
          | (BlotterOrderLimitProperties & { isReadyToSubmit: boolean })
          | (BlotterOrderAlgoProperties & { isReadyToSubmit: boolean });

        let initialPatch: OrderInitialPatch;

        if (isAlgoOrder(order)) {
          initialPatch = {
            values: {
              ...order.values,
              ...mappedInputs,
              ...mapValuesFromActionIfValidated(action, isTakeProfitOrder),
            },
            inputs: {},
            errors: {},
            warnings: order.warnings,
            isReadyToSubmit: action.isReadyToSubmit,
            // todo 5010 see if we need those properties (can they be updated by oms validation ? )
            // instrument: 'Order',
            // modeStatus: ModeStatus;
            // errorCode?: OrderErrorCode;
          } as BlotterOrderAlgoProperties & { isReadyToSubmit: boolean };

          const patch = action.validationDetails.reduce((acc, { code, description, field }) => {
            const errors = {
              ...acc.errors,
              [field]: {
                code,
                description,
                userNotified: false,
                faultyValue: null,
              },
            };
            return { ...acc, errors };
          }, initialPatch);
          return patch;
        } else {
          initialPatch = {
            values: {
              ...order.values,
              ...mappedInputs,
              ...mapValuesFromActionIfValidated(action, isTakeProfitOrder),
              ...(isFixingOrder(order.values.product) ? getFixingOrderValues(action, order) : {}),
            },
            inputs: {},
            errors: {},
            warnings: order.warnings,
            isReadyToSubmit: action.isReadyToSubmit,
            // todo 5010 see if we need those properties (can they be updated by oms validation ? )
            // instrument: 'Order',
            // modeStatus: ModeStatus;
            // errorCode?: OrderErrorCode;
          } as BlotterOrderLimitProperties & { isReadyToSubmit: boolean };
        }

        // const initialPatch: OrderInitialPatch = {
        //   values: {
        //     ...order.values,
        //     ...mappedInputs,
        //     ...mapValuesFromActionIfValidated(action, isTakeProfitOrder),
        //     ...(!getIsAlgoOrder(order) && isFixingOrder(order.values.product)
        //       ? getFixingOrderValues(action, order)
        //       : {}),
        //   },
        //   inputs: {},
        //   errors: {},
        //   warnings: order.warnings,
        //   isReadyToSubmit: action.isReadyToSubmit,
        //   // todo 5010 see if we need those properties (can they be updated by oms validation ? )
        //   // instrument: 'Order',
        //   // modeStatus: ModeStatus;
        //   // errorCode?: OrderErrorCode;
        // };

        return action.validationDetails.reduce((acc, { code, description, field }) => {
          const errors = {
            ...acc.errors,
            [field]: {
              code,
              description,
              userNotified: false,
              faultyValue: null,
            },
          };
          return { ...acc, errors };
        }, initialPatch);
      });
    // TODO ABO, SGEFX-5010: impplement validation error ???
    case 'BLOTTER_EDITED_ORDER_CREATE_ENTRY':
      return blotterData[action.orderId] !== undefined
        ? blotterData
        : addKey(blotterData, action.orderId, action.order);
    case 'BLOTTER_EDITED_ORDER_REMOVE_ENTRY':
      return blotterData[action.orderId] === undefined ? blotterData : removeKey(blotterData, action.orderId);
  }
  return blotterData;
};

const mapValuesFromActionIfValidated = (action: BlotterEditedOrderValidationReceived, isTakeProfitOrder: boolean) => {
  const isPayloadValid = action.validationDetails.length === 0;

  return isPayloadValid
    ? {
        margin: isDefined(action.margin) ? action.margin : null,
        ...clearUndefined({
          limitPrice: adaptPriceForTakeProfitIfNeeded(
            isTakeProfitOrder,
            action.limitPrice,
            action.allowOrderTakeProfitMargin,
          ),
          customerPrice: adaptPriceForTakeProfitIfNeeded(
            isTakeProfitOrder,
            action.customerPrice,
            action.allowOrderTakeProfitMargin,
          ),
          maturityDate: action.maturityDay,
          ndfFixingSource: action.ndfFixingSource,
          ndfFixingDate: action.ndfFixingDate,
          expiryDay: action.expiryDay,
          expiryTime: action.expiryTime,
        }),
      }
    : {};
};

const adaptPriceForTakeProfitIfNeeded = (
  isTakeProfitOrder: boolean,
  price: number | null | undefined,
  allowOrderTakeProfitMargin: boolean,
) => {
  if (isTakeProfitOrder && allowOrderTakeProfitMargin) {
    // we need null values to passed only for takeProfit as this needs to override input values for this specific order
    // whereas we do not expect any override for other type of order when oms sends back null value
    return isDefined(price) ? `${price}` : null;
  }

  return isDefined(price) ? `${price}` : undefined;
};

function isAlgoOrder(order: OrderBlotterEntry): order is OrderBlotterAlgoEntry {
  return 'speed' in order.values;
}
