import { isDefinedAndNonEmpty, isEmpty } from '@sgme/fp';
import { acceleratorOffset } from './numberInputConstants';
import {
  getCursorOffsetFromSelectionToValue,
  formatNumberAsStringToLocale,
  getCursorOffsetFromValueToSelection,
} from './numberInputFormats';

interface Separators {
  localeThousandSeparator: string;
  localeDecimalSeparator: string;
}

interface Boundaries {
  selectionStart: number;
  selectionEnd: number;
}

export interface INumberInputDigitEdit extends Separators, Boundaries {
  value: string;
  valueLocale: string;
  key: string;
}

export interface INumberInputChangeSign extends Boundaries {
  value: string;
  valueLocale: string;
}

export interface INumberInputMultiplierEdit extends Separators {
  value: string;
  key: string;
}

export interface ICopy extends Separators, Boundaries {
  value: string;
  valueLocale: string;
}
/**
 * given locale separators and the state of the input field before key press
 * returns the new number, localized number and cursor position
 */
export function insertDigitKey({
  value,
  valueLocale,
  key,
  selectionStart,
  selectionEnd,
  localeThousandSeparator,
  localeDecimalSeparator,
}: INumberInputDigitEdit) {
  // given the localized value and the thousand separator,
  // what is the offset between the input field cursor position
  // and the inner non formatted value position
  const valueStartOffset = getCursorOffsetFromSelectionToValue(
    selectionStart,
    valueLocale,
    localeThousandSeparator,
  );
  const valueEndOffset = getCursorOffsetFromSelectionToValue(
    selectionEnd,
    valueLocale,
    localeThousandSeparator,
  );
  const valueCursorPositionStart = selectionStart - valueStartOffset;
  const valueCursorPositionEnd = selectionEnd - valueEndOffset;

  // a priori, a key is added and cursor moves one to the right
  let valueCursorDelta = key.length;

  // if key is a dot, remove all other dots
  let leftHandString = value.substr(0, valueCursorPositionStart);
  if (key === '.' && leftHandString.split('.').length > 1) {
    leftHandString = leftHandString.split('.').join('');
    // left hand side dots implies cursor remains in position
    valueCursorDelta = 0;
  }
  let rightHandString = value.substr(valueCursorPositionEnd);
  if (key === '.' && rightHandString.split('.').length > 1) {
    rightHandString = rightHandString.split('.').join('');
  }

  const newValue = [leftHandString, key, rightHandString].join('');
  const newValueLocale = formatNumberAsStringToLocale(
    newValue,
    localeThousandSeparator,
    localeDecimalSeparator,
  );

  // update the inner cursor position
  const newValuePosition = valueCursorPositionStart + valueCursorDelta;

  // calculate corresponding cursor position in the input field
  const newSelectionOffset = getCursorOffsetFromValueToSelection(
    newValuePosition,
    newValueLocale,
    localeThousandSeparator,
  );
  const cursorPosition = newValuePosition + newSelectionOffset;

  return {
    cursorPosition,
    state: { value: newValue, valueLocale: newValueLocale },
  };
}
/**
 * insert or remove '-' sign and offset cursor position acordingly
 */
export function changeSign({ value, valueLocale, selectionEnd }: INumberInputChangeSign) {
  const negative = value[0] === '-';
  const sign = negative ? -1 : 1;
  const cursorPosition = selectionEnd + sign;
  const state = {
    value: negative ? value.slice(1) : '-'.concat(value),
    valueLocale: negative ? valueLocale.slice(1) : '-'.concat(valueLocale),
  };
  return { cursorPosition, state };
}

/**
 * given locale separators and the state of the input field before key press
 * returns the new number and localized number
 */
export function insertAcceleratorKey({
  value,
  key,
  localeThousandSeparator,
  localeDecimalSeparator,
}: INumberInputMultiplierEdit) {
  // map each key to a multiplier (power of ten)
  const multiplier = acceleratorOffset(key);

  // split value in integer and decimal parts
  let [integer, decimal] = value.split('.') as [string, string | undefined];
  // if the decimal part has no number, ignore it
  decimal = isDefinedAndNonEmpty(decimal) ? decimal : undefined;
  // if there is no integer and there is no decimal part, ignore key press
  if (isEmpty(integer) && decimal === undefined) {
    return null;
  }

  // string multiplication
  for (let index = 0; index < multiplier; index++) {
    if (decimal === undefined) {
      // add zero if there is no decimal digit
      integer = integer.concat('0');
    } else {
      // add most significant decimal digit
      integer = integer.concat(decimal[0]);
      // strip it from the decimal part, if its length reaches 0, explicitly remove decimal reference
      decimal = decimal.length > 1 ? decimal.slice(1) : undefined;
    }
  }

  const newValue = decimal === undefined ? integer : [integer, decimal].join('.');
  const newValueLocale = formatNumberAsStringToLocale(
    newValue,
    localeThousandSeparator,
    localeDecimalSeparator,
  );
  return { value: newValue, valueLocale: newValueLocale };
}

export function copy({
  value,
  valueLocale,
  selectionStart,
  selectionEnd,
  localeThousandSeparator,
  localeDecimalSeparator,
}: ICopy) {
  const start =
    selectionStart -
    getCursorOffsetFromSelectionToValue(selectionStart, valueLocale, localeThousandSeparator);
  const end =
    selectionEnd -
    getCursorOffsetFromSelectionToValue(selectionEnd, valueLocale, localeThousandSeparator);
  return value
    .substr(start, end - start)
    .split('.')
    .join(localeDecimalSeparator);
}
