import type { UserPreferencesData } from 'state/userPreferences/userPreferencesModel';
import { makeValuesPredicate } from '@sgme/fp';

export const featureTogglesKeys = [
  'orderTakeProfitMargin',
  'timeoutedDeal',
  'optionTradeCaptureNewModel',
  'optionTradeCaptureMultiHedges',
  'allowSaveWorkspace',
  'ndfEspExecutable',
  'barrierTypeDropdownDisplayPIVOTEKI',
  'barrierTypeDropdownDisplayPIVOT',
  'barrierTypeDropdownDisplayEKI',
  'accumulatorAllowOverrideHedge',
  'smartRFS',
] as const;

export type FeatureToggleKeys = (typeof featureTogglesKeys)[number];

export type FeatureToggles = Record<FeatureToggleKeys, boolean>;

export const defaultFeatureToggles: FeatureToggles = {
  orderTakeProfitMargin: false,
  timeoutedDeal: false,
  optionTradeCaptureNewModel: false,
  optionTradeCaptureMultiHedges: false,
  allowSaveWorkspace: false,
  ndfEspExecutable: false,
  barrierTypeDropdownDisplayPIVOTEKI: false,
  barrierTypeDropdownDisplayPIVOT: false,
  barrierTypeDropdownDisplayEKI: false,
  accumulatorAllowOverrideHedge: false,
  smartRFS: false,
};

export const productsAccessKeys = [
  'spot',
  'forward',
  'swap',
  'option',
  'nonDeliverable',
  'targetAccu',
  'forwardAccu',
  'stopLoss',
  'takeProfit',
  'callOrder',
  'twap',
  'nightjar',
  'falcon',
  'fixing',
] as const;

export type ProductAccessKeys = (typeof productsAccessKeys)[number];
export type ProductsAccess = Record<ProductAccessKeys, boolean>;
export const defaultProductsAccess: ProductsAccess = {
  spot: false,
  forward: false,
  swap: false,
  option: false,
  nonDeliverable: false,
  targetAccu: false,
  forwardAccu: false,
  stopLoss: false,
  takeProfit: false,
  callOrder: false,
  twap: false,
  nightjar: false,
  falcon: false,
  fixing: false,
};

export interface User {
  login: string;
  ulysseLogin: string;
  bdrInitials: string;
  piwikLogin: string;
  userType: 'Internal Sales' | 'Internal Trader' | null;
  hasFxTcaAccess: boolean;
  isOnBehalf: boolean;
  canTrade: boolean;
  isFastTrade: boolean;
  clients: readonly Client[];
  featureToggles: FeatureToggles;
  userPreferences: UserPreferencesData;
  productsAccess: ProductsAccess;
  productTypes: readonly InstrumentChoice[];
  localMarketsCcy: readonly string[];
}

export interface ClosedDates {
  [currencyPair: string]: readonly string[];
}

export const switchableInstruments = ['Cash', 'Swap', 'Option', 'Accumulator'] as const;
export type SwitchableInstrument = (typeof switchableInstruments)[number];

export const instrumentChoices = ['Cash', 'Swap', 'Option', 'TargetAccumulator', 'ForwardAccumulator'] as const;
export type InstrumentChoice = (typeof instrumentChoices)[number];

export const executableInstruments = [...switchableInstruments, 'Bulk'] as const;
export type ExecutableInstrument = (typeof executableInstruments)[number];

export const tileInstruments = [...switchableInstruments, 'Order', 'BlotterOrder'] as const;
export type TileInstrument = (typeof tileInstruments)[number];

export const instruments = [...tileInstruments, 'Bulk'] as const;
export type Instrument = (typeof instruments)[number];

export const formDomain = [...instruments, 'BlotterTrade'] as const;
export type FormDomain = (typeof formDomain)[number];

export const leggedInstruments = ['Option', 'Bulk', 'Accumulator'] as const;
export type LeggedInstrument = (typeof leggedInstruments)[number];

// todo-5149 check if any side effect
export const hedgedInstruments = ['Option'] as const;
export type HedgedInstrument = (typeof hedgedInstruments)[number];

export const isInstrumentChoice = makeValuesPredicate(instrumentChoices);

export const isSwitchableInstrument = makeValuesPredicate(switchableInstruments);

export const isExecutableInstrument = makeValuesPredicate(executableInstruments);

export const isTileInstrument = makeValuesPredicate(tileInstruments);

export const isLeggedInstrument = makeValuesPredicate(leggedInstruments);

export interface Client {
  companyId: number;
  mnemonicCode: string;
  companyName: string;
  companyFullName: string;
  externalCompanyName: string | null;
  bookingEntityName: string | null;
  primeBroker: string | null;
  maxTenor: string | null;
}

export interface CurrencyPairData {
  pair: string;
  ccy1: string;
  ccy2: string;
  precision: number;
  isInverted: boolean;
  canBeDelivered: boolean;
  notDeliverable: boolean;
  isCashDeliverable: boolean;
  isOptionCurrency: boolean;
}

export interface CurrencyPair extends CurrencyPairData {
  closedDates: readonly string[];
  tenorDatesCache: Record<string, Date | undefined>;
}

export type IndexedCurrencyPairs = Record<CurrencyPair['pair'], CurrencyPair>;

export interface ClientMap {
  [clientId: string]: Readonly<Client>;
}

export interface ReferenceDataState {
  readonly instruments: readonly InstrumentChoice[];
  readonly ccyPairs: Readonly<IndexedCurrencyPairs>;
  readonly userInfo: Readonly<UserInfo>;
  readonly userClients: Readonly<ClientMap>;
  readonly featureToggles: Readonly<FeatureToggles>;
  readonly productsAccess: Readonly<ProductsAccess>;
  readonly espLimits: Readonly<EspLimits>;
  readonly localMarketsCcy: readonly string[];
  readonly pinnedCompanies: readonly number[];
}

export type UserType = 'Internal Sales' | 'Internal Trader' | null;

export interface UserInfo {
  login: string | null;
  ulysseLogin: string | null;
  bdrId: string | null;
  userType: UserType;
  hasFxTcaAccess: boolean;
  isOnBehalf: boolean;
  canTrade: boolean;
  isFastTrade: boolean;
}
export const defaultUserInfo: UserInfo = {
  login: null,
  ulysseLogin: null,
  bdrId: null,
  userType: null,
  hasFxTcaAccess: false,
  isOnBehalf: false,
  canTrade: false,
  isFastTrade: false,
};

export interface EspLimits {
  readonly [currency: string]: number | undefined;
}
