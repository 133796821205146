import { getCashInput } from './factories';

export const getCashCurrencyPair = getCashInput('currencyPair');
export const getCashIsNonDeliverable = getCashInput('isNonDeliverable');
export const getCashAmountCurrency = getCashInput('amountCurrency');
export const getCashMaturityDate = getCashInput('maturityDate');
export const getCashFixingSource = getCashInput('fixingSource');
export const getCashFixingCurrency = getCashInput('fixingCurrency');
export const getCashXCurrency = getCashInput('xCurrency');
export const getCashSndFixingSource = getCashInput('sndFixingSource');
export const getCashMaturityDateTenor = getCashInput('maturityDateTenor');
export const getCashAmount = getCashInput('amount');
