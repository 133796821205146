import { getClientIdByQuoteId, getQuoteInstrument } from 'state/clientWorkspace/selectors';
import {
  getUserClientById,
  getCompanyIdFromClientId,
  getCompanyNameFromClientId,
} from 'state/referenceData/referenceDataSelectors';
import type { AppState } from 'state/model';
import { getOptionStreamState } from 'state/fxOptions/selectors';
import { getTileCurrentStreamId } from './selectors';
import type { IFxTileMetadata } from './fxTileModel';
import type { HelpRequestIdParam, TileError } from 'api/help/sendHelpRequest';
import { getTileRfsStream } from 'state/sharedSelectors';
import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { IFxExecutionErrorState } from 'state/executions/executionsStateModel';
import { createSelector } from 'reselect';
import { getAccumulatorTraderId, getAccumulatorQuoteContribution } from 'state/fxAccumulators/selectors';
import { isDefined } from '@sgme/fp';
import { strictValues } from 'utils/object';
import { getCashRfsStreamState } from 'state/fxCashs/selectors';
import { getSwapRfsStream } from 'state/fxSwaps/selectors';

export function getClientForTile(state: AppState, tileId: string) {
  const clientId = getClientIdByQuoteId(state, tileId);
  if (clientId === undefined) {
    throw new Error(`Cannot find clientId with tile id ${tileId}`);
  }
  const client = getUserClientById(state, clientId!);
  if (client === undefined) {
    throw new Error(`Cannot find client with id ${clientId}`);
  }
  return client;
}

export function getCompanyIdFromQuoteId(state: AppState, quoteId: string): number | null {
  const clientId = getClientIdByQuoteId(state, quoteId);
  if (clientId !== null) {
    return getCompanyIdFromClientId(state, clientId);
  }
  return null;
}
export function getCompanyNameFromQuoteId(state: AppState, quoteId: string): string | null {
  const clientId = getClientIdByQuoteId(state, quoteId);
  if (clientId !== null) {
    return getCompanyNameFromClientId(state, clientId);
  }
  return null;
}

export const getTileTraderId = (state: AppState, quoteId: string) => {
  const streamId = getTileCurrentStreamId(state, quoteId);
  if (streamId === null) {
    return null;
  }

  const instrument = getQuoteInstrument(state, quoteId);
  switch (instrument) {
    case 'Option':
      const optionStream = getOptionStreamState(state, streamId);

      if (
        optionStream?.status === 'AWAITING' ||
        optionStream?.status === 'PRICING' ||
        optionStream?.status === 'EXPIRED'
      ) {
        return optionStream.traderId;
      }
      return null;
    case 'Cash':
      const cashStream = getCashRfsStreamState(state, streamId);
      if (cashStream.status !== 'PRICING') {
        return null;
      }
      return cashStream.quote.traderId;

    case 'Accumulator':
      return getAccumulatorTraderId(state, streamId);
    case 'Swap':
      const swapStream = getSwapRfsStream(state, streamId);

      if (swapStream.status !== 'PRICING') {
        return null;
      }

      return swapStream?.quote?.traderId;
    default:
      return null;
  }
};

export const getTileContribution = (state: AppState, quoteId: string) => {
  const streamId = getTileCurrentStreamId(state, quoteId);
  if (streamId === null) {
    return null;
  }

  const instrument = getQuoteInstrument(state, quoteId);
  switch (instrument) {
    case 'Option':
      const stream = getOptionStreamState(state, streamId);

      if (stream?.status === 'PRICING' || stream?.status === 'EXPIRED') {
        return stream.quoteContribution;
      }
      return null;
    case 'Accumulator':
      return getAccumulatorQuoteContribution(state, streamId);
    default:
      return null;
  }
};

const emptyFaultyIds: HelpRequestIdParam = {
  rfsIdsErrors: [],
  executionIdsErrors: [],
  rfsIdsNoPrice: [],
  espIdsNoPrice: [],
};

export const getFaultyStreamIds = (state: AppState): HelpRequestIdParam => {
  const options = Object.entries(state.fxOptions.options);
  const cashs = Object.entries(state.fxCashs.cashs);
  const swaps = Object.entries(state.fxSwaps.swaps);
  const accu = Object.entries(state.fxAccumulators.accumulators);
  const bulks = Object.entries(state.fxBulks.bulks);
  const executions = strictValues(state.executions);

  const all = [...options, ...cashs, ...swaps, ...accu, ...bulks];
  const allValues = all.map(a => a[1]).filter(isDefined);
  const espIdsNoPrice = allValues
    .filter(({ currentEspStreamId }) => state.espStreams[currentEspStreamId!]?.status === 'AWAITING')
    .map(c => c.currentExecutionId)
    .filter(isDefined);
  const rfsIdsErrors = getRfsErrorMessages(allValues);
  const rfsIdsNoPrice = all
    .filter(([tileId]) => getTileRfsStream(state, tileId)?.status === 'AWAITING')
    .map(([, streamState]) => streamState?.currentExecutionId!);

  const executionIdsErrors = executions
    .filter(({ status }) => status === 'Error')
    .map(execution => toTileError((execution as IFxExecutionErrorState).streamError));

  if (!rfsIdsErrors.length && !executionIdsErrors.length && !rfsIdsNoPrice.length && !espIdsNoPrice.length) {
    return emptyFaultyIds;
  } else {
    return faultyIdsSelector({
      rfsIdsErrors,
      executionIdsErrors,
      rfsIdsNoPrice,
      espIdsNoPrice,
    });
  }
};

// keep the same object to avoid rerenders
const faultyIdsSelector = createSelector([JSON.stringify], JSON.parse);

const getRfsErrorMessages = (values: readonly IFxTileMetadata[]) =>
  values.filter(v => isDefined(v?.lastStreamError?.multipassSessionId)).map(v => toTileError(v?.lastStreamError!));

const toTileError = (v: IStreamError): TileError => ({
  id: v!.multipassSessionId!,
  message: v!.message!,
});
