import { memo } from 'react';
import { useIntl } from 'react-intl';
import { noop } from '@sgme/fp';
import { NumberInputRenderProps } from 'components/share/NumberInput/NumberInputRenderProps';
import * as e2e from './e2e';
import type { NumberInputProps } from './typings';
import { inputClassName } from './className';
import { InputGroup } from './InputGroup';
import { useTabContext } from 'components/contexts/tabContext';

export const BareNumberInput = memo((props: NumberInputProps) => {
  const { locale } = useIntl();
  const tabIndex = useTabContext();
  return (
    <NumberInputRenderProps
      value={props.value}
      locale={locale}
      onSubmit={props.readOnly === true ? noop : props.onSubmit}
      onBlurPristine={props.readOnly === true ? undefined : props.onBlurPristine}
      withAccelerators={props.withAccelerators}
    >
      {({ value, ...renderProps }) => (
        <input
          type="text"
          tabIndex={props.tabNoIndex === true ? -1 : tabIndex}
          autoComplete="off"
          readOnly={props.readOnly}
          data-e2e={e2e.input(props.e2eHandle)}
          data-e2e-level={e2e.inputErrorLevel(props.alertLevel)}
          data-e2e-is-modified={e2e.isModifiedHandleValue(props.e2eIsModified)}
          inputMode="decimal"
          className={`${inputClassName(props.alertLevel)} ${props.inputClassName ?? ''}`}
          value={value}
          {...(props.readOnly ? {} : renderProps)}
        />
      )}
    </NumberInputRenderProps>
  );
});

export const NumberInput = (props: NumberInputProps) => (
  <InputGroup ref={props.innerRef}>
    <BareNumberInput {...props} />
  </InputGroup>
);
