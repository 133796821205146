import { FormattedMessage } from 'react-intl';
import { IsInternal } from 'components/share/UserType';
import { withQuoteId } from 'components/contexts';
import { connectQuoteError } from './connect';
import * as e2e from 'components/Form/Inputs/e2e';

export interface QuoteErrorProps {
  resetLabelId?: string;
  showErrorDetails(): void;
  reset(): void;
}

const br = { br: <br /> };
const QuoteErrorRaw: React.FunctionComponent<QuoteErrorProps> = ({
  resetLabelId = 'tradecapture.error.resetView',
  reset,
  showErrorDetails,
}) => (
  <div
    className="d-flex flex-column my-auto align-items-center text-center text-danger w-100 mt-3"
    data-e2e="quote-error"
  >
    <i className="icon icon-xl">error_outline</i>
    <div className="mt-3">
      <FormattedMessage id="tradecapture.error.title" tagName="strong" />
    </div>
    <FormattedMessage id="tradecapture.error.messageView" tagName="div" values={br} />
    <button
      type="button"
      className="btn btn-flat-danger font-weight-bold mt-3"
      onClick={reset}
      data-e2e={e2e.button('reset-error')}
    >
      <FormattedMessage id={resetLabelId} />
    </button>
    <br />
    <IsInternal>
      <button
        className="btn btn-danger mt-3"
        type="button"
        onClick={showErrorDetails}
        data-e2e={e2e.button('show-error-detail')}
      >
        View SG log
      </button>
    </IsInternal>
  </div>
);

export const QuoteError = withQuoteId(connectQuoteError(QuoteErrorRaw));
