import { FormattedMessage } from 'react-intl';
import { connectLockButton } from './connect';
import { MenuButton } from './MenuButton';

export interface LockButtonProps {
  locked: boolean;
  canToggleLock: boolean;
  toggleLock(): void;
}

const lockedTooltip = <FormattedMessage id="navMenuButtons.lockTrading.enabled" />;

const unlockedTooltip = <FormattedMessage id="navMenuButtons.lockTrading.disabled" />;

const br = { br: <br /> };
const readOnly = <FormattedMessage id="navMenuButtons.lockTrading.readOnly" values={br} />;

export const LockButtonRaw: React.FunctionComponent<LockButtonProps> = ({
  locked,
  canToggleLock,
  toggleLock,
}) => (
  <MenuButton
    color="primary"
    e2eHandle="lock-trading"
    disabled={canToggleLock === false}
    iconClassName={locked ? 'icon icon-md text-danger' : undefined}
    onClick={toggleLock}
    tooltip={canToggleLock === false ? readOnly : locked ? lockedTooltip : unlockedTooltip}
  >
    <i className={`icon icon-md my-1${locked ? ' text-danger' : ''}`}>
      {locked ? 'lock' : 'lock_open'}
    </i>
  </MenuButton>
);

export const LockButton = connectLockButton(LockButtonRaw);
