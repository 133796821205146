import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import type { NotificationZoneProps } from './typings';
import { getHelpCenter } from '../HelpCenter';

interface HelpCenterLinkProps {
  helpCenterLinkId?: string;
}

const displayHelpCenterLink = (topicId: string) => getHelpCenter().topic(topicId);

const HelpCenterLink: React.FunctionComponent<HelpCenterLinkProps> = ({ helpCenterLinkId }) => {
  const displayLink = useCallback(
    () => displayHelpCenterLink(helpCenterLinkId!),
    [helpCenterLinkId],
  );
  return helpCenterLinkId === undefined ? null : (
    <a className="alert-link" href="#" onClick={displayLink}>
      <FormattedMessage id="header.notification.linkToHelpCenter" />
    </a>
  );
};

export const NotificationZone: React.FunctionComponent<NotificationZoneProps> = ({ messages }) => (
  <div className="sgwt-account-center">
    <div className="sgwt-account-center-tags">
      {messages.map((notif, index) => (
        <div key={index} className={`sgwt-account-center-tag p-1 alert-${notif.level}`}>
          <FormattedMessage id={notif.messageId} />{' '}
          <HelpCenterLink helpCenterLinkId={notif.helpCenterLinkId} />
        </div>
      ))}
    </div>
  </div>
);
