import { type ClassAttributes, Component, type Ref } from 'react';
import { noop } from '@sgme/fp';

// https://sgithub.fr.world.socgen/DT-Widgets/sgwt-widgets/blob/master/src/sgwt-account-center/README.md

interface AccountCenterHTMLAttributes {
  authentication: 'sso-v1' | 'sg-connect-v2';
  'available-languages'?: string; // string[] => .join(',');
  debug?: boolean;
  environment?: string;
  'hide-current-language'?: boolean;
  'hide-services-link'?: boolean;
  i18n?: string; // json
  language?: string; // default en
  mode?: 'sg-markets';
  'navigate-as'?: boolean;
  'navigate-as-user'?: string; // json extends {name: string;}
  'navigate-as-list'?: string; // json extends Array<{name: string;}>
  user?: string; // json extends {name: string;}
  'show-notification'?: boolean;
  'producer-code'?: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-account-center': ClassAttributes<AccountCenterElement> & AccountCenterHTMLAttributes;
    }
  }
}

interface User {
  name: string;
}

interface LanguageEvent {
  language: string;
}
// events
const languageChanged = 'sgwt-account-center--language-changed';
const navigateAsSelectUser = 'sgwt-account-center--navigate-as-select-user';
const ready = 'sgwt-account-center--ready';
const signIn = 'sgwt-account-center--sign-in';
const signOut = 'sgwt-account-center--sign-out';
const stopNavigationAs = 'sgwt-account-center--stop-navigation-as';

interface AccountCenterElementEventMap {
  [languageChanged]: CustomEvent<LanguageEvent>;
  [navigateAsSelectUser]: CustomEvent<User>;
  [ready]: Event;
  [signIn]: CustomEvent<User>;
  [signOut]: Event;
  [stopNavigationAs]: CustomEvent<User>;
}

type AccountCenterElementListenersMap = {
  [K in keyof AccountCenterElementEventMap]: (e: AccountCenterElementEventMap[K]) => void;
};

interface AccountCenterElement extends Element {
  addEventListener<K extends keyof AccountCenterElementEventMap>(
    type: K,
    listener: (this: AccountCenterElement, ev: AccountCenterElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener<K extends keyof AccountCenterElementEventMap>(
    type: K,
    listener: (this: AccountCenterElement, ev: AccountCenterElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ): void;
  changeLanguage(newLanguage: string): void;
  setUser(user: User): void;
  setNavigateAsUser(user: User): void;
  signOut(): void;
  stopNavigationAs(): void;
}

interface AccountCenterAttributesProps {
  authentication: 'sso-v1' | 'sg-connect-v2';
  language?: string; // default en
  availableLanguages?: string[]; // string[] => .join(',');
  // i18n?: string; // json
  debug?: boolean;
  environment?: string;
  hideCurrentLanguage?: boolean;
  hideServicesLink?: boolean;
  mode?: 'sg-markets';
  showNotification?: boolean;
  user?: string; // json extends {name: string;}
  // ship last two only if navigate as is true
  navigateAs?: true;
  navigateAsUser?: User; // json extends {name: string;}
  navigateAsList?: User[]; // json extends Array<{name: string;}>
  producerCode?: string;
}
interface AccountCenterCallBackProps {
  languageChanged(language: string): void;
  navigateAsSelectUser(event: User): void;
  ready(): void;
  signIn(event: User): void;
  signOut(): void;
  stopNavigationAs(event: User): void;
}

function ed<T>({ detail }: CustomEvent<T>): T {
  return detail;
}
function pluck<T>(str: keyof T) {
  return (obj: T) => obj[str];
}

const pluckLanguage = pluck<LanguageEvent>('language');

export class AccountCenter extends Component<
  AccountCenterAttributesProps & Partial<AccountCenterCallBackProps>
> {
  public static defaultProps: AccountCenterCallBackProps = {
    languageChanged: noop,
    navigateAsSelectUser: noop,
    ready: noop,
    signIn: noop,
    signOut: noop,
    stopNavigationAs: noop,
  };

  private accountCenterElement: AccountCenterElement | null = null;

  private events: AccountCenterElementListenersMap = {
    [languageChanged]: event => this.props.languageChanged!(pluckLanguage(ed(event))),
    [navigateAsSelectUser]: event => this.props.navigateAsSelectUser!(ed(event)),
    [ready]: () => this.props.ready!(),
    [signIn]: event => this.props.signIn!(ed(event)),
    [signOut]: () => this.props.signOut!(),
    [stopNavigationAs]: event => this.props.stopNavigationAs!(ed(event)),
  };

  private ref: Ref<AccountCenterElement> = accountCenterElement => {
    if (accountCenterElement !== null) {
      Object.entries(this.events).forEach(([type, event]) =>
        accountCenterElement.addEventListener(type as any, event),
      );
    }
    this.accountCenterElement = accountCenterElement;
  };
  public componentWillUnmount() {
    const accountCenterElement = this.accountCenterElement;
    if (accountCenterElement !== null) {
      Object.entries(this.events).forEach(([type, event]) =>
        accountCenterElement.removeEventListener(type as any, event),
      );
    }
  }

  public render() {
    return (
      <sgwt-account-center
        ref={this.ref}
        authentication={this.props.authentication}
        available-languages={join(this.props.availableLanguages)}
        debug={bool(this.props.debug)}
        environment={this.props.environment}
        hide-current-language={bool(this.props.hideCurrentLanguage)}
        hide-services-link={bool(this.props.hideServicesLink)}
        // i18n={json(this.props.i18n)} // shape ?
        language={this.props.language}
        mode={this.props.mode}
        navigate-as={bool(this.props.navigateAs)}
        navigate-as-user={json(this.props.navigateAsUser)} //  string; // json extends {name: string;}
        navigate-as-list={json(this.props.navigateAsList)} // ?: string; // json extends Array<{name: string;}>
        user={json(this.props.user)} // ?: string; // json extends {name: string;}
        show-notification={bool(this.props.showNotification)}
        producer-code={this.props.producerCode}
      />
    );
  }
}

function bool(prop?: any): boolean | undefined {
  return prop === true ? true : undefined;
}
function json(prop?: any): string | undefined {
  return prop === undefined ? undefined : JSON.stringify(prop);
}
function join(prop?: string[]): string | undefined {
  return prop?.join(',');
}
