interface AccumulatorCollapseButtonProps {
  isCollapsed: boolean;
}

export function AccumulatorCollapseButton({ isCollapsed }: AccumulatorCollapseButtonProps) {
  return (
    <button type="button" className="btn btn-sm btn-link btn-icon-text pr-0">
      <i className="icon line-height-1 btn-group text-xxlarge cursor-pointer ml-auto">
        {isCollapsed ? 'unfold_less' : 'unfold_more'}
      </i>
    </button>
  );
}
