import type { Thunk } from '../../index';
import { getFieldContent } from '../../../utils/fieldSelectors';
import { FIRST_CURRENCY, SECOND_CURRENCY } from '../../share/productModel/litterals';
import { isDefined, isDefinedAndNonEmpty } from '@sgme/fp';

export function accumulatorHedgeNotionalChangedThunk(quoteId: string, hedgeAmount: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const hedgeCurrency = getFieldContent(sl.getAccumulatorHedgeCurrency(state, quoteId)) ?? FIRST_CURRENCY;

    dispatch(
      ac.accumulatorPropertiesChanged(quoteId, {
        hedgeAmount,
        hedgeCurrency,
      }),
    );
  };
}

export function accumulatorHedgeNotionalCurrencyChangedThunk(accumulatorId: string, hedgeAmount: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const amount = isDefinedAndNonEmpty(hedgeAmount)
      ? hedgeAmount
      : getFieldContent(sl.getAccumulatorHedgeAmount(state, accumulatorId));

    const oldCcy = getFieldContent(sl.getAccumulatorHedgeCurrency(state, accumulatorId));
    const newCcy = oldCcy === FIRST_CURRENCY ? SECOND_CURRENCY : FIRST_CURRENCY;

    if (amount === null) {
      dispatch(
        ac.accumulatorPropertiesChanged(accumulatorId, {
          hedgeCurrency: newCcy,
        }),
      );

      // If there is an active RFS, restart it or it will send hedge in wrong currency
      // TODO ABO - Should we implement this in accumulator case ?
      // if (sl.getTileCurrentStreamId(state, accumulatorId) !== null) {
      //   dispatch(ac.optionStreamRefreshThunk(optionId, true, true, true));
      // }
    } else {
      dispatch(
        ac.accumulatorPropertiesChanged(accumulatorId, {
          hedgeAmount: isDefined(amount) ? amount.toString() : null,
          hedgeCurrency: newCcy,
        }),
      );
    }
  };
}
