import type { LogDealInfoLeg, LogInstrumentType } from 'api/eventLog';
import type { FxExecutionState } from 'state/executions/executionsModel';

export function getLogInstrumentType(execution: FxExecutionState): LogInstrumentType {
  if (execution.instrument === 'Cash') {
    return execution.productName === 'FxSpot' ? 'Spot' : 'Forward';
  }
  return execution.instrument;
}

export function getLegDealInfo(execution: FxExecutionState): readonly LogDealInfoLeg[] {
  switch (execution.instrument) {
    case 'Cash':
      return [
        {
          index: 'Default',
          negotiatedAmount: execution.notionalAmount,
          negotiatedCurrency: execution.notionalCurrency,
        },
      ];
    case 'Option':
      const [ccy1, ccy2] = execution.currencyPair.split('/');
      return Object.entries(execution.legs).map(([lkey, lvalue]) => {
        return {
          index: lkey,
          negotiatedAmount: lvalue.notionalAmount!,
          negotiatedCurrency: lvalue.notionalCurrency === 1 ? ccy1 : ccy2,
        };
      });
    case 'Swap':
      return [
        {
          index: 'Near',
          negotiatedAmount: execution.nearNotionalAmount,
          negotiatedCurrency: execution.notionalCurrency,
        },
        {
          index: 'Far',
          negotiatedAmount: execution.farNotionalAmount,
          negotiatedCurrency: execution.notionalCurrency,
        },
      ];
  }
  return [];
}
