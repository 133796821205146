import { button, menuItem } from 'components/Form/Inputs/e2e';

import { FormattedMessage } from 'react-intl';
import { connect, type MapDispatchToPropsParam } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {
  moveDownPinnedClientAction,
  moveUpPinnedClientAction,
  pinClientAction,
  unpinClientAction,
} from 'state/referenceData';
export type PinnedStatus = undefined | 'pinned' | 'first' | 'last' | 'only';
interface ClientListRowMenuOwnProps {
  companyId: number;
  pinnedStatus: PinnedStatus;
}
interface ClientListRowMenuDispatchProps {
  pinClient: () => void;
  unpinClient: () => void;
  moveUpClient: () => void;
  moveDownClient: () => void;
}

type ClientListRowMenuProps = ClientListRowMenuOwnProps & ClientListRowMenuDispatchProps;

export const ClientListRowMenu = ({
  pinClient,
  unpinClient,
  moveUpClient,
  moveDownClient,
  pinnedStatus,
}: ClientListRowMenuProps) => (
  <UncontrolledDropdown>
    <DropdownToggle
      color="flat-primary"
      className="btn-discreet btn-icon px-2 py-0"
      data-e2e={button('tileMenu')}
      onClick={e => e.stopPropagation()}
    >
      <i className="icon icon-lg">more_horiz</i>
    </DropdownToggle>
    <DropdownMenu className="p-0" persist={true}>
      {pinnedStatus === undefined && (
        <DropdownItem
          // eslint-disable-next-line no-console
          onClick={e => {
            pinClient();
            e.stopPropagation();
          }}
          data-e2e={menuItem('pin')}
        >
          <FormattedMessage id="clientpicker.pinToTop" />
        </DropdownItem>
      )}
      {canMoveUp(pinnedStatus) && (
        <DropdownItem
          // eslint-disable-next-line no-console
          onClick={e => {
            moveUpClient();
            e.stopPropagation();
          }}
          data-e2e={menuItem('pin')}
        >
          <FormattedMessage id="clientpicker.moveUp" />
        </DropdownItem>
      )}
      {canMoveDown(pinnedStatus) && (
        <DropdownItem
          // eslint-disable-next-line no-console
          onClick={e => {
            moveDownClient();
            e.stopPropagation();
          }}
          data-e2e={menuItem('pin')}
        >
          <FormattedMessage id="clientpicker.moveDown" />
        </DropdownItem>
      )}
      {pinnedStatus && (
        <DropdownItem
          // eslint-disable-next-line no-console
          onClick={e => {
            unpinClient();
            e.stopPropagation();
          }}
          data-e2e={menuItem('pin')}
        >
          <FormattedMessage id="clientpicker.unpin" />
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
);

function canMoveUp(pinnedStatus: PinnedStatus) {
  return pinnedStatus !== undefined && pinnedStatus !== 'first' && pinnedStatus !== 'only';
}

function canMoveDown(pinnedStatus: PinnedStatus) {
  return pinnedStatus !== undefined && pinnedStatus !== 'last' && pinnedStatus !== 'only';
}

const mapDispatchToProps: MapDispatchToPropsParam<
  ClientListRowMenuDispatchProps,
  ClientListRowMenuOwnProps
> = (dispatch, ownProps): ClientListRowMenuDispatchProps => ({
  pinClient: () => dispatch(pinClientAction(ownProps.companyId)),
  unpinClient: () => dispatch(unpinClientAction(ownProps.companyId)),
  moveUpClient: () => dispatch(moveUpPinnedClientAction(ownProps.companyId)),
  moveDownClient: () => dispatch(moveDownPinnedClientAction(ownProps.companyId)),
});

export const ClientListRowMenuConnected = connect(undefined, mapDispatchToProps)(ClientListRowMenu);
