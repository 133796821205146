import type { IFxOrderValues, OrderType } from 'state/fxOrders/fxOrdersModel';
import { isAlgoOrder } from 'state/fxOrders/fxOrdersModel';
import type {
  OmsOrderSubmissionPayload,
  OmsOrderValidationResponse,
  OrderValidationDetail,
  OrderValidationResponse,
} from 'api/order/oms';
import { type DayAndTime, extractDayAndTime, toUtc } from 'utils/dateFormats';
import { format, parseISO } from 'date-fns';
import { mapOrDefault } from 'utils/optional';
import { isDefined } from '@sgme/fp';

export const getLimitPrice = (
  response: OmsOrderValidationResponse,
  orderType: OrderType,
  allowOrderTakeProfitMargin: boolean,
) => {
  //todo 4466 - remove toggleCheck once featureToggle is enabled
  if (orderType === 'TakeProfit' && allowOrderTakeProfitMargin) {
    return response.limitPrice;
  }
  if (isAlgoOrder(orderType)) {
    return isDefined(response.defaultPrice) ? response.defaultPrice : undefined;
  }

  //currently limitPrice is not sent back neither consumed for other type of orders
  return undefined;
};

export const extractAndComputeOrderValidationDetails = (
  response: OmsOrderValidationResponse,
  orderType: OrderType,
  allowOrderTakeProfitMargin: boolean
): OrderValidationResponse => {
  const validationResponseDetailContent = response.validationResponseDetailContent.reduce(
    (acc, omsError) => {
      const field = omsFieldToStateField[omsError.field as ResponseOmsSubmissionField];
      if (field === undefined) {
        return acc;
      }
      const code = `error-oms-${omsError.errorCode}`;
      const error: OrderValidationDetail = {
        code,
        field,
        description: omsError.errorDescription,
      };
      return [...acc, error];
    },
    [] as readonly OrderValidationDetail[],
  );

  const isAlgo = isAlgoOrder(orderType);
  const { day: expiryDay, time: expiryTime } = parseAndExtractDayAndTime(response.expiryDay);
  const maturityDay = parseAndExtractDay(response.maturityDay);

  return {
    isReadyToSubmit: response.isReadyToSubmit,
    validationDetails: validationResponseDetailContent,
    expiryDay,
    expiryTime,
    maturityDay: isAlgo ? maturityDay : undefined,
    limitPrice: getLimitPrice(response, orderType, allowOrderTakeProfitMargin),
    ...(allowOrderTakeProfitMargin && orderType === 'TakeProfit'
      ? { customerPrice: response.customerPrice }
      : {}),
    margin: response.margin,
    fixingTypes: response.fixingTypes,
    isCcyForcedBidAsk: response.isCcyForcedBidAsk,
    isStandardGroup: response.isStandardGroup,
    fixingTimesOfSelectedType: response.fixingTimesOfSelectedType,
    sourceNameOfSelectedType: response.sourceNameOfSelectedType,
    canModifyMarginInBps: response.canModifyMarginInBps,
    marginInBps: response.marginInBps,
    fixingMarginType: response.fixingMarginType,
    ndfFixingDate: response.ndfFixingDate,
    ndfFixingSource: response.ndfFixingSource
  };
};

export type ResponseOmsSubmissionField = keyof OmsOrderSubmissionPayload & 'tenor' & 'maturityDate';
const omsFieldToStateField: Record<ResponseOmsSubmissionField, keyof IFxOrderValues | undefined> = {
  bdrId: undefined,
  bdrIni: undefined,
  ulysseLogin: undefined,
  owner: undefined,
  ccyPair: 'currencyPair',
  amountInCcy1: 'amount',
  amountInCcy2: 'amount',
  customerPrice: 'customerPrice',
  expiryDay: 'expiryDay',
  expiryTime: 'expiryTime',
  emails: 'emails',
  limitPrice: 'limitPrice',
  triggerMode: 'trigger',
  way: 'way',
  isGtc: 'isGtc',
  maturityDateString: 'maturityDate',
  maturityDate: 'maturityDate',
  tenor: 'maturityDate',
  startDateUtc: 'startDate',
  endDateUtc: 'expiryDay',
  clipSize: 'clipSize',
  clippingMode: 'clippingMode',
  liquidityPool: 'liquidityPool',
  dodging: 'randomize',
  spreadCapture: 'spreadCapture',
  alphaSeeker: 'alphaSeeker',
  speed: 'speed',
  fixingDateUtc: 'fixingDateUtc',
  fixingPriceType: 'fixingPriceType',
  margin: 'margin',
};

export const parseAndExtractDayAndTime = mapOrDefault(
  (receivedDateTime: string): Partial<DayAndTime> => extractDayAndTime(parseISO(receivedDateTime)),
  { day: undefined, time: undefined },
);

export const parseAndExtractDay = (receivedDate: string | null): string | undefined =>
  receivedDate ? format(toUtc(parseISO(receivedDate)), 'yyyy-MM-dd') : undefined;
