import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { Inputs } from '../productModel';
import { fieldData } from 'utils/fieldSelectors';
import type { DuplicatableInstruments } from './typings';
import { cashMappers } from './cashMappers';
import { swapMappers } from './swapMappers';
import { optionMappers } from './optionMappers';
import { accuMappers } from './accumulatorMappers';

const mappers = {
  Cash: cashMappers,
  Swap: swapMappers,
  Option: optionMappers,
  Accumulator: accuMappers,
};

export function getInputs<I extends DuplicatableInstruments>(instrument: I) {
  return (sl: Selectors, state: AppState, quoteId: string): Partial<Inputs<I>> =>
    Object.entries(mappers[instrument](sl)).reduce((acc, [key, mapper]) => {
      const fieldValue = fieldData(mapper!(state, quoteId)).data;
      return fieldValue ? { ...acc, [key]: fieldValue } : acc;
    }, {} as Partial<Inputs<I>>);
}
