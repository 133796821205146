import { DisableableButtonWithTooltip } from 'components/Form/Inputs/DisableableButtonWithTooltip';
import { noop } from '@sgme/fp';

export interface MenuButtonProps {
  e2eHandle: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  iconClassName?: string;
  color?: 'primary' | 'secondary';
  children: React.ReactNode;
  onClick?(): void;
}

const buttonClassName = (color: string) =>
  `h-100 btn btn-flat-${color} btn-icon btn-nav btn-lg p-2`;

export function MenuButton({
  onClick = noop,
  disabled = false,
  tooltip,
  e2eHandle,
  color = 'primary',
  children,
}: MenuButtonProps): JSX.Element {
  return (
    <DisableableButtonWithTooltip
      className={buttonClassName(color)}
      e2eHandle={e2eHandle}
      onClick={onClick}
      disabled={disabled}
      tooltipContents={tooltip}
    >
      {children}
    </DisableableButtonWithTooltip>
  );
}
