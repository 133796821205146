import type { Instrument } from 'state/referenceData/referenceDataModel';
import { withQuoteId } from 'components/contexts';
import { connectTestUtil } from './connect';

export interface TestUtilSpanProps {
  quoteId: string;
  instrument: Instrument;
  rfsId: string | null;
  streamKey: string | null;
  lastExecutedQuoteId: string | null;
}

export const TestUtilSpanRaw: React.FunctionComponent<TestUtilSpanProps> = ({
  quoteId,
  instrument,
  rfsId,
  streamKey,
  lastExecutedQuoteId,
}) => (
  <span
    data-quote-id={quoteId || ''}
    data-e2e-quote-instrument={instrument || ''}
    data-rfs-id={rfsId || ''}
    data-stream-key={streamKey || ''}
    data-quote-execution-id={lastExecutedQuoteId || ''}
  />
);

export const TestUtilSpan = withQuoteId(connectTestUtil(TestUtilSpanRaw));
