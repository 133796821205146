import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import { isDefined } from '@sgme/fp';

export interface TileStatusConnectOwnProps {
  quoteId: string;
}

export interface TileStatusConnectStateProps {
  hasError: boolean;
  isEmpty: boolean;
}

export interface TileStatusConnectDispatchProps {}

// you can whitelist selectors after implementation
export type TileStatusConnectSelectors = Selectors;
/*
export type TileStatusConnectSelectorsKeys = 'aSelector'; // example
export type TileStatusConnectSelectors = Pick<Selectors, TileStatusConnectSelectorsKeys>;
*/

function hasTileError(state: AppState, sl: TileStatusConnectSelectors, quoteId: string) {
  const requestError = sl.getTilePropertiesRequestError(state, quoteId);
  const clientError = sl.getTileState(state, quoteId).clientError;

  return isDefined(requestError) || Boolean(clientError);
}

export const mapStateToPropsTileStatus: MapStateToPropsHOF<
  TileStatusConnectStateProps,
  TileStatusConnectOwnProps,
  AppState,
  TileStatusConnectSelectors
> =
  sl =>
    (state, { quoteId }) => {
      const hasError =
        sl.isTilePresent(state, quoteId) &&
        hasTileError(state, sl, quoteId);

      const isEmpty = fieldData(sl.getTileCurrencyPair(state, quoteId)).data === null;

      return {
        hasError,
        isEmpty,
      };
    };
