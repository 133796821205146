import { forwardRef, useContext, type ReactNode, type Ref } from 'react';
import { SizeContext } from './SizeContext';
import { FormTypeContext } from './formTypeContext';
import { inputGroupClassName } from './className';

export const InputGroup = forwardRef<HTMLElement, { className?: string; children?: ReactNode }>(
  ({ className = '', children }, ref: Ref<any>) => {
    const size = useContext(SizeContext);
    const formType = useContext(FormTypeContext);
    return formType === 'form' ? (
      <div className={`${inputGroupClassName(size)} ${className}`} ref={ref} children={children} />
    ) : (
      <td className={className}>
        <div className={inputGroupClassName(size)} ref={ref} children={children} />
      </td>
    );
  },
);

InputGroup.displayName = 'InputGroup';
