import { getCashValue } from './factories';

export const getCashProductName = getCashValue('productName');
export const getCashCurrency1 = getCashValue('currency1');
export const getCashCurrency2 = getCashValue('currency2');
export const getCashFixingDate = getCashValue('fixingDate');
export const getCashPossibleFixingCurrencies = getCashValue('possibleFixingCurrencies');
export const getCashPossibleFixingSources = getCashValue('possibleFixingSources');
export const getCashIsCrossed = getCashValue('isCrossed');
export const getCashPossibleXCurrencies = getCashValue('possibleXCurrencies');
export const getCashPossibleSndFixingSources = getCashValue('possibleSndFixingSources');
