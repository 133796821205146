import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'components/share/Select';
import { UserPrefLabel } from './UserPrefLabel';
import { ThemeContext } from 'components/contexts/ThemeContext';
import type { Theme } from 'utils/theme';

export const ChangeThemeComponent: React.FunctionComponent = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  return (
    <UserPrefLabel label="theme">
      <Select<Theme>
        value={theme}
        onChange={setTheme}
        e2eHandle="changeTheme"
        options={['LIGHT', 'DARK']}
        className="form-control w-auto"
      >
        {({ option, value }) => (
          <FormattedMessage id={`userPreferencesForm.theme.${option}`} key={value}>
            {label => <option value={value}>{label}</option>}
          </FormattedMessage>
        )}
      </Select>
    </UserPrefLabel>
  );
};
