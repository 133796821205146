import type { AppState } from 'state/model';
import type { Instrument } from 'state/referenceData/referenceDataModel';
import { getTileState } from 'state/clientWorkspace/selectors';
import { getOptionMetadata } from 'state/fxOptions/selectors/factories';
import { getCashMetadata } from 'state/fxCashs/selectors/factories';
import { getSwapMetadata } from 'state/fxSwaps/selectors/factories';
import { getBulkMetadata } from 'state/fxBulks/selectors/factories';
import { getOrderMetadata } from 'state/fxOrders/selectors/factories';
import { getAccumulatorMetadata } from 'state/fxAccumulators/selectors/factories';
import type { IFxTileMetadata } from '../fxTileModel';
import { getBlotterOrderAlgoMetadata } from 'state/blotter/selectors/blotterOrderSelectors';

type TileSelector<T> = (state: AppState, tileId: string) => T;

type InstrumentSelectors<T> = Record<Instrument, TileSelector<T>>;

export function tileSelector<T>(instrumentSelectors: InstrumentSelectors<T>): TileSelector<T> {
  return (state, tileId) =>
    instrumentSelectors[getTileState(state, tileId).instrument](state, tileId);
}

type SharedMetadata = keyof IFxTileMetadata;

export function getTileMetadata<T extends SharedMetadata>(property: T) {
  return tileSelector<IFxTileMetadata[T]>({
    Option: getOptionMetadata(property),
    Cash: getCashMetadata(property),
    Swap: getSwapMetadata(property),
    Bulk: getBulkMetadata(property),
    Accumulator: getAccumulatorMetadata(property),
    Order: getOrderMetadata(property as any),
    BlotterOrder: getBlotterOrderAlgoMetadata(property as any),
  });
}
