import { useTheme } from 'components/share/hooks/useTheme';
import { getEnvironment } from '../../../configuration';
import { useIntl } from 'react-intl';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['sgwt-help-center']: any;
    }
  }
}

export const HelpCenterId = 'my-help-center';

const defaultSendTo = btoa(window.sgmeConfiguration.helpCenterSendMailto);
export function HelpCenter(): JSX.Element {
  const theme = useTheme();
  const { locale } = useIntl();
  return (
    <aside>
      <sgwt-help-center
        id={HelpCenterId}
        language={locale}
        class={theme === 'DARK' ? 'dark' : undefined}
        sg-connect-support="sg-connect-v2"
        default-send-to={defaultSendTo}
        mail-subject={`[SGME-FX:${getEnvironment()?.toUpperCase() ?? 'PROD'}] Help center message`}
        application-id="sgme-fx"
        allow-screenshot={true}
      />
    </aside>
  );
}

export interface HelpCenterApi extends Element {
  open: () => void;
  category: (categoryId: string) => void;
  topic: (topicId: string) => void;
  search: (value: string, categoryId?: string) => void;
  form: () => void;
  chat: () => void;
  close: () => void;
  setIntroductionTour: (introductionTour: boolean) => void;
  setEmailEndpoint: (emailEndpoint: string) => void;
  setDefaultSendTo: (sendTo: string) => void;
  setAdditionalMessageInformation: (additionalMessageInformation?: { [key: string]: any }) => void;
  setMessageTopics: (messageTopics: object[]) => void;
  setLanguage: (language: string) => void;
  setI18n: (i18n: object) => void;
  setUser: (user: object) => void;
  setKnowledge: (knowledge: object) => void;
  setKnowledgeUrls: (knowledgeUrls: string[]) => void;
  setApplicationId: (applicationId: string) => void;
  setMessageOnly: (messageOnly: boolean) => void;
  setHandleVisible: (handleVisible: boolean) => void;
  setMailSubject: (mailSubject: string | null) => void;
}

const helpCenterQuery = `#${HelpCenterId}`;
export const getHelpCenter = () => document.querySelector<HelpCenterApi>(helpCenterQuery)!;
