import type { FxAccumulatorRfsStream, FxAccuStreamStreamingWithPrice, IHedge } from '../fxAccumulatorsModel';
import type { HedgeWay } from 'state/share/productModel/litterals';
import type { AppState } from 'state/model';
import { getAccumulatorActiveStreamId } from './accumulatorMetadata';
import { getAccumulatorRfsState } from './accumulatorRfs';
import { isNotDefined } from '@sgme/fp';
import { isArray } from '../../../utils/immutableReferenceUpdate';

export function getAccumulatorRfsByQuoteId(state: AppState, quoteId: string) {
  const rfsStreamId = getAccumulatorActiveStreamId(state, quoteId);
  if (rfsStreamId === null) {
    return null;
  }
  return getAccumulatorRfsState(state, rfsStreamId);
}

const allowedStreamStatus: Array<FxAccumulatorRfsStream['status']> = ['PRICING', 'EXPIRED'];

function isPricing(stream: FxAccumulatorRfsStream): stream is FxAccuStreamStreamingWithPrice {
  return allowedStreamStatus.includes(stream.status);
}

export function getAccumulatorRfsQuoteByQuoteId(state: AppState, quoteId: string) {
  const rfs = getAccumulatorRfsByQuoteId(state, quoteId);
  if (rfs === null) {
    return null;
  }
  return isPricing(rfs) ? rfs.quote : null;
}

export function getAccumulatorRfsPremiumByQuoteId(state: AppState, quoteId: string) {
  const quote = getAccumulatorRfsQuoteByQuoteId(state, quoteId);
  if (quote === null) {
    throw `Accumulator ${quoteId} has no valid RFS or RFS pricing quote : premium cannot be issued.`;
  }
  return [quote.premiumCcy1, quote.premiumCcy2] as const;
}

export function getAccumulatorRfsPremiumPercentByQuoteId(state: AppState, quoteId: string) {
  const quote = getAccumulatorRfsQuoteByQuoteId(state, quoteId);
  if (quote === null) {
    throw `Accumulator ${quoteId} has no valid RFS or RFS pricing quote : premium cannot be issued.`;
  }
  return [quote.premiumPctCcy1, quote.premiumPctCcy2] as const;
}

export function getAccumulatorRfsDateAndWindowsByQuoteId(
  state: AppState,
  quoteId: string,
): {
  lastQuoteReceivedDate: Date;
  remainingDuration: number;
  totalDuration: number;
} {
  const rfs = getAccumulatorRfsByQuoteId(state, quoteId);
  if (rfs === null || rfs.status !== 'PRICING') {
    throw `Accumulator ${quoteId} has no valid RFS or RFS pricing quote : rfs window cannot be issued.`;
  }
  return {
    lastQuoteReceivedDate: rfs.lastQuoteReceivedDate,
    totalDuration: rfs.initialRfsWindow,
    remainingDuration: rfs.quote.rfsWindow,
  };
}

export function getQuoteHedgeByQuoteId(state: AppState, quoteId: string): IHedge | null {
  const rfs = getAccumulatorRfsByQuoteId(state, quoteId);

  if (rfs === null || rfs.status !== 'PRICING') {
    return null;
  }

  const hedge = isArray(rfs.quote.hedge) ? rfs.quote?.hedge[0] : rfs.quote?.hedge;

  return hedge ?? null;
}

export function getAccumulatorHedgeWayByQuoteId(state: AppState, quoteId: string): HedgeWay {
  const rfs = getAccumulatorRfsByQuoteId(state, quoteId);
  if (rfs === null || rfs.status !== 'PRICING') {
    return 'WayUnknown';
  }
  const quote = rfs.quote;

  if (quote === null || isNotDefined(quote.hedge)) {
    return 'WayUnknown';
  }

  const hedgeWay = isArray(quote.hedge) ? quote.hedge[0].way : quote.hedge.way;

  return hedgeWay || 'WayUnknown';
}

export function hasAccumulatorBypassedLimitCheck(state: AppState, quoteId: string): boolean {
  const streamState = getAccumulatorRfsByQuoteId(state, quoteId);
  return streamState?.status === 'PRICING' && streamState.skippedLimitCheck;
}
