import type { Reducer } from 'redux';
import type { IWorkspaceTiles, IWorkspaceTileState } from '../clientWorkspaceModel';
import type { Action } from 'state/actions';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import type { InstrumentChoice, TileInstrument } from 'state/referenceData/referenceDataModel';

function mapToTileInstrument(instrumentChoice: InstrumentChoice | 'Order'): TileInstrument {
  return instrumentChoice === 'ForwardAccumulator' || instrumentChoice === 'TargetAccumulator'
    ? 'Accumulator'
    : instrumentChoice;
}

export const clientWorkspaceTilesReducer: Reducer<IWorkspaceTiles> = (
  tiles: IWorkspaceTiles = {},
  action: Action,
): IWorkspaceTiles => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      return addKey(tiles, action.tileId, {
        instrument: action.savedTile.instrument,
        expanded:
          action.savedTile.instrument === 'Option' && action.isOptionGreekAndMktExpanded
            ? ['Greeks', 'MarketData']
            : [],
        clientWorkspaceId: action.clientWorkspaceId,
        overrideClientId: action.savedTile.overrideClientId ?? null,
      });
    case 'BULK_CREATED':
      return addKey(tiles, action.bulkId, {
        instrument: 'Bulk',
        expanded: [],
        clientWorkspaceId: action.bulkId,
        overrideClientId: null,
      });
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return addKey(tiles, action.tileId, {
        instrument: mapToTileInstrument(action.instrument),
        expanded:
          action.instrument === 'Option' && action.isOptionGreekAndMktExpanded
            ? ['Greeks', 'MarketData']
            : [],
        clientWorkspaceId: action.clientWorkspaceId,
        overrideClientId: null,
      });
    case 'CLIENTWORKSPACE_TILE_TOGGLE_COLLAPSED':
      return updateKey(tiles, action.tileId, ({ expanded }) => ({
        expanded: expanded.includes(action.keyName)
          ? expanded.filter(key => key !== action.keyName)
          : expanded.concat([action.keyName]),
      }));
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(tiles, action.tileId);
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      return updateKey<IWorkspaceTileState>(tiles, action.tileId, () => ({
        instrument: mapToTileInstrument(action.instrument),
        expanded:
          action.instrument === 'Option' && action.isOptionGreekAndMktExpanded
            ? ['Greeks', 'MarketData']
            : [],
      }));
    case 'ORDER_SUBMISSION_SUCCESS':
      if (action.orderId === null) {
        return tiles;
      }
      const existingTileState = tiles[action.quoteId]!;
      return action.order
        ? removeKey(
            addKey(tiles, action.orderId, {
              ...existingTileState,
              instrument: 'BlotterOrder',
            }),
            action.quoteId,
          )
        : tiles;
    case 'BLOTTER_ORDER_OPEN_TILE':
      return Object.keys(tiles).includes(action.orderId)
        ? tiles
        : addKey(tiles, action.orderId, {
            instrument: 'BlotterOrder',
            clientWorkspaceId: action.currentTabId,
            overrideClientId: action.account,
            expanded: [],
          });
    case 'CLIENTWORKSPACE_CLIENT_CHANGED':
      let newTiles = tiles;
      for (const key of Object.keys(tiles)) {
        newTiles = updateKey(newTiles, key, tile =>
          tile.instrument === 'BlotterOrder' && tile.clientWorkspaceId === action.clientWorkspaceId
            ? {
                overrideClientId:
                  tile.overrideClientId === action.clientId
                    ? null
                    : tile.overrideClientId === null
                    ? action.previousClientId
                    : tile.overrideClientId,
              }
            : {},
        );
      }
      return newTiles;
    case 'CLIENTWORKSPACE_TILE_CLIENT_OVERRIDDEN':
      return updateKey(tiles, action.quoteId, () => ({
        overrideClientId: action.clientId,
      }));
    case 'CLIENTWORKSPACE_TILE_CLIENT_RESET':
      return updateKey(tiles, action.quoteId, () => ({
        overrideClientId: null,
      }));
    case 'CLIENTWORKSPACE_TILE_CLIENT_ERROR_CHANGED':
      return updateKey(tiles, action.tileId, () => ({
        clientError: action.clientError,
      }));
    case 'CASH_TILE_RESET':
    case 'SWAP_TILE_RESET':
    case 'OPTION_TILE_RESET':
    case 'ACCUMULATOR_TILE_RESET':
      return updateKey(tiles, action.quoteId, ({ clientError }) =>
        clientError
          ? {
              clientError: false,
            }
          : null,
      );
    default:
      return tiles;
  }
};
