import { connectNDCheckbox } from './connect';
import { withQuoteId } from 'components/contexts';
import { CheckboxInput } from 'components/share/formElements/CheckboxInput';

interface NDCheckboxProps {
  quoteId: string;
  value: boolean;
  show: boolean;
  disabled: boolean;
  label: string;
  className?: string;
  onChange: (value: boolean) => void;
}

const NDCheckboxRaw: React.FunctionComponent<NDCheckboxProps> = ({
  show,
  disabled,
  onChange,
  value,
  label,
  className,
}) =>
  show ? (
    <CheckboxInput
      e2eHandle="nd"
      value={value}
      onChange={onChange}
      className={className}
      disabled={disabled}
    >
      {label}
    </CheckboxInput>
  ) : (
    <div></div>
  );
export const NDCheckbox = withQuoteId(connectNDCheckbox(NDCheckboxRaw));
