import React, { useCallback, useContext } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { button, menuItem } from 'components/Form/Inputs/e2e';
import { RefToggle } from 'components/share/RefToggle';
import { TileClientPicker } from '../TileClientPicker';
import { CurrencyPickerContext } from 'components/CurrencyPicker';
import CopyToClipBoard from './CopyToClipBoard';

interface TileMenuProps {
  closeTile(): void;
  duplicateTile(): void;
  resetTile(): void;
}

export const TileMenu: React.FunctionComponent<TileMenuProps> = ({
  closeTile,
  duplicateTile,
  resetTile,
}) => {
  const { inputRef } = useContext(CurrencyPickerContext);

  const onReset = useCallback(() => {
    resetTile();
    inputRef.current?.focus();
  }, [resetTile, inputRef]);

  return (
    <RefToggle>
      {clientPicker => (
        <div>
          <UncontrolledDropdown className="align-self-start">
            <DropdownToggle
              color="flat-primary"
              className="btn-discreet btn-icon px-2 py-0"
              data-e2e={button('tileMenu')}
            >
              <i className="icon icon-lg">more_vert</i>
            </DropdownToggle>
            <DropdownMenu className="p-0" persist={true}>
              <DropdownItem
                data-e2e={menuItem('changeAccount')}
                className="pr-0"
                toggle={false}
                onClick={clientPicker.toggle}
              >
                <FormattedMessage id="tile.changeAccount" />
                <i className="icon icon-md ml-2" ref={clientPicker.ref}>
                  arrow_right
                </i>
              </DropdownItem>
              <DropdownItem onClick={duplicateTile} data-e2e={menuItem('duplicateTile')}>
                <FormattedMessage id="tile.duplicate" />
              </DropdownItem>
              <DropdownItem onClick={onReset} data-e2e={menuItem('resetTile')}>
                <FormattedMessage id="tile.reset" />
              </DropdownItem>
              <CopyToClipBoard />
              <DropdownItem onClick={closeTile} data-e2e={menuItem('closeTile')}>
                <FormattedMessage id="tile.close" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {clientPicker.target !== null && (
            <Popover
              target={clientPicker.target}
              placement="right"
              trigger="legacy"
              fade={false}
              isOpen={clientPicker.isOpen}
              open={clientPicker.open}
              close={clientPicker.close}
              toggle={clientPicker.toggle}
            >
              <TileClientPicker close={clientPicker.close} />
            </Popover>
          )}
        </div>
      )}
    </RefToggle>
  );
};
