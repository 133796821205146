import type { Thunk } from 'state';
import { validInputsInFine } from 'state/fxOptions/fxOptionsModel';
import { isDefined, isDefinedAndNonEmpty } from '@sgme/fp';
import { FIRST_CURRENCY, SECOND_CURRENCY } from '../../share/productModel/litterals';
import { getFieldContent } from 'utils/fieldSelectors';

export function optionTilePremiumDateChangedThunk(
  optionId: string,
  premiumDate: string,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const isStrategy = sl.getOptionIsStrategy(getState(), optionId);
    const patch =
      !isStrategy && validInputsInFine.includes(premiumDate.trim().toLocaleLowerCase())
        ? { isInFine: true }
        : { premiumDate };
    dispatch(ac.optionPropertyChanged(optionId, patch));
  };
}

export function optionTilePremiumDateTenorChangedThunk(
  optionId: string,
  premiumDateTenor: string,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(
      ac.optionPropertyChanged(
        optionId,
        premiumDateTenor === 'in fine' ? { isInFine: true } : { premiumDateTenor },
      ),
    );
  };
}

export function optionHedgeNotionalChangedThunk(hedgeId: string, hedgeAmount: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const hedgeCurrency =
      getFieldContent(sl.getOptionHedgeCurrency(state, hedgeId)) ?? FIRST_CURRENCY;

    dispatch(
      ac.optionHedgePropertyChanged(hedgeId, {
        amount: hedgeAmount,
        currency: hedgeCurrency,
      }),
    );
  };
}

export function optionHedgeNotionalCurrencyChangedThunk(
  optionId: string,
  hedgeId: string,
  hedgeAmount: string,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const amount = isDefinedAndNonEmpty(hedgeAmount)
      ? hedgeAmount
      : getFieldContent(sl.getOptionHedgeAmount(state, hedgeId));

    const oldCcy = getFieldContent(sl.getOptionHedgeCurrency(state, hedgeId));
    const newCcy = oldCcy === FIRST_CURRENCY ? SECOND_CURRENCY : FIRST_CURRENCY;

    if (amount === null) {
      dispatch(
        ac.optionHedgePropertyChanged(hedgeId, {
          currency: newCcy,
        }),
      );

      // If there is an active RFS, restart it or it will send hedge in wrong currency
      if (sl.getTileCurrentStreamId(state, optionId) !== null) {
        dispatch(ac.optionStreamRefreshThunk(optionId, true, true, true));
      }
    } else {
      dispatch(
        ac.optionHedgePropertyChanged(hedgeId, {
          amount: isDefined(amount) ? amount.toString() : null,
          currency: newCcy,
        }),
      );
    }
  };
}
