import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { Instrument } from 'state/referenceData/referenceDataModel';

export interface TestUtilConnectOwnProps {
  quoteId: string;
}

export interface TestUtilConnectStateProps {
  instrument: Instrument;
  rfsId: string | null;
  streamKey: string | null;
  lastExecutedQuoteId: string | null;
}

export type TestUtilConnectSelectorsKeys =
  | 'getQuoteInstrument'
  | 'getTileCurrentStreamId'
  | 'getTileEspStreamId'
  | 'getTileLastExecutedQuoteId';
export type TestUtilConnectSelectors = Pick<Selectors, TestUtilConnectSelectorsKeys>;

export const mapStateToPropsTestUtil: MapStateToPropsHOF<
  TestUtilConnectStateProps,
  TestUtilConnectOwnProps,
  AppState,
  TestUtilConnectSelectors
> =
  sl =>
  (state, { quoteId }) => ({
    instrument: sl.getQuoteInstrument(state, quoteId),
    rfsId: sl.getTileCurrentStreamId(state, quoteId),
    streamKey: sl.getTileEspStreamId(state, quoteId),
    lastExecutedQuoteId: sl.getTileLastExecutedQuoteId(state, quoteId),
  });
