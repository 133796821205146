import type { AppState } from 'state/model';
import {
  getValueProperty,
  type ValueKey,
  type SharedKey,
  getSharedProperty,
  type SharedPropertyStatus,
} from 'state/share/form';
import type {
  IFxCashMetadata,
  IFxCashValues as CashValue,
  IFxCashInputs as CashInput,
} from '../fxCashsModel';
import { getCashState } from './cash';

export type CashMetadataStatus<T extends keyof IFxCashMetadata> = IFxCashMetadata[T];

export function getCashMetadata<T extends keyof IFxCashMetadata>(property: T) {
  return (state: AppState, CashId: string): CashMetadataStatus<T> =>
    getCashState(state, CashId)[property];
}
type CashValuePropertyKey = ValueKey<CashValue, CashInput>;

export function getCashValue<T extends CashValuePropertyKey>(prop: T) {
  const get = getValueProperty<CashValue, CashInput, T>(prop);
  return (state: AppState, CashId: string) => get(getCashState(state, CashId));
}

type CashSharedPropertyKey = SharedKey<CashValue, CashInput>;

export function getCashInput<T extends CashSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<CashValue, CashInput, T>(prop);
  return (state: AppState, cashId: string) => get(getCashState(state, cashId));
}

export type CashSharedPropertyStatus<T extends CashSharedPropertyKey> = SharedPropertyStatus<
  CashValue[T],
  CashInput[T]
>;
