import styled from 'styled-components';

interface SpinnerProps {
  waiting: boolean;
}

const SpinnerContainer = styled.div.attrs<SpinnerProps>(({ waiting }) => ({
  className: `d-flex justify-content-center align-items-center position-absolute text-secondary ${
    waiting ? 'bg-primary-alt-alpha-xxl text-primary' : ''
  }`,
}))`
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  ${({ waiting }: SpinnerProps) =>
    waiting
      ? `z-index: 150;
         transition: opacity 1s ease-in 300ms, z-index 0ms linear 300ms;
         opacity: 1;`
      : `z-index: -10;
         opacity: 0;`}
`;

export const SpinnerOverlay: React.FunctionComponent<SpinnerProps> = ({ waiting }) => (
  <SpinnerContainer waiting={waiting}>
    <div className="spinner spinner-xl"></div>
  </SpinnerContainer>
);
