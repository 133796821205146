import { useIntl } from 'react-intl';
import { onFooterSetRef, type SgFooterWidget } from 'utils/userConsent';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-mini-footer':
        | SgFooterWidget
        | FooterHTMLAttributes
        | React.RefAttributes<SgFooterWidget>;
    }
  }
}

interface FooterHTMLAttributes {
  id: string;
  mode?: 'sg-markets';
  'contact-us': string;
  language: string;
  type: 'compact' | 'medium' | 'micro';
  container?: string;
  'cookies-consent'?: boolean;
  'legal-notices'?: string;
  'accessibility-compliance'?: 'none' | 'partial' | 'full';
  'accessibility-link'?: string;
}

export function Footer(): JSX.Element {
  const { locale } = useIntl();
  return (
    <sgwt-mini-footer
      mode="sg-markets"
      language={locale}
      type="micro"
      cookies-consent
      ref={onFooterSetRef}
      legal-notices={JSON.stringify({
        en: [
          {
            label: 'SG Markets Electronic Services Terms',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_ELEC_SERV_TERMS',
          },
          {
            label: 'Notice to Investors',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_TO_INVESTORS',
          },
          {
            label: 'Foreign exchange disclosure',
            value: 'http://swapdisclosure.sgcib.com/fx-disclosure',
          },
        ],
        fr: [
          {
            label: 'Termes du Service SG Markets Electronic',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_ELEC_SERV_TERMS',
          },
          {
            label: 'Notice Investisseurs',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_TO_INVESTORS',
          },
          {
            label: 'Communication pour le Marché des Changes',
            value: 'http://swapdisclosure.sgcib.com/fx-disclosure',
          },
        ],
      })}
      contact-us={JSON.stringify({
        mail: 'sgmarketsfx@sgcib.com',
        phones: [
          { label: 'emea', value: '+33 (0)1 42 13 86 00' },
          { label: 'americas', value: '+1 212 278 6022' },
          { label: 'asia pacific', value: '+852 2166 5707' },
        ],
      })}
      accessibility-compliance="none"
      accessibility-link="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://fx.sgmarkets.com"
    />
  );
}
