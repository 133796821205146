import { FormattedMessage } from 'react-intl';
import { Popover } from 'reactstrap';
import { RefToggle } from 'components/share/RefToggle';
import { TileClientPicker } from '../TileClientPicker';
import * as e2e from 'components/Form/Inputs/e2e';

export interface AccountChangedProps {
  accountName: string | null;
  disabled?: true;
}

export const AccountChanged: React.FunctionComponent<AccountChangedProps> = ({
  accountName,
  disabled = false,
}) =>
  accountName === null ? null : (
    <RefToggle>
      {({ ref, target, isOpen, open, close, toggle }) => (
        <div className="row no-gutters px-2 pt-2">
          <div className="col-12 alert alert-outline-warning p-2 mb-1 font-weight-bold d-flex align-items-center justify-content-between">
            <span
              className="text-truncate"
              title={accountName}
              data-e2e={e2e.text('custom-client-name')}
            >
              {accountName}
            </span>
            {disabled ? null : (
              <>
                <button
                  className="btn btn-link alert-link text-warning p-0"
                  ref={ref}
                  onClick={toggle}
                  data-e2e={e2e.button('edit-custom-client')}
                >
                  <FormattedMessage id="tile.edit" />
                </button>
                {target != null && (
                  <Popover
                    target={target}
                    placement="bottom"
                    trigger="legacy"
                    isOpen={isOpen}
                    open={open}
                    close={close}
                    toggle={toggle}
                  >
                    <TileClientPicker query={accountName} close={close} />
                  </Popover>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </RefToggle>
  );
