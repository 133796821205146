import type {
  FxCashPatch,
  IQuote,
  IFxCashInputs,
  IFxCashMetadata,
  IFxCashExecutionData,
  IFxCashValues,
  CashTileOpenFromBlotter,
} from './fxCashsModel';
import type { Way, CurrencyChoice } from 'state/share/productModel/litterals';
import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { IFxExecutionPendingState } from 'state/executions/executionsStateModel';
import type { CashSavedTile } from 'api/workspaceService/model';
import type { TradeCaptureError } from 'state/tile/fxTileModel';
import type { RestoredTiles } from 'state/clientWorkspace';

export type FxCashAction =
  | CashTileReset
  | CashTileRestoreEpic
  | CashTileOpenFromBlotterEpic
  | CashAmountCurrencyMaskChanged
  | CashPropertiesChanged
  | CashPropertiesRemoveError
  | CashPropertiesPristine
  | CashPropertiesDirty
  | CashPropertiesRequested
  | CashPropertiesReceived
  | CashPropertiesRequestFailed
  | CashRfsRequestedEpic
  | CashRfsStarted
  | CashRfsFailed
  | CashRfsTerminated
  | CashRfsCancelEpic
  | CashRfsCancel
  | CashRfsCancelSent
  | CashRfsCancelFailed
  | CashRfsClearError
  | CashQuoteReceived
  | CashRfsExecutionRequested
  | CashExecutionFailed
  | CashExecutionReceived
  | CashFillReportReceived
  | CashRfsExecutionSent
  | CashLocalPropertyChanged
  | CashEspExecutionSent
  | CashEspExecutionRequested
  | CashExecutionSentSucceeded;

export interface CashTileReset {
  type: 'CASH_TILE_RESET';
  quoteId: string;
}

export function cashTileReset(quoteId: string): CashTileReset {
  return {
    type: 'CASH_TILE_RESET',
    quoteId,
  };
}

export interface CashTileRestoreEpic {
  type: 'CASH_TILE_RESTORE_EPIC';
  tiles: RestoredTiles<CashSavedTile>;
}

export function cashTileRestoreEpic(tiles: RestoredTiles<CashSavedTile>): CashTileRestoreEpic {
  return {
    type: 'CASH_TILE_RESTORE_EPIC',
    tiles,
  };
}

export interface CashTileOpenFromBlotterEpic {
  type: 'CASH_TILE_OPEN_FROM_BLOTTER_EPIC';
  tileId: string;
  tile: CashTileOpenFromBlotter;
}

export function cashTileOpenFromBlotterEpic(
  tileId: string,
  tile: CashTileOpenFromBlotter,
): CashTileOpenFromBlotterEpic {
  return {
    type: 'CASH_TILE_OPEN_FROM_BLOTTER_EPIC',
    tileId,
    tile,
  };
}

export interface CashPropertiesRequestFailed {
  type: 'CASH_PROPERTIES_REQUEST_FAILED';
  quoteId: string;
  error: TradeCaptureError;
}

export function cashPropertiesRequestFailed(
  quoteId: string,
  error: TradeCaptureError,
): CashPropertiesRequestFailed {
  return {
    type: 'CASH_PROPERTIES_REQUEST_FAILED',
    quoteId,
    error,
  };
}

export interface CashLocalPropertyChanged {
  type: 'CASH_LOCAL_PROPERTY_CHANGED';
  tileId: string;
  patch: Partial<IFxCashMetadata>;
}

export function cashLocalPropertyChanged(
  tileId: string,
  patch: Partial<IFxCashMetadata>,
): CashLocalPropertyChanged {
  return {
    type: 'CASH_LOCAL_PROPERTY_CHANGED',
    tileId,
    patch,
  };
}

export interface CashRfsExecutionRequested {
  type: 'CASH_RFS_EXECUTION_REQUESTED';
  cashId: string;
  streamId: string;
  way: Way;
}

export function cashRfsExecutionRequested(
  cashId: string,
  streamId: string,
  way: Way,
): CashRfsExecutionRequested {
  return {
    type: 'CASH_RFS_EXECUTION_REQUESTED',
    cashId,
    streamId,
    way,
  };
}

export interface CashEspExecutionRequested {
  type: 'CASH_ESP_EXECUTION_REQUESTED';
  cashId: string;
  streamId: string;
  way: Way;
  precision: number;
}

export function cashEspExecutionRequested(
  cashId: string,
  streamId: string,
  way: Way,
  precision: number,
): CashEspExecutionRequested {
  return {
    type: 'CASH_ESP_EXECUTION_REQUESTED',
    cashId,
    streamId,
    way,
    precision,
  };
}

export interface CashExecutionReceived {
  type: 'CASH_EXECUTION_RECEIVED';
  executionId: string;
}

export function cashExecutionReceived(executionId: string): CashExecutionReceived {
  return {
    type: 'CASH_EXECUTION_RECEIVED',
    executionId,
  };
}

export interface CashFillReportReceived {
  type: 'CASH_FILL_REPORT_RECEIVED';
  executionId: string;
  tradeIds: readonly string[];
}

export function cashFillReportReceived(
  executionId: string,
  tradeIds: readonly string[],
): CashFillReportReceived {
  return {
    type: 'CASH_FILL_REPORT_RECEIVED',
    executionId,
    tradeIds,
  };
}

export interface CashExecutionFailed {
  type: 'CASH_EXECUTION_FAILED';
  streamError: IStreamError;
  executionId: string;
}

export function cashExecutionFailed(
  executionId: string,
  streamError: IStreamError,
): CashExecutionFailed {
  return {
    type: 'CASH_EXECUTION_FAILED',
    streamError,
    executionId,
  };
}

export interface CashExecutionSentSucceeded {
  type: 'CASH_EXECUTION_SENT_SUCCEEDED';
  executionId: string;
}

export function cashExecutionSentSucceeded(executionId: string): CashExecutionSentSucceeded {
  return {
    type: 'CASH_EXECUTION_SENT_SUCCEEDED',
    executionId,
  };
}

export interface CashRfsExecutionSent {
  type: 'CASH_RFS_EXECUTION_SENT';
  cashId: string;
  quoteId: string;
  executionId: string;
  execution: IFxCashExecutionData & IFxExecutionPendingState;
  instrument: 'Cash';
}

export function cashRfsExecutionSent(
  cashId: string,
  quoteId: string,
  executionId: string,
  execution: IFxCashExecutionData & IFxExecutionPendingState,
): CashRfsExecutionSent {
  return {
    type: 'CASH_RFS_EXECUTION_SENT',
    cashId,
    quoteId,
    executionId,
    execution,
    instrument: 'Cash',
  };
}

export interface CashEspExecutionSent {
  type: 'CASH_ESP_EXECUTION_SENT';
  cashId: string;
  /** This is the Multipass ESP quote identifier */
  quoteId: string;
  executionId: string;
  execution: IFxCashExecutionData & IFxExecutionPendingState;
  instrument: 'Cash';
}

export function cashEspExecutionSent(
  cashId: string,
  quoteId: string,
  executionId: string,
  execution: IFxCashExecutionData & IFxExecutionPendingState,
): CashEspExecutionSent {
  return {
    type: 'CASH_ESP_EXECUTION_SENT',
    cashId,
    quoteId,
    executionId,
    execution,
    instrument: 'Cash',
  };
}

export interface CashPropertiesChanged {
  type: 'CASH_PROPERTIES_CHANGED';
  cashId: string;
  patch: Partial<IFxCashInputs>;
}

export function cashPropertiesChanged(
  cashId: string,
  patch: Partial<IFxCashInputs>,
): CashPropertiesChanged {
  return {
    type: 'CASH_PROPERTIES_CHANGED',
    cashId,
    patch,
  };
}
export interface CashPropertiesRemoveError {
  type: 'CASH_PROPERTIES_REMOVE_ERROR';
  cashId: string;
  keys: ReadonlyArray<keyof IFxCashValues>;
}

export function cashPropertiesRemoveError(
  cashId: string,
  keys: ReadonlyArray<keyof IFxCashValues>,
): CashPropertiesRemoveError {
  return {
    type: 'CASH_PROPERTIES_REMOVE_ERROR',
    cashId,
    keys,
  };
}

export interface CashPropertiesPristine {
  type: 'CASH_PROPERTIES_PRISTINE';
  cashId: string;
  fieldName: keyof IFxCashValues;
}

export function cashPropertiesPristine(
  cashId: string,
  fieldName: keyof IFxCashValues,
): CashPropertiesPristine {
  return {
    type: 'CASH_PROPERTIES_PRISTINE',
    cashId,
    fieldName,
  };
}

export interface CashPropertiesDirty {
  type: 'CASH_PROPERTIES_DIRTY';
  cashId: string;
  fieldName: keyof IFxCashValues;
}

export function cashPropertiesDirty(
  cashId: string,
  fieldName: keyof IFxCashValues,
): CashPropertiesDirty {
  return {
    type: 'CASH_PROPERTIES_DIRTY',
    cashId,
    fieldName,
  };
}

export interface CashAmountCurrencyMaskChanged {
  type: 'CASH_AMOUNT_CURRENCY_MASK_CHANGED';
  cashId: string;
  amountCurrency: CurrencyChoice;
}

export function cashAmountCurrencyMaskChanged(
  cashId: string,
  amountCurrency: CurrencyChoice,
): CashAmountCurrencyMaskChanged {
  return {
    type: 'CASH_AMOUNT_CURRENCY_MASK_CHANGED',
    cashId,
    amountCurrency,
  };
}

export interface CashPropertiesReceived {
  type: 'CASH_PROPERTIES_RECEIVED';
  quoteId: string;
  patch: FxCashPatch;
  isReadyToPrice: boolean | null;
  isPriceObsolete: boolean;
  idVersion: number;
}

export function cashPropertiesReceived(
  quoteId: string,
  patch: FxCashPatch,
  idVersion: number,
  isReadyToPrice: boolean | null,
  isPriceObsolete: boolean,
): CashPropertiesReceived {
  return {
    type: 'CASH_PROPERTIES_RECEIVED',
    quoteId,
    patch,
    isReadyToPrice,
    isPriceObsolete,
    idVersion,
  };
}

export interface CashPropertiesRequested {
  type: 'CASH_PROPERTIES_REQUESTED';
  cashId: string;
  sessionId: string;
}

export function cashPropertiesRequested(
  cashId: string,
  sessionId: string,
): CashPropertiesRequested {
  return {
    type: 'CASH_PROPERTIES_REQUESTED',
    cashId,
    sessionId,
  };
}

export interface CashRfsRequestedEpic {
  type: 'CASH_RFS_REQUESTED_EPIC';
  cashId: string;
  previousStreamId?: string | null;
}

export function cashRfsRequestedEpic(
  cashId: string,
  previousStreamId?: string | null,
): CashRfsRequestedEpic {
  return {
    type: 'CASH_RFS_REQUESTED_EPIC',
    cashId,
    previousStreamId,
  };
}

export interface CashRfsStarted {
  type: 'CASH_RFS_STARTED';
  cashId: string;
  streamId: string;
  rfsStartedAt: Date;
}

export function cashRfsStarted(
  cashId: string,
  streamId: string,
  rfsStartedAt: Date,
): CashRfsStarted {
  return {
    type: 'CASH_RFS_STARTED',
    cashId,
    streamId,
    rfsStartedAt,
  };
}

export interface CashRfsFailed {
  type: 'CASH_RFS_FAILED';
  cashId: string;
  streamId: string;
  error: IStreamError;
}

export function cashRfsFailed(
  cashId: string,
  streamId: string,
  error: IStreamError,
): CashRfsFailed {
  return {
    type: 'CASH_RFS_FAILED',
    cashId,
    streamId,
    error,
  };
}

export interface CashRfsCancelEpic {
  type: 'CASH_RFS_CANCEL_EPIC';
  cashId: string;
  streamId: string;
}

export function cashRfsCancelEpic(cashId: string, streamId: string): CashRfsCancelEpic {
  return {
    type: 'CASH_RFS_CANCEL_EPIC',
    cashId,
    streamId,
  };
}

export interface CashRfsCancel {
  type: 'CASH_RFS_CANCEL';
  cashId: string;
  streamId: string;
}

export function cashRfsCancel(cashId: string, streamId: string): CashRfsCancel {
  return {
    type: 'CASH_RFS_CANCEL',
    cashId,
    streamId,
  };
}

export interface CashRfsCancelSent {
  type: 'CASH_RFS_CANCEL_SENT';
  quoteId: string;
  streamId: string;
}

export function cashRfsCancelSent(quoteId: string, streamId: string): CashRfsCancelSent {
  return {
    type: 'CASH_RFS_CANCEL_SENT',
    quoteId,
    streamId,
  };
}

export interface CashRfsCancelFailed {
  type: 'CASH_RFS_CANCEL_FAILED';
  cashId: string;
  streamId: string;
}

export function cashRfsCancelFailed(cashId: string, streamId: string): CashRfsCancelFailed {
  return {
    type: 'CASH_RFS_CANCEL_FAILED',
    cashId,
    streamId,
  };
}

export interface CashQuoteReceived {
  type: 'CASH_QUOTE_RECEIVED';
  cashId: string;
  streamId: string;
  quote: IQuote;
  tiering: string | null;
}

export function cashQuoteReceived(
  cashId: string,
  streamId: string,
  quote: IQuote,
  tiering: string | null,
): CashQuoteReceived {
  return {
    type: 'CASH_QUOTE_RECEIVED',
    cashId,
    streamId,
    quote,
    tiering,
  };
}

export interface CashRfsTerminated {
  type: 'CASH_RFS_TERMINATED';
  cashId: string;
  streamId: string;
}

export function cashRfsTerminated(cashId: string, streamId: string): CashRfsTerminated {
  return {
    type: 'CASH_RFS_TERMINATED',
    cashId,
    streamId,
  };
}
export interface CashRfsClearError {
  type: 'CASH_RFS_CLEAR_ERROR';
  quoteId: string;
}

export function cashRfsClearError(quoteId: string): CashRfsClearError {
  return { type: 'CASH_RFS_CLEAR_ERROR', quoteId };
}
