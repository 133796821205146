import { Component } from 'react';
import { noop } from '@sgme/fp';
import type { RenderPropChildren } from 'typings/utils';
import { getSeparatorByLocale, isAccelerator } from 'utils/numberInputConstants';
import { formatNumberAsStringToLocale } from 'utils/numberInputFormats';
import {
  insertDigitKey,
  insertAcceleratorKey,
  copy,
  changeSign,
} from 'utils/numberInputKeyHandlers';
import { deriveStateOnChangedProps, type PreviousProps } from 'utils/stateProps';

type Copy = (selectionStart: number, selectionEnd: number) => string;
type Cut = (
  selectionStart: number,
  selectionEnd: number,
  done: (position: number | null, isDirty: boolean) => void,
) => string;

type KeyPress = (
  key: string,
  selectionStart: number,
  selectionEnd: number,
  done: (position: number | null, isDirty: boolean) => void,
) => void;

type Paste = (
  keys: readonly string[],
  selectionStart: number,
  selectionEnd: number,
  done: (position: number | null, isDirty: boolean) => void,
) => void;

interface DecimalKeyProps {
  decimal: string;
  withAccelerators?: boolean;
  allowNegative?: boolean;
  integerOnly?: boolean;
  locale?: string;
  autofocus?: boolean;
}
interface DecimalKeyState extends PreviousProps<DecimalKeyProps> {
  value: string;
  valueLocale: string;
}
export interface DecimalKeyChildrenProps {
  currentValue: string;
  valueLocale: string;
  onKeyDown: KeyPress;
  onPaste: Paste;
  onCopy: Copy;
  onCut: Cut;
  reset(cb?: (v: string) => void): void;
}

export class DecimalKey extends Component<
  RenderPropChildren<DecimalKeyChildrenProps> & DecimalKeyProps,
  DecimalKeyState
> {
  public static getDerivedStateFromProps = deriveStateOnChangedProps(nextDecimalKeyState);
  public state = decimalKeyStateFromProps(this.props);
  private get dirty() {
    return this.state.value !== this.props.decimal;
  }
  private onCopy: Copy = (selectionStart, selectionEnd) => {
    const sep = getSeparatorByLocale(this.props.locale);
    const edit = { ...this.state, ...sep, selectionStart, selectionEnd };
    const clipboard = copy(edit);
    return clipboard;
  };
  private onCut: Cut = (selectionStart, selectionEnd, done) => {
    const sep = getSeparatorByLocale(this.props.locale);
    const edit = { ...this.state, ...sep, selectionStart, selectionEnd };
    const clipboard = copy(edit);
    const { cursorPosition, state } = insertDigitKey({ ...edit, key: '' });
    this.setState(
      () => state,
      () => done(cursorPosition, this.dirty),
    );
    return clipboard;
  };
  private onPaste: Paste = (keys, selectionStart, selectionEnd, done) => {
    const sep = getSeparatorByLocale(this.props.locale);
    const {
      value,
      valueLocale,
      selectionStart: position,
    } = keys
      .filter(key => key === sep.localeDecimalSeparator || isDecimal(key))
      .reduce(
        (edit, key) => {
          const { cursorPosition, state } = insertDigitKey({
            ...edit,
            key: isDecimalSeparator(key) ? '.' : key,
          });
          return {
            ...edit,
            selectionEnd: cursorPosition,
            selectionStart: cursorPosition,
            ...state,
          };
        },
        { selectionStart, selectionEnd, ...this.state, ...sep },
      );
    this.setState(
      () => ({ value, valueLocale }),
      () => done(position, this.dirty),
    );
  };
  private onKeyDown: KeyPress = (key, selectionStart, selectionEnd, done) => {
    const edit = {
      key,
      selectionStart,
      selectionEnd,
      value: this.state.value,
      valueLocale: this.state.valueLocale,
      ...getSeparatorByLocale(this.props.locale),
    };
    if (isDecimal(key)) {
      const { cursorPosition, state } = insertDigitKey(edit);
      this.setState(
        () => state,
        () => done(cursorPosition, this.dirty),
      );
    } else if (isDecimalSeparator(key) && this.props.integerOnly !== true) {
      const { cursorPosition, state } = insertDigitKey({ ...edit, key: '.' });
      this.setState(
        () => state,
        () => done(cursorPosition, this.dirty),
      );
    } else if (isMinus(key) && this.props.allowNegative === true) {
      const { cursorPosition, state } = changeSign({ ...edit });
      this.setState(
        () => state,
        () => done(cursorPosition, this.dirty),
      );
    } else if (isAccelerator(key) && this.props.withAccelerators !== false) {
      const state = insertAcceleratorKey({
        key,
        value: this.state.value,
        ...getSeparatorByLocale(this.props.locale),
      });
      if (state !== null) {
        this.setState(() => state);
      }
    } else if (key === 'Backspace') {
      const start =
        selectionStart === selectionEnd && selectionStart !== 0 ? selectionEnd - 1 : selectionStart;
      const { cursorPosition, state } = insertDigitKey({
        ...edit,
        selectionStart: start,
        key: '',
      });
      this.setState(
        () => state,
        () => done(cursorPosition, this.dirty),
      );
    } else if (key === 'Delete') {
      const end = selectionStart === selectionEnd ? selectionEnd + 1 : selectionEnd;
      const { cursorPosition, state } = insertDigitKey({
        ...edit,
        selectionEnd: end,
        key: '',
      });
      this.setState(
        () => state,
        () => done(cursorPosition, this.dirty),
      );
    }
  };
  private reset = (cb: (v: string) => void = noop) => {
    this.setState(
      (_, props) => decimalKeyStateFromProps(props),
      () => cb(this.state.value),
    );
  };
  private get renderProps(): DecimalKeyChildrenProps {
    const { value: currentValue, valueLocale } = this.state;
    const { onKeyDown, onPaste, onCopy, onCut, reset } = this;
    const callbacks = { onKeyDown, onPaste, onCopy, onCut, reset };
    return { currentValue, valueLocale, ...callbacks };
  }
  public render() {
    return this.props.children(this.renderProps);
  }
}

function isDecimalSeparator(key: string) {
  return key === '.' || key === 'Decimal' || key === ',';
}

function isDecimal(key: string) {
  return key.length === 1 && key <= '9' && key >= '0';
}

function isMinus(key: string) {
  return key === '-';
}

function decimalKeyStateFromProps(props: Readonly<DecimalKeyProps>): DecimalKeyState {
  const { locale, decimal } = props;
  const { localeDecimalSeparator, localeThousandSeparator } = getSeparatorByLocale(locale);
  const valueLocale = formatNumberAsStringToLocale(
    decimal,
    localeThousandSeparator,
    localeDecimalSeparator,
  );
  return { value: decimal, valueLocale };
}

function nextDecimalKeyState(
  props: Readonly<DecimalKeyProps>,
  state: Readonly<DecimalKeyState>,
): DecimalKeyState {
  if (props.autofocus === true) {
    return state;
  }
  return decimalKeyStateFromProps(props);
}
