import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { onKeyDownWith, onPasteWith } from './emailsInputHandlers';
import { useIntl } from 'react-intl';
import * as e2e from 'components/Form/Inputs/e2e';
import { SizeContext } from 'components/Form/Inputs/SizeContext';
import { formControlClassName } from 'components/Form/Inputs/className';
import { add, adds, remove } from './emailsInputActions';
import type { EmailsInputProps, MappingValues } from './emailsInputModels';
import { assertUnreachable } from '@sgme/fp';

const EmailsDisplayContainer = styled.div.attrs({
  className: 'text-small overflow-auto',
  'data-nodrag': true,
})`
  max-height: 15vh;
  scroll-behavior: smooth;
`;

const IconButton: React.FunctionComponent<{
  icon: string;
  e2eHandle: string;
  onClick(): void;
}> = ({ icon, e2eHandle, onClick }) => (
  <button
    className="btn btn-sm btn-icon"
    type="button"
    onClick={onClick}
    data-e2e={e2e.button(e2eHandle)}
  >
    <i className="icon">{icon}</i>
  </button>
);

const scrollToBottom = (ref: React.RefObject<HTMLElement>) => {
  const element = ref.current!;
  setImmediate(() => {
    element.scrollTop = element.scrollHeight;
  });
};

export const EmailsInput: React.FunctionComponent<EmailsInputProps> = ({
  ref,
  live,
  e2eHandle,
  notificationsType,
  emails,
  dispatch,
  onSubmit,
  onsubmitWithSplit,
}) => {
  const size = useContext(SizeContext);
  const formControlClass = `${formControlClassName(size)} p-1 h-auto`;
  const badgeClass = `badge${size === 'sm' ? '' : ' badge-lg'} sgbs-badge-default pl-1 mr-1 mb-1`;

  const { formatMessage } = useIntl();
  const placeholder = useMemo(() => formatMessage({ id: 'emails.placeholder' }), [formatMessage]);

  const inputRef = useRef<HTMLInputElement>(null);
  const emailsContainerRef = useRef<HTMLDivElement>(null);

  const mappingValues: MappingValues = useMemo(() => {
    switch (notificationsType) {
      case 'orders':
        return {
          actionType: { add: 'addOrder', adds: 'addsOrder', remove: 'removeOrder' },
          typeOfEmail: 'emailsOrder',
        };
      case 'options':
        return {
          actionType: { add: 'addOptions', adds: 'addsOptions', remove: 'removeOptions' },
          typeOfEmail: 'emailsOptions',
        };
      case 'default':
        return {
          actionType: { add: 'add', adds: 'adds', remove: 'remove' },
          typeOfEmail: 'emailsDefault',
        };
      case 'cash':
        return {
          actionType: { add: 'addCash', adds: 'addsCash', remove: 'removeCash' },
          typeOfEmail: 'emailsCash',
        };
      default:
        assertUnreachable(notificationsType, 'one email type is not handled');
    }
  }, [notificationsType]);

  const onKeyPress = useCallback(
    onKeyDownWith(email => {
      if (live) {
        if (notificationsType === 'default') {
          onSubmit([...emails, email]);
        } else {
          onsubmitWithSplit!([...emails, email], mappingValues?.typeOfEmail);
        }
      }
      dispatch(add(email, mappingValues?.typeOfEmail, mappingValues?.actionType));
      scrollToBottom(emailsContainerRef);
    }),
    [emails],
  );

  const onPaste = useCallback(
    onPasteWith(newEmails => {
      if (live) {
        if (notificationsType === 'default') {
          onSubmit([...emails, ...newEmails.filter(mail => !emails.includes(mail))]);
        } else {
          onsubmitWithSplit!(
            [...emails, ...newEmails.filter(mail => !emails.includes(mail))],
            mappingValues?.typeOfEmail,
          );
        }
      }
      dispatch(adds(newEmails, mappingValues?.typeOfEmail, mappingValues?.actionType));
      scrollToBottom(emailsContainerRef);
    }),
    [emails],
  );

  const onEditEmail = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const input = inputRef.current!;
    if (input.value !== '') {
      return;
    }
    const emailToEdit = e.currentTarget.parentElement!.dataset.value!;
    dispatch(remove(emailToEdit, mappingValues?.typeOfEmail, mappingValues?.actionType));
    input.value = emailToEdit;
    input.focus();
  }, []);

  const onRemoveEmail = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const emailToRemove = e.currentTarget.parentElement!.dataset.value!;
      if (live) {
        if (notificationsType === 'default') {
          onSubmit(emails.filter(email => email !== emailToRemove));
        } else {
          onsubmitWithSplit!(
            emails.filter(email => email !== emailToRemove),
            mappingValues?.typeOfEmail,
          );
        }
      }
      dispatch(remove(emailToRemove, mappingValues?.typeOfEmail, mappingValues?.actionType));
    },
    [dispatch, emails, live, notificationsType, mappingValues, onSubmit, onsubmitWithSplit],
  );

  const onCancelChanges = useCallback(() => {
    if (notificationsType === 'default') {
      onSubmit(null);
    } else {
      onsubmitWithSplit!(null, mappingValues?.typeOfEmail);
    }
  }, [notificationsType, mappingValues?.typeOfEmail, onSubmit, onsubmitWithSplit]);

  const onSubmitChanges = useCallback(() => {
    const input = inputRef.current;
    const submittedEmails =
      input === null || input.value === '' ? emails : [...emails, input.value];
    if (notificationsType === 'default') {
      onSubmit(submittedEmails);
    } else {
      onsubmitWithSplit!(submittedEmails, mappingValues?.typeOfEmail);
    }
  }, [emails, notificationsType, mappingValues?.typeOfEmail, onSubmit, onsubmitWithSplit]);

  return (
    <>
      <div className={formControlClass} ref={ref}>
        <EmailsDisplayContainer ref={emailsContainerRef}>
          {emails.map((mail, index) => (
            <span
              className={badgeClass}
              key={index}
              data-e2e={e2e.chip('email-notification')}
              data-value={mail}
            >
              <i
                className="icon cursor-pointer mr-1"
                onClick={onRemoveEmail}
                data-e2e={e2e.button('remove-email-notification')}
              >
                close
              </i>
              <span onClick={onEditEmail} className="cursor-default">
                {mail}
              </span>
            </span>
          ))}
        </EmailsDisplayContainer>
        <input
          type="text"
          name="newEmail"
          ref={inputRef}
          autoFocus
          autoComplete="none"
          className="w-100 bg-transparent border-0 text-primary"
          data-e2e={e2e.input(e2eHandle)}
          onKeyDown={onKeyPress}
          onPaste={onPaste}
          placeholder={placeholder}
        />
      </div>
      {live ? null : (
        <div className="d-flex justify-content-end">
          <IconButton icon="close" e2eHandle="cancel" onClick={onCancelChanges} />
          <IconButton icon="check" e2eHandle="validate" onClick={onSubmitChanges} />
        </div>
      )}
    </>
  );
};
