import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import type { DispatchWithThunkExt } from 'state';
import type { AppState } from 'state/model';

import { isUserPreferenceOpened } from 'state/userPreferences/selectors/userPreferenceSelectors';
import { userPreferenceToggleModal } from 'state/userPreferences';
import { UserPreferencesContents } from 'components/UserPreferences';
import * as e2e from 'components/Form/Inputs/e2e';
import styled from 'styled-components';

interface UserPreferencesModalStateProps {
  open: boolean;
}

interface UserPreferencesModalDispatchProps {
  close: () => void;
}

const CloseButton = styled.button.attrs({
  type: 'button',
  className: 'close position-absolute p-2 m-2',
})`
  top: 0;
  right: 0;
`;

const UserPreferencesModalRaw: React.FunctionComponent<
  UserPreferencesModalStateProps & UserPreferencesModalDispatchProps
> = ({ open, close }) => (
  <Modal className="modal-lg p-0" isOpen={open} toggle={close} data-e2e="user-preference">
    <CloseButton aria-label="Close" data-e2e={e2e.button('close-user-preference')} onClick={close}>
      <i className="icon" aria-hidden="true">
        close
      </i>
    </CloseButton>
    <UserPreferencesContents />
  </Modal>
);

const mapStateToProps = (state: AppState): UserPreferencesModalStateProps => ({
  open: isUserPreferenceOpened(state),
});

const mapDispatchToProps = (dispatch: DispatchWithThunkExt): UserPreferencesModalDispatchProps => ({
  close: () => dispatch(userPreferenceToggleModal(false)),
});

export const UserPreferencesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPreferencesModalRaw);

export default UserPreferencesModal;
