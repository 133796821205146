export type SmartRfsAction = CreateSmartRfsRequestedEpic | CreateSmartRfsDone;

export interface CreateSmartRfsRequestedEpic {
  type: 'CREATE_SMART_RFS_REQUESTED_EPIC';
  productStringRepresentation: string;
  sessionId: string;
}

export function createSmartRfsRequestedEpic(
  productStringRepresentation: string,
  sessionId: string,
): CreateSmartRfsRequestedEpic {
  return { type: 'CREATE_SMART_RFS_REQUESTED_EPIC', productStringRepresentation, sessionId };
}

export interface CreateSmartRfsDone {
  type: 'CREATE_SMART_RFS_DONE';
  success: boolean;
}

export function createSmartRfsDone(success: boolean): CreateSmartRfsDone {
  return { type: 'CREATE_SMART_RFS_DONE', success };
}
