import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { isDefinedAndNonEmpty } from '@sgme/fp';

export interface MifidButtonConnectOwnProps {
  quoteId: string;
}

export interface MifidButtonConnectStateProps {
  isDisabled: boolean;
  hasBeenUsed: boolean;
}

export interface MifidButtonConnectDispatchProps {
  onRecordPrice(): void;
}

// you can whitelist selectors after implementation
export type MifidButtonConnectSelectors = Selectors;
/*
export type MifidButtonConnectSelectorsKeys = 'aSelector'; // example
export type MifidButtonConnectSelectors = Pick<Selectors, MifidButtonConnectSelectorsKeys>;
*/

export const mapStateToPropsMifidButton: MapStateToPropsHOF<
  MifidButtonConnectStateProps,
  MifidButtonConnectOwnProps,
  AppState,
  MifidButtonConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const stream = sl.getTileRfsStream(state, quoteId);
    const isDisabled = stream === null || stream.status !== 'PRICING';

    const priceRecords = sl.getTilePriceRecords(state, quoteId);
    return {
      isDisabled,
      hasBeenUsed: isDefinedAndNonEmpty(priceRecords),
    };
  };

// you can whitelist action creators after implementation
export type MifidButtonConnectActionCreators = ActionCreators;
/*
export type MifidButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type MifidButtonConnectActionCreators = Pick<ActionCreators, MifidButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsMifidButton: MapDispatchToPropsHOF<
  MifidButtonConnectDispatchProps,
  MifidButtonConnectOwnProps,
  MifidButtonConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onRecordPrice() {
      dispatch(ac.recordPriceEpic(quoteId));
    },
  });
