import { type PropsWithChildren, useEffect, useId, useRef } from 'react';
import { label, checkBox } from 'components/Form/Inputs/e2e';

interface CheckboxInputProps {
  value: boolean;
  disabled?: boolean;
  e2eHandle: string;
  className?: string;
  indeterminate?: true;
  onChange: (value: boolean) => void;
}

export const CheckboxInput: React.FunctionComponent<PropsWithChildren<CheckboxInputProps>> = ({
  value,
  disabled = false,
  className = '',
  indeterminate = false,
  e2eHandle,
  children,
  onChange,
}) => {
  const onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);
  const ref = useRef<HTMLInputElement>(null);
  const id = useId();
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);
  return (
    <div className={`custom-control custom-checkbox ${className}`} data-e2e={e2eHandle}>
      <input
        className="custom-control-input"
        checked={value}
        type="checkbox"
        onChange={onCheckBoxChange}
        id={id}
        disabled={disabled}
        ref={ref}
        data-e2e={checkBox(e2eHandle)}
      />
      <label htmlFor={id} className="custom-control-label" data-e2e={label(e2eHandle)}>
        {children}
      </label>
    </div>
  );
};
