import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import type {
  GridStateCollection,
  GridIdleState,
  GridLayout,
  GridItemPosition,
} from '../gridLayoutModels';
import { addKey, updateKey, removeKey } from 'utils/stateMap';
import { gridStatePatcher } from './gridStatePatcher';

export const defaultLayout: GridLayout = {
  columns: [[]],
  gridItemSizes: {},
  gridItemPositions: {},
};

const defaultAddTilePositions: readonly GridItemPosition[] = [
  {
    top: 0,
    left: 0,
  },
];

export const defaultGridState: GridIdleState = {
  draggingStatus: 'IDLE',
  gridLayout: defaultLayout,
  addTilePositions: defaultAddTilePositions,
};

const defaultGridCollection: GridStateCollection = {};

const gridLayoutAuthorizedInstruments: ReadonlyArray<InstrumentChoice | 'Order'> = [
  'Cash',
  'Option',
  'Swap',
  'TargetAccumulator',
  'ForwardAccumulator',
];
export const gridCollectionReducer: Reducer<GridStateCollection> = (
  gridLayoutCollection = defaultGridCollection,
  action: Action,
) => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TAB_ADDED':
      return addKey(gridLayoutCollection, action.tabId, defaultGridState);

    case 'CLIENTWORKSPACE_TAB_REMOVED':
      return removeKey(gridLayoutCollection, action.tabId);
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      if (gridLayoutAuthorizedInstruments.includes(action.instrument)) {
        return updateKey(gridLayoutCollection, action.clientWorkspaceId, gridStatePatcher(action));
      }
      break;
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      const { instrument, productName } = action.savedTile;
      const instrumentChoice: InstrumentChoice =
        instrument !== 'Accumulator'
          ? instrument
          : productName === 'FxForwardAccumulator'
          ? 'ForwardAccumulator'
          : 'TargetAccumulator';
      if (gridLayoutAuthorizedInstruments.includes(instrumentChoice)) {
        return updateKey(gridLayoutCollection, action.clientWorkspaceId, gridStatePatcher(action));
      }
      break;
    case 'CLIENTWORKSPACE_TILE_DELETED':
      if (
        gridLayoutCollection[action.clientWorkspaceId]!.gridLayout.gridItemPositions[
          action.tileId
        ] === undefined
      ) {
        return gridLayoutCollection;
      }
      return updateKey(gridLayoutCollection, action.clientWorkspaceId, gridStatePatcher(action));
    case 'GRID_ITEM_SIZE_CHANGED':
    case 'GRID_ITEM_DRAG':
    case 'GRID_ITEM_DRAG_END':
      return updateKey(gridLayoutCollection, action.gridId, gridStatePatcher(action));
  }
  return gridLayoutCollection;
};
