import { isDefined } from '@sgme/fp';
import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';

export function swapTileClosed(swapId: string, automatically = false): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, swapId)) {
      return;
    }
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, swapId);
    dispatch(ac.swapRfsCancelThunk(swapId));
    dispatch(ac.clientWorkspaceTileDeleted(swapId, tabId, automatically));
  };
}

export function swapClientChangedThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    // always cancel pending rfs if it exists
    dispatch(ac.swapRfsCancelThunk(swapId));
    const nearPrice = fieldData(sl.getSwapNearPrice(getState(), swapId)).data;
    if (isDefined(nearPrice)) {
      dispatch(ac.swapPropertyChanged(swapId, { nearPriceReference: null }));
    }
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidMargin: null,
        askMargin: null,
        bidSpotMargin: null,
        askSpotMargin: null,
      }),
    );
  };
}

export function swapTileStateCleaned(swapId: string): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.swapRfsCancelThunk(swapId, true));
  };
}
