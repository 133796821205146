import { TileHeader } from 'components/FxTileHeader';
import { CurrencyList } from 'components/CurrencyPicker';
import type { TileLayoutProps } from './typings';
import { TileContainer } from './TileContainer';
import { colWidth, tileContainerPadding } from 'styles/constants';

export const TileLayout: React.FunctionComponent<TileLayoutProps> = ({
  header = <TileHeader />,
  currencyList = <CurrencyList className="h-100 position-absolute bg-lvl1 border-top" />,
  ...props
}) => {
  switch (props.layout) {
    case 'single-column':
      return (
        <TileContainer fixedColNumber cols={1}>
          <div className="bg-lvl1 d-flex flex-column w-100 m-0 p-0">
            {header}
            <div className="position-relative d-flex flex-column flex-grow-1">
              {currencyList}
              {props.col1}
            </div>
          </div>
        </TileContainer>
      );

    case 'free-width':
      return (
        <TileContainer fixedColNumber={false} height={props.height} width={props.width}>
          <div className="bg-lvl1 d-flex flex-column w-100 m-0 p-0">
            {header}
            <div className="d-flex flex-column flex-grow-1 position-relative">
              {currencyList}
              {props.children}
            </div>
          </div>
        </TileContainer>
      );

    case 'two-columns':
      return (
        <TileContainer fixedColNumber cols={2}>
          <div className="bg-lvl1 d-flex w-100 flex-row">
            <LeftCol>
              {header}
              <div className="d-flex flex-column position-relative flex-grow-1">
                {currencyList}
                {props.col1}
              </div>
            </LeftCol>
            <div className="d-flex flex-column flex-grow-1 border-left">{props.col2}</div>
          </div>
        </TileContainer>
      );

    case 'three-columns':
      return (
        <TileContainer fixedColNumber cols={3}>
          <div className="bg-lvl1 d-flex w-100 flex-row no-gutters">
            <div className="col-4">
              {header}
              <div className="d-flex flex-column position-relative flex-grow-1">
                {currencyList}
                {props.col1}
              </div>
            </div>
            <div className="d-flex flex-column col-4 border-left">{props.col2}</div>
            <div className="d-flex flex-column col-4">{props.col3}</div>
          </div>
        </TileContainer>
      );

    case 'three-columns-double-extension':
      return (
        <TileContainer fixedColNumber cols={3}>
          <div className="bg-lvl1 d-flex w-100 flex-row">
            <LeftCol>
              {header}
              <div className="d-flex flex-column position-relative flex-grow-1">
                {currencyList}
                {props.col1}
              </div>
            </LeftCol>
            <Extension>{props.col2}</Extension>
          </div>
        </TileContainer>
      );
  }
};

const leftColWidth = colWidth - 2 * tileContainerPadding;

const leftColStyle = { minWidth: leftColWidth, width: leftColWidth };

interface ChildrenProp {
  children: React.ReactNode;
}

function LeftCol({ children }: ChildrenProp): JSX.Element {
  return (
    <div style={leftColStyle} className="d-flex flex-column">
      {children}
    </div>
  );
}

const doubleExtensionWidth = 3 * colWidth - leftColWidth - 2 * tileContainerPadding;
const doubleExtensionStyle = { width: doubleExtensionWidth };

function Extension({ children }: ChildrenProp): JSX.Element {
  return (
    <div style={doubleExtensionStyle} className="border-left">
      {children}
    </div>
  );
}
