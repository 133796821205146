import { userConsentAsync } from './userConsent';
import { logger } from '../logging/logger';

interface MyWindow extends Window {
  // global variable to communicate with piwik tracking code
  _paq: any[];
}

declare let window: MyWindow;

export interface IMatomoConfig {
  piwikRootUrl: string;
  siteId: string;
}

export type Category =
  | 'Workspace'
  | 'Mifid2'
  | 'Blotter'
  | 'Cash'
  | 'Swap'
  | 'Option'
  | 'Accumulator'
  | 'TargetAccumulator'
  | 'ForwardAccumulator'
  | 'Bulk'
  | 'Order';

export type Action =
  | 'Accumulator type changed to permanent'
  | 'Change product'
  | 'Change currency pair'
  | 'Create bulk'
  | 'Import bulk'
  | 'Exclude from bulk'
  | 'Add product to bulk'
  | 'Remove product(s) from bulk'
  | 'Reset bulk'
  | 'Stream request'
  | 'Tradeable stream request'
  | 'Stream cancel'
  | 'Execution request'
  | 'Execution failure'
  | 'Execution success'
  | 'Order creation cancelled'
  | 'Order submission request'
  | 'Order submission success'
  | 'Order submission failure'
  | 'Order cancel'
  | 'Order pause'
  | 'Order resume'
  | 'Order fill now'
  | 'Order modify'
  | 'Order view'
  | 'Order click'
  | 'Add tab'
  | 'Change client'
  | 'Remove tab'
  | 'Save workspace'
  | 'Workspace lock toggled'
  | 'Switch theme'
  | 'Add tile'
  | 'Duplicate tile'
  | 'Remove tile'
  | 'Tile client changed'
  | 'Tile client reset'
  | 'Zoom tile'
  | 'Switch to horizontal leg'
  | 'Switch to vertical leg'
  | 'Group strategy legs by expiry'
  | 'Ungroup strategy legs'
  | 'Notional currency pairs link toggled'
  | 'Legs premiums pin toggled'
  | 'Solving requested in compact view'
  | 'Solving requested in strategy view'
  | 'Reduce tile'
  | 'Open preferences'
  | 'Preference changed'
  | 'Open MIFID2 panel'
  | 'Close MIFID2 panel'
  | 'Price recorded'
  | 'Open blotter'
  | 'Close blotter'
  | 'Add leg to option'
  | 'Duplicate option leg'
  | 'Remove leg from option'
  | 'Import option legs'
  | 'Import TA schedule'
  | 'Paste in option strategy'
  | 'Option stripping request'
  | 'Strike shortcut used'
  | 'Order template created'
  | 'Order template updated'
  | 'Order template applied'
  | 'Order template deleted'
  | 'Open tile from blotter'
  | 'Open cambist calendar'
  | 'Pin client'
  | 'Unpin client'
  | 'Move client up'
  | 'Move client down'
  | 'Amount ref overriden in option strategy'
  | 'Hedge forward'
  | 'Custom hedge'
  | 'Call / Put'
  | 'Straddle'
  | 'Strangle'
  | 'Risk Reversal'
  | 'Volatility has been overriden'
  | 'Calendar overridden';

export interface Matomo {
  init(login: string): void;

  track(category: Category, event: Action, additionalData?: string): void;

  setWithUserConsent(): void;

  setWithoutUserConsent(): void;

  setCustomVariable(
    index: 1 | 2 | 3 | 4 | 5,
    name: 'locale' | 'theme',
    value: string,
    scope: 'visit' | 'page',
  ): void;

  trackPageView(): void;
}

export class MatomoUtils implements Matomo {
  private _matomoSourceUrl: string;
  private _login: string | undefined;
  private _matomoScriptId = 'matomo-script-id';

  constructor(config: IMatomoConfig) {
    const currentDomain = window.location.hostname;
    window._paq = [];
    window._paq.push(['setDomains', [currentDomain]]);
    window._paq.push(['requireCookieConsent']);
    window._paq.push(['setTrackerUrl', config.piwikRootUrl + 'piwik.php']);
    window._paq.push(['setSiteId', config.siteId]);
    this._matomoSourceUrl = config.piwikRootUrl + 'piwik.js';
  }

  private appendMatomoScript(): void {
    const matomoScript = document.createElement('script');
    matomoScript.type = 'text/javascript';
    matomoScript.async = true;
    matomoScript.defer = true;
    matomoScript.src = this._matomoSourceUrl;
    matomoScript.id = this._matomoScriptId;

    const firstDocumentScriptNode = document.getElementsByTagName('script')[0];
    if (firstDocumentScriptNode === null || firstDocumentScriptNode.parentNode === null) {
      logger.logError('Can not inject piwik source code into document');
      return;
    }
    firstDocumentScriptNode.parentNode.insertBefore(matomoScript, firstDocumentScriptNode);
  }

  public init(login: string) {
    this._login = login;
    window._paq.push(['setUserId', this._login]);

    userConsentAsync.then(isOk => {
      if (isOk) {
        this.setWithUserConsent();
      } else {
        this.setWithoutUserConsent();
      }
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      this.appendMatomoScript();
    });
  }

  public setWithUserConsent() {
    window._paq.push(['rememberCookieConsentGiven', 4320]);
    window._paq.push(['setCustomDimension', 10, true]);
  }

  public setWithoutUserConsent() {
    window._paq.push(['forgetCookieConsentGiven']);
    window._paq.push(['setCustomDimension', 10, false]);
  }

  private trackEvent(category: Category, eventName: string, additionalData?: string) {
    const data = ['trackEvent', category, eventName];
    if (additionalData !== undefined) {
      data.push(additionalData);
    }
    window._paq.push(data);
    return;
  }

  public track(category: Category, event: Action, additionalData?: string): void {
    this.trackEvent(category, event, additionalData);
  }

  public setCustomVariable(
    index: 1 | 2 | 3 | 4 | 5,
    name: 'locale' | 'theme',
    value: string,
    scope: 'visit' | 'page',
  ): void {
    window._paq.push(['setCustomVariable', index, name, value, scope]);
  }
  public trackPageView() {
    window._paq.push(['trackPageView']);
  }
}
