import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { autoCloseGrowlAfterChoices, type AutoCloseGrowlAfter } from 'state/userPreferences';
import { connectUserPref } from './connect';
import type { UserPrefConnectProps } from './connect/connectUserPref';
import * as e2e from 'components/Form/Inputs/e2e';
import { UserPrefLabel } from './UserPrefLabel';

const NEVER = 'never';

const options = autoCloseGrowlAfterChoices.map((choice, index) => (
  <FormattedMessage
    id={`userPreferencesForm.autoCloseGrowlAfter.${choice === null ? NEVER : 'after'}`}
    key={index}
    values={choice === null ? undefined : { seconds: choice / 1000 }}
  >
    {label => (
      <option
        value={choice ?? NEVER}
        key={choice}
        data-e2e={e2e.itemSelector(choice ? choice.toString() : NEVER)}
      >
        {label}
      </option>
    )}
  </FormattedMessage>
));

const AutoCloseGrowlAfterSelect: React.FunctionComponent<
  UserPrefConnectProps<'autoCloseGrowlAfter'>
> = ({ userPrefValue, onChangeUserPref }) => {
  const onChangeAutoCloseAfter = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      onChangeUserPref(value === NEVER ? null : (Number(value) as AutoCloseGrowlAfter));
    },
    [onChangeUserPref],
  );
  return (
    <UserPrefLabel label="autoCloseGrowlAfter">
      <select
        className="form-control w-auto"
        value={userPrefValue ?? NEVER}
        onChange={onChangeAutoCloseAfter}
        data-e2e={e2e.selector('autoCloseGrowlAfter')}
      >
        {options}
      </select>
    </UserPrefLabel>
  );
};

export const AutoCloseGrowlAfterPref =
  connectUserPref('autoCloseGrowlAfter')(AutoCloseGrowlAfterSelect);
