import type { UserPrefConnectProps } from './connect/connectUserPref';
import { SwitchInput } from 'components/share/formElements/SwitchInput';
import { FormattedMessage } from 'react-intl';
import type { UserPreferencesData } from 'state/userPreferences';
import { connectUserPref } from './connect';
import type { GetKeysExtending } from 'utils/object';
import { isDefined } from '@sgme/fp';
import { UncontrolledTooltip } from 'reactstrap';

type BooleanUserPreferencesKeys = GetKeysExtending<UserPreferencesData, boolean>;

const CheckboxUserPrefInput =
  <K extends BooleanUserPreferencesKeys>(userPrefKey: K) =>
  ({ userPrefValue, onChangeUserPref, tooltipId }: UserPrefConnectProps<K>): JSX.Element => {
    const tooltipTargetId = tooltipId?.replaceAll('.', '_');

    return (
      <div className="card-body d-flex align-items-center justify-content-between p-3 px-md-4 border-bottom">
        <SwitchInput
          value={userPrefValue}
          onChange={onChangeUserPref as (value: boolean) => void}
          e2eHandle={userPrefKey}
        >
          <FormattedMessage id={`userPreferencesForm.${userPrefKey}.label`} />

          {isDefined(tooltipId) && isDefined(tooltipTargetId) && (
            <>
              <i className="icon icon-sm ml-2" id={tooltipTargetId}>
                info_outline
              </i>

              <UncontrolledTooltip
                placement="bottom"
                target={tooltipTargetId}
                style={{ maxWidth: 400 }}
                autohide={false}
              >
                <FormattedMessage id={tooltipId} />
              </UncontrolledTooltip>
            </>
          )}
        </SwitchInput>
      </div>
    );
  };

export const CheckboxUserPref = <K extends BooleanUserPreferencesKeys>(userPrefKey: K) =>
  connectUserPref(userPrefKey)(CheckboxUserPrefInput(userPrefKey));
