import type { SendHelpStatus } from 'state/globalError/globalErrorModel';
import { FormattedMessage } from 'react-intl';
import { sendHelpRequest, type HelpRequestConfig } from 'api/help';
import { assertUnreachable } from '@sgme/fp';
import { logger } from 'logging/logger';
import { Component } from 'react';

interface IHelpButtonOwnProps {
  labelId?: string;
  config?: HelpRequestConfig;
  includeLastActions?: boolean;
  includeState?: boolean;
  screenshot?: string;
  details(): any;
  statusUpdateCallBack?(status: SendHelpStatus | null): void;
}

interface IHelpButtonState {
  sendHelpStatus: SendHelpStatus | null;
}

export class HelpButton extends Component<IHelpButtonOwnProps, IHelpButtonState> {
  constructor(props: IHelpButtonOwnProps) {
    super(props);
    this.state = { sendHelpStatus: null };
  }

  private sendHelp = () => {
    this.updateStatus('sending');
    sendHelpRequest({
      config: this.props.config || 'general',
      details: stringifyIfNeeded(this.props.details()),
      screenshot: this.props.screenshot,
      includeLastActions: this.props.includeLastActions,
      includeState: this.props.includeState,
    })
      .then(() => this.updateStatus('success'))
      .catch(reason => {
        logger.logError('Could not send help request : {reason_s}', JSON.stringify(reason));
        this.updateStatus('failed');
      });
  };

  private updateStatus = (sendHelpStatus: SendHelpStatus | null) => {
    this.setState({ sendHelpStatus });
    if (this.props.statusUpdateCallBack) {
      this.props.statusUpdateCallBack(sendHelpStatus);
    }
  };

  public render() {
    switch (this.state.sendHelpStatus) {
      case null:
        return (
          <button type="button" className="btn btn-primary" onClick={this.sendHelp}>
            <FormattedMessage id={this.props.labelId ?? 'modals.helpbutton.send'} />
          </button>
        );
      case 'failed':
        return (
          <>
            <span className="text-danger pb-1">
              <FormattedMessage
                id="modals.helpbutton.fail"
                values={{
                  mailLink: <a href="mailto:sgmarketsfx@sgcib.com">sgmarketsfx@sgcib.com</a>,
                }}
              />
            </span>
            <button type="button" className="btn btn-icon-text btn-danger" onClick={this.sendHelp}>
              <i className="icon">close</i>
              <FormattedMessage id={this.props.labelId ?? 'modals.helpbutton.send'} />
            </button>
          </>
        );
      case 'sending':
        return (
          <button type="button" className="btn btn-icon-text" disabled>
            <div className="mr-2 spinner spinner-sm"></div>
            <FormattedMessage id="modals.helpbutton.sending" />
          </button>
        );
      case 'success':
        return (
          <span className="text-success mx-2">
            <i className="icon mr-1">check</i>
            <FormattedMessage id="modals.helpbutton.success" />
          </span>
        );
      default:
        assertUnreachable(this.state.sendHelpStatus, 'Unhandled state for HelpButton');
    }
  }
}

const stringifyIfNeeded = (value: any): string => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(value, null, 2);
};
