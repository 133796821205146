import type React from 'react';
import { connectTileStatus } from './connect';
import { withQuoteId } from 'components/contexts';

interface QuoteStatusProps {
  hasError: boolean;
  isEmpty: boolean;
  error?: React.ReactElement;
  empty?: React.ReactElement;
  children: React.ReactNode;
}

export const QuoteStatusRaw: React.FunctionComponent<QuoteStatusProps> = ({
  hasError,
  isEmpty,
  error,
  empty,
  children,
}) =>
  hasError && error ? error : isEmpty && empty ? empty : (children as React.ReactElement<any>);

export const QuoteStatus = withQuoteId(connectTileStatus(QuoteStatusRaw));
