import { getMatomo } from 'bootstrap/matomo';
import { logger } from 'logging/logger';

export const themeKey = 'sgmeFxTheme';

export type Theme = 'DARK' | 'LIGHT';

const cdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${window.sgbsVersion}/`;

function variableSgbsUrls(theme: string | null) {
  const variation = theme === 'DARK' ? 'dark' : 'standard';
  return {
    sgbs: `${cdnUrl}core/sg-bootstrap-${variation}.min.css`,
    aggrid: `${cdnUrl}ag-grid-theme/27-${variation}.min.css`,
  };
}

function updateThemeUrls(theme: 'DARK' | 'LIGHT') {
  Object.entries(variableSgbsUrls(theme)).forEach(([id, url]) => {
    const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
    const newLinkElement = document.createElement('link');
    newLinkElement.rel = 'stylesheet';
    newLinkElement.id = id;
    newLinkElement.href = url;
    if (linkElement !== null) {
      newLinkElement.onload = () => {
        document.head.removeChild(linkElement);
      };
    }
    document.head.appendChild(newLinkElement);
  });
}

export function setTheme(theme: Theme) {
  updateThemeUrls(theme);
  logger.logInformation('User switched theme: {theme_s}', theme);
  getMatomo().track('Workspace', 'Switch theme', theme);
  if (localStorage) {
    try {
      localStorage.setItem(themeKey, theme);
    } catch {
      logger.logError('Unable to store theme, localStorage might be full');
    }
  }
}

export function getTheme(): Theme {
  const params = new URLSearchParams(self?.location.search);
  const savedTheme = typeof localStorage !== 'undefined' ? localStorage.getItem(themeKey) : 'LIGHT';
  const selectedTheme = params.get('theme') ?? savedTheme;
  const isDark = selectedTheme?.toUpperCase() === 'DARK';

  return isDark ? 'DARK' : 'LIGHT';
}
