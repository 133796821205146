import thunk from 'redux-thunk';
import type { ExtraArgument, DispatchExt } from 'state';
import { selectors } from 'state/selectors';
import { type Action, actionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { makeEpicMiddleware } from 'epics/makeMiddleware';
import { sgmeHttp } from './http';
import { type Store, applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from 'state/rootReducer';
import { getStreams } from './streams';
import { crashStatus } from 'circuitBreaker';
import { actionNotifier } from 'redux-middlewares/actionNotificationMiddleware';
import { matomoHandlerInitializer } from './reduxMiddlewares/matomoHandler';
import {
  logActionMiddleware,
  crashOnActionMiddleware,
  catchMiddleware,
  rememberLastActionsMiddleware,
  autosendHelpRequestMiddleware,
} from './reduxMiddlewares';
import { circuitBreakerMiddleware } from 'redux-middlewares/circuitBreakerMiddleware';
import { v4 as getNewGuid } from 'uuid';
import { metaSelectors } from 'epics/metaSelectors';
import { createFxApi } from 'api';
import { isDev } from '../../configuration';
import { circuitBreakerTimer } from 'utils/Timer/Timers';
import { getMatomo } from './matomo';
import { appBootstrapped } from 'state/globalActions';

let _store: Store<AppState> & DispatchExt;

const thunkExtraArgument: ExtraArgument = {
  selectors,
  metaSelectors,
  actionCreators,
  getNewGuid,
};

const lastActions: Action[] = [];
export function getLastActions(): readonly Action[] {
  return [...lastActions].reverse();
}

export function getStore() {
  if (_store === undefined) {
    // think twice before changing order
    const fxApi = createFxApi(sgmeHttp);
    const epicMiddleware = makeEpicMiddleware(fxApi);
    const middlewares = [
      circuitBreakerMiddleware(circuitBreakerTimer(crashStatus)),
      catchMiddleware,
      thunk.withExtraArgument(thunkExtraArgument),
      epicMiddleware,
      logActionMiddleware,
      rememberLastActionsMiddleware(lastActions),
      autosendHelpRequestMiddleware(isDev),
      crashOnActionMiddleware,
    ];

    if (window.sgmeConfiguration.piwik !== null) {
      const utils = getMatomo();
      middlewares.push(actionNotifier({ didMatch: matomoHandlerInitializer(utils) }));
    }

    const _compose =
      import.meta.env.DEBUG === 'profiling'
        ? compose
        : (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;
    const enhancer = _compose(applyMiddleware(...middlewares));

    _store = createStore<AppState, Action, DispatchExt, {}>(rootReducer, enhancer);

    import('epics').then(({ makeRootEpic }) => {
      epicMiddleware.run(makeRootEpic(getStreams()));
      _store?.dispatch(appBootstrapped());
    });
  }
  return _store;
}
