import type { MapStateToMetadataHOF } from "typings/redux-utils";
import type {
  FxOptionMultilegModelChanges,
  OptionHedgesStoreModelChanges,
  OptionStoreModelChanges,
  TypedStrategyOptionLegsStoreModelChanges,
  VanillaOptionLegsModelChanges,
  VanillaOptionLegsStoreModelChanges
} from "./tradeCaptureModel";
import type { Selectors } from "state/selectors";
import type { AppState } from "state/model";
import type {
  IFxOptionTypedStrategyLegInputsWithSubLegs,
  OptionPropertyChanged,
  OptionRemoveGroup,
  OptionStrategyTypeChanged,
  OptionTileOpenFromBlotter,
  OptionTileOpenFromBlotterEpic,
  OptionTileReset,
  OptionTileRestoreEpic
} from "state/fxOptions/actions/optionProduct";

import type {
  OptionLegAdded,
  OptionLegDuplicated,
  OptionLegPropertyChanged,
  OptionLegRemoved,
  OptionLegsImported,
  OptionLegsPremiumPinToggled,
  OptionLegsPropertyChanged
} from "state/fxOptions/actions/optionLegs";

import type { OptionSolvingWanted, OptionStrikeSolved } from "state/fxOptions/actions/optionSolving";

import type { OptionLegSavedTile, OptionSavedTile, OptionVanillaLegSavedTile } from "api/workspaceService/model";
import { fieldData } from "utils/fieldSelectors";
import type {
  FxOptionLegInputs,
  IFxOptionTypedStrategyLegInputs,
  IFxVanillaLegInputs,
  LegType
} from "state/fxOptions/model/optionsLegs";
import { mergeValues, unflattenObject, updateStateLegKeyToTradeCaptureLegKey } from "./utils/patchBuilder";
import type { OptionType, PriceType } from "state/fxOptions/fxOptionsModel";
import { addTileIdToLegKey, updateLegIdsWithNewTileId } from "../../../state/fxOptions/utilities";
import { clearUndefined } from "../../../utils/clearUndefined";
import { getOptionLegInputs } from "../../../state/share/productMappers/optionMappers";
import { isDefined, isDefinedAndNonEmpty, isEmpty } from "@sgme/fp";
import type { IFxHedgeInputs } from "state/fxOptions/model/optionHedges";
import type { OptionHedgePropertyChanged } from "../../../state/fxOptions/actions";
import type { CurrencyChoice } from "state/share/productModel/litterals";
import { FIRST_CURRENCY } from "state/share/productModel/litterals";

type TradeCapturePatchBuilderFromActionSelectorsKeys =
  | 'getUserPreferenceData'
  | 'getLegIdsOfMultilegOfOption'
  | 'getOptionCurrencyPair'
  | 'getOptionNextLegId'
  | 'getOptionLegProductName'
  | 'getOptionVanillaLegMarketPlace'
  | 'getOptionVanillaLegSettlementType'
  | 'getOptionVanillaLegCashSettlementCurrency'
  | 'getOptionVanillaLegPremiumTypeString'
  | 'getOptionVanillaLegPremiumCurrency'
  | 'getOptionPremiumDateTenor'
  | 'getOptionPremiumDate'
  | 'getDefaultPriceTypeForOption'
  | 'getOptionVanillaLegExpiry'
  | 'getOptionRfsStreamId'
  | 'getOptionLastStreamId'
  | 'getOptionDisplayPriceType'
  | 'getOptionVanillaLegPremiumBid'
  | 'getOptionVanillaLegPremiumAsk'
  | 'getProductNameAndSublegIdsOfAllLegsOfOptionById'
  | 'getOptionLegPremiumFromStream'
  | 'getFxOptionMultilegOfOption'
  | 'getAllVanillaLegsOfOptionWithId'
  | 'getFirstVanillaLegIdOfOption'
  | 'getOptionVanillaLegMarkup'
  | 'getOptionVanillaLegFixingReference1'
  | 'getOptionVanillaLegPremiumDate'
  | 'getOptionVanillaLegDelivery'
  | 'getOptionVanillaLegVolatilityBid'
  | 'getOptionVanillaLegVolatilityAsk'
  | 'getOptionVanillaLegExpiryTenor'
  | 'getOptionVanillaLegSide'
  | 'getOptionVanillaLegStrike'
  | 'getOptionVanillaLegNotional'
  | 'getOptionVanillaLegNotionalCurrency'
  | 'getOptionVanillaLegOptionType'
  | 'getFeatureToggles';

export type TradeCapturePatchBuilderFromActionSelectors = Pick<
  Selectors,
  TradeCapturePatchBuilderFromActionSelectorsKeys
>;

type MapStateToActionChanges<TAction> = MapStateToMetadataHOF<
  [string, OptionStoreModelChanges],
  TAction,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
>;
type MapStateToActionArrayChanges<TAction> = MapStateToMetadataHOF<
  ReadonlyArray<[string, OptionStoreModelChanges]>,
  TAction,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
>;

export const legChangedToPatchWith: MapStateToActionChanges<OptionLegPropertyChanged> =
  sl => (state, action) => {
    const legPatch = {
      [`${action.legId}`]: action.patch,
    };

    const patch: OptionStoreModelChanges = {
      legs: patchLegsFromAction(sl, state, action.optionId, legPatch),
      hedges: {},
    };

    return [action.optionId, patch];
  };

export const legsChangedToPatchWith: MapStateToActionChanges<OptionLegsPropertyChanged> =
  sl => (state, action) => {
    const patch: OptionStoreModelChanges = {
      legs: patchLegsFromAction(sl, state, action.optionId, action.patch),
      hedges: {},
    };

    return [action.optionId, patch];
  };

export const optionStrikeSolvedToPatchWith: MapStateToActionChanges<OptionStrikeSolved> =
  sl => (state, action) => {
    const legsPatch = action.strikes.reduce((acc, [legId, strike]) => {
      if (strike === null) {
        return acc;
      }

      acc[legId] = {
        strike,
        premiumBid: '',
        premiumAsk: '',
      };

      return acc;
    }, {} as Record<string, Partial<IFxVanillaLegInputs>>);

    const patch: OptionStoreModelChanges = {
      legs: patchLegsFromAction(sl, state, action.optionId, legsPatch),
      hedges: {},
    };

    return [action.optionId, patch];
  };

export const optionPropertyChangedToPatchWith: MapStateToActionChanges<OptionPropertyChanged> =
  sl => (state, action) => {
    const { hedges, legs, ...otherPatch } = action.patch;

    const patch: OptionStoreModelChanges = {
      ...otherPatch,
      // TODO Handle action.patch.legs exists, see fxOptionMultileg doc => section warnings
      legs: patchLegsFromAction(sl, state, action.optionId, legs),
      hedges: patchHedgesFromAction(hedges),
    };

    return [action.optionId, patch];
  };

export const optionHedgePropertyChangedToPatchWith: MapStateToActionChanges<
  OptionHedgePropertyChanged
> = sl => (state, action) => {
  const isMultiHedgesEnabled = sl.getFeatureToggles(state).optionTradeCaptureMultiHedges;
  const [optionId, hedgeId] = action.hedgeId.split('/');

  const patch: OptionStoreModelChanges = {
    ...(isMultiHedgesEnabled ? {} : getHedgeOptionPatch(action.patch)),
    // TODO Handle action.patch.legs exists, see fxOptionMultileg doc => section warnings
    legs: patchLegsFromAction(sl, state, optionId, {}),
    hedges: isMultiHedgesEnabled ? getHedgePatch(hedgeId, action.patch) : {},
  };
  return [optionId, patch];
};

const getHedgeOptionPatch = (hedges: Partial<IFxHedgeInputs>) => {
  const hedgePatch = clearUndefined({
    hedgeNotional: hedges.amount,
    hedgeNotionalCurrency: hedges.currency,
    hedgePrice: hedges.rate,
  });

  return hedgePatch;
};
const getHedgePatch = (
  hedgeId: string,
  hedge: Partial<IFxHedgeInputs>,
): OptionHedgesStoreModelChanges => {
  const hedgePatch = {
    productName: 'FxOptionHedge' as const,
    currentCurrency: hedge.currency as CurrencyChoice, // TODO 4288 FIRST_CURRENCY as CurrencyChoice,
    ...clearUndefined(hedge),
  };

  return { [hedgeId]: hedgePatch };
};

export const optionStrategyTypeChangedToPatchWith: MapStateToActionChanges<
  OptionStrategyTypeChanged
> = sl => (state, action) => {
  let [firstLegId] = sl.getLegIdsOfMultilegOfOption(state, action.optionId);
  const { defaultHedgeType } = sl.getUserPreferenceData(state);
  const defaultPriceType = sl.getDefaultPriceTypeForOption(state, action.optionId);

  const execArray = /([^\/]+)$/.exec(firstLegId);
  if (isDefinedAndNonEmpty(execArray)) {
    firstLegId = execArray[0];
  }

  const legs =
    action.strategyType === 'Vanilla'
      ? createOptionStrategyTypeChangedPathVanillaLeg(
          firstLegId,
          action.optionType,
          defaultPriceType,
        )
      : createOptionStrategyTypeChangedPathTypedStrategyLegs(
          firstLegId,
          action.strategyType,
          defaultPriceType,
        );

  const patch: OptionStoreModelChanges = {
    currencyPair: fieldData(sl.getOptionCurrencyPair(state, action.optionId)).data,
    hedgeType: defaultHedgeType,
    legs: {
      ['0']: {
        productName: 'FxOptionMultileg',
        legs,
      },
    },
    hedges: {},
  };

  return [action.optionId, patch];
};

const createDefaultVanillaLeg = (defaultPriceType: PriceType): VanillaOptionLegsModelChanges =>
  ({
    productName: 'Vanilla',
    premiumTypeString: defaultPriceType,
    premiumCurrency: 1,
  } as const);

const createOptionStrategyTypeChangedPathVanillaLeg = (
  firstLegId: string,
  optionType: OptionType | undefined,
  defaultPriceType: PriceType,
): VanillaOptionLegsStoreModelChanges => ({
  [firstLegId]: {
    ...createDefaultVanillaLeg(defaultPriceType),
    optionType,
  },
});

const createOptionStrategyTypeChangedPathTypedStrategyLegs = (
  firstLegId: string,
  strategyType: Exclude<LegType, 'Vanilla' | 'FxOptionMultileg'>,
  defaultPriceType: PriceType,
): TypedStrategyOptionLegsStoreModelChanges => {
  const defaultVanillaLeg = createDefaultVanillaLeg(defaultPriceType);

  return {
    [firstLegId]: {
      productName: strategyType,
      legs: {
        call: { ...defaultVanillaLeg },
        put: { ...defaultVanillaLeg },
      },
    },
  };
};

export const legRemovedToPatchWith: MapStateToActionChanges<OptionLegRemoved> =
  sl => (state, action) => {
    const { value: currencyPair } = sl.getOptionCurrencyPair(state, action.optionId);
    const fxOptionMultileg = sl.getFxOptionMultilegOfOption(state, action.optionId);

    const legIdsPatch = {
      [`${action.optionId}/0`]: {
        legIds: [...fxOptionMultileg.legIds.filter(legId => legId !== action.legId)],
      },
    };

    const patch: OptionStoreModelChanges = {
      currencyPair,
      legs: patchLegsFromAction(sl, state, action.optionId, legIdsPatch),
      hedges: {},
    };

    return [action.optionId, patch];
  };

export const legGroupRemovedToPatchWith: MapStateToActionChanges<OptionRemoveGroup> =
  sl =>
  (state, { quoteId: optionId, expiry }) => {
    const { value: currencyPair } = sl.getOptionCurrencyPair(state, optionId);
    const fxOptionMultileg = sl.getFxOptionMultilegOfOption(state, optionId);

    const legIdsPatch = {
      [`${optionId}/0`]: {
        legIds: [
          ...fxOptionMultileg.legIds.filter(
            legId => (sl.getOptionVanillaLegExpiry(state, legId).value ?? 'nodate') !== expiry,
          ),
        ],
      },
    };

    const patch: OptionStoreModelChanges = {
      currencyPair,
      legs: patchLegsFromAction(sl, state, optionId, legIdsPatch),
      hedges: {},
    };

    return [optionId, patch];
  };

export const legsImportedToPatchWith: MapStateToActionChanges<OptionLegsImported> =
  sl =>
  (state, action): [optionId: string, changes: OptionStoreModelChanges] => {
    const { value: currencyPair } = sl.getOptionCurrencyPair(state, action.optionId);

    const patchLegs = action.patch.legs ?? {};

    const importedLegIds: string[] = Object.keys(patchLegs);

    const toPatchLegId = (id: string) => `${action.optionId}/0/${id}`;

    const multileg = {
      [`${action.optionId}/0`]: {
        productName: 'FxOptionMultileg',

        legIds: importedLegIds.map(toPatchLegId),
      },
    };

    const allImportedLegs = importedLegIds.reduce((legs, id) => {
      const importedLeg = patchLegs[id] as Partial<IFxVanillaLegInputs>;

      legs[toPatchLegId(id)] = clearUndefined({
        productName: 'Vanilla',

        deliveryDate: importedLeg.deliveryDate,
        expiryDate: importedLeg.expiryDate,
        notionalAmount: importedLeg.notionalAmount,
        notionalCurrency: importedLeg.notionalCurrency,
        optionType: importedLeg.optionType,
        side: importedLeg.side,
        strike: importedLeg.strike,
      });

      return legs;
    }, multileg as Record<string, Partial<IFxVanillaLegInputs>>);

    const patch: OptionStoreModelChanges = {
      currencyPair,
      legs: patchLegsFromAction(sl, state, action.optionId, allImportedLegs),
      hedges: {},
    };

    return [action.optionId, patch];
  };

export const legsPremiumPinToggledToPatchWith: MapStateToActionChanges<
  OptionLegsPremiumPinToggled
> =
  sl =>
  (state, { quoteId: optionId }) => {
    function isLegPinned(legId: string) {
      const premiumBid =
        fieldData(sl.getOptionVanillaLegPremiumBid(state, legId)).data?.toString() ?? null;
      const premiumAsk =
        fieldData(sl.getOptionVanillaLegPremiumAsk(state, legId)).data?.toString() ?? null;
      return premiumBid !== null || premiumAsk !== null;
    }

    const vanillaLegs = sl.getAllVanillaLegsOfOptionWithId(state, optionId);
    const vanillaLegsIds = vanillaLegs.map(leg => leg[0]);

    const streamId =
      sl.getOptionRfsStreamId(state, optionId) ?? sl.getOptionLastStreamId(state, optionId);
    const priceType = sl.getOptionDisplayPriceType(state, optionId);

    const allPinned = vanillaLegsIds.every(legId => isLegPinned(legId));

    const pinChanges: Record<
      string,
      Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputsWithSubLegs
    > = {};

    vanillaLegsIds.forEach(legId => {
      if (allPinned) {
        pinChanges[legId] = { productName: 'Vanilla', premiumBid: null, premiumAsk: null };
      } else if (!isLegPinned(legId)) {
        const premium = sl.getOptionLegPremiumFromStream(state, legId, streamId!, priceType);
        pinChanges[legId] = {
          productName: 'Vanilla',
          premiumBid: premium?.bid,
          premiumAsk: premium?.ask,
        };
      }
    });

    const patch: OptionStoreModelChanges = {
      legs: { ...patchLegsFromAction(sl, state, optionId, pinChanges) },
      hedges: {},
    };

    return [optionId, patch];
  };

export const legAddToPatchWith: MapStateToActionChanges<OptionLegAdded> =
  sl =>
  (state, { optionId }) => {
    const fxOptionMultileg = sl.getFxOptionMultilegOfOption(state, optionId);
    const firstLegId = sl.getFirstVanillaLegIdOfOption(state, optionId);
    const { value: currencyPair } = sl.getOptionCurrencyPair(state, optionId);
    const legIdToAdd = sl.getOptionNextLegId(state, optionId);
    const premiumDate = sl.getOptionPremiumDate(state, optionId).value;
    const premiumDateTenor = sl.getOptionPremiumDateTenor(state, optionId).value;
    const marketPlace = sl.getOptionVanillaLegMarketPlace(state, firstLegId).value;
    const premiumTypeString = sl.getOptionVanillaLegPremiumTypeString(state, firstLegId).value;
    const premiumCurrency = sl.getOptionVanillaLegPremiumCurrency(state, firstLegId).value;
    const settlementType = sl.getOptionVanillaLegSettlementType(state, firstLegId).value;
    let cashSettlementCurrency;
    if (settlementType === 'Cash') {
      cashSettlementCurrency = sl.getOptionVanillaLegCashSettlementCurrency(
        state,
        firstLegId,
      ).value;
    }

    const legToAdd: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputs> = {
      [`${optionId}/0`]: {
        legIds: [...fxOptionMultileg.legIds, `${optionId}/0/${legIdToAdd}`],
      },
      [`${optionId}/0/${legIdToAdd}`]: {
        productName: 'Vanilla',
        marketPlace,
        settlementType,
        cashSettlementCurrency,
        premiumTypeString,
        premiumCurrency,
      },
    };

    const legs = patchLegsFromAction(sl, state, optionId, legToAdd);

    const patch: OptionStoreModelChanges = {
      currencyPair,
      premiumDate,
      premiumDateTenor,
      legs,
      hedges: {},
    };

    return [optionId, patch];
  };

export const legDuplicateToPatchWith: MapStateToActionChanges<OptionLegDuplicated> =
  sl =>
  (state, { optionId, legId }) => {
    const fxOptionMultileg = sl.getFxOptionMultilegOfOption(state, optionId);
    const currencyPair = sl.getOptionCurrencyPair(state, optionId).value;
    const legPatch = getOptionLegInputs(sl as Selectors, state, legId);
    const legIdToAdd = sl.getOptionNextLegId(state, optionId);
    const premiumDate = sl.getOptionPremiumDate(state, optionId).value;
    const premiumDateTenor = sl.getOptionPremiumDateTenor(state, optionId).value;

    const legToAdd: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputs> = {
      [`${optionId}/0`]: {
        legIds: [...fxOptionMultileg.legIds, `${optionId}/0/${legIdToAdd}`],
      },
      [`${optionId}/0/${legIdToAdd}`]: {
        productName: 'Vanilla',
        ...legPatch,
      },
    };

    const legs = patchLegsFromAction(sl, state, optionId, legToAdd);

    const patch: OptionStoreModelChanges = {
      currencyPair,
      premiumDate,
      premiumDateTenor,
      legs,
      hedges: {},
    };

    return [optionId, patch];
  };



export const optionTileRestoreToPatchWith: MapStateToActionArrayChanges<OptionTileRestoreEpic> =
  sl => (state, action) =>
    Object.entries(action.tiles).map(([tileId, tile]) => {
      const updatedLegs = Object.entries(tile.legs).reduce((acc, [legId, leg]) => {
        // legId with optionId/tileId are needed to properly build the patch later on
        if (isVanillaSavedLeg(leg!)) {
          acc[addTileIdToLegKey(tileId, legId)] = mapSavedVanillaLeg(
            leg,
            tile,
          ) as OptionVanillaLegSavedTile;
        } else {
          acc[addTileIdToLegKey(tileId, legId)] = {
            ...leg!,
            legIds: updateLegIdsWithNewTileId(tileId, leg!.legIds),
          };
        }

        return acc;
      }, {} as Record<string, OptionLegSavedTile>);

      const updatedHedges = Object.entries(tile.hedges).reduce((acc, [hedgeId, hedge]) => {
        acc[`${hedgeId}`] = {
          productName: 'FxOptionHedge',
          amount: isDefined(hedge?.amount) ? String(hedge?.amount) : undefined,
          currency: hedge?.currency ?? FIRST_CURRENCY,
          rate: isDefined(hedge?.rate) ? String(hedge?.rate) : undefined,
        };

        return acc;
      }, {} as OptionHedgesStoreModelChanges)

      const patch: OptionStoreModelChanges = {
        currencyPair: tile.currencyPair,
        hedgeType: tile.hedgeType,
        premiumDateTenor: tile.premiumDateTenor,
        premiumDate: tile.premiumDate,
        legs: patchLegsFromAction(sl, state, tileId, updatedLegs),
        hedges: updatedHedges,
      };

      return [tileId, patch];
    });

function mapSavedVanillaLeg(
  leg: OptionVanillaLegSavedTile,
  { priceType, priceTypeCcy }: Pick<OptionSavedTile, 'priceType' | 'priceTypeCcy'>,
): Partial<IFxVanillaLegInputs> {
  return {
    productName: 'Vanilla',
    side: leg.side,
    optionType: leg.putOrCall,
    premiumTypeString: priceType,
    premiumCurrency: priceTypeCcy,
    marketPlace: leg.marketPlace,
    settlementType: leg.settlementType,
    expiryDate: leg.expiryDate,
    expiryDateTenor: leg.expiryDateTenor,
    strike: leg.strikePrice,
    notionalAmount: leg.notionalAmount?.toString() ?? undefined,
    notionalCurrency: leg.notionalCurrency,
  };
}

export function optionTileOpenFromBlotterToPatch({
  tileId,
  tile,
}: OptionTileOpenFromBlotterEpic): [string, OptionStoreModelChanges] {
  const patch: OptionStoreModelChanges = {
    currencyPair: tile.currencyPair,
    hedgeType: tile.hedgeType,
    legs: mapBlotterOptionLegs(tile),
    hedges: {},
  };

  return [tileId, patch];
}

const mapLegValues = (tile: Partial<OptionTileOpenFromBlotter>): Partial<FxOptionLegInputs> => ({
  productName: 'Vanilla',
  premiumTypeString: tile.priceType,
  premiumCurrency: tile.premiumCurrency,
  side: tile.side,
  optionType: tile.optionType,
  expiryDate: tile.expiryDate,
  strike: tile.strike,
  notionalAmount: tile.notionalAmount,
  notionalCurrency: tile.notionalCurrency,
  deliveryDate: tile.deliveryDate,
  settlementType: tile.settlementType,
  marketPlace: tile.settlementPlace,
  premiumDate: tile.premiumDate,
});

/**
 * Function that takes an array of legs from blotter and wrap them with the right leg structure for TradeCapture
 * @param tile
 */
function mapBlotterOptionLegs(
  tile: OptionTileOpenFromBlotter,
): Record<string, FxOptionMultilegModelChanges> {
  let legs: Record<string, Partial<FxOptionLegInputs>>;

  if (isEmpty(tile.legs)) {
    legs = { ['0']: mapLegValues(tile) };
  } else {
    legs = tile.legs.reduce((acc, leg, index) => {
      const legId =
        tile.strategyType === 'Vanilla' || tile.strategyType === 'Strategy'
          ? `${index}`
          : `${leg.optionType?.toLowerCase()}`;

      acc[`${legId}`] = mapLegValues({
        ...leg,
        priceType: tile.priceType,
        premiumCurrency: tile.premiumCurrency,
      });

      return acc;
    }, {} as Record<string, Partial<FxOptionLegInputs>>);
  }

  return {
    [`0`]: {
      productName: 'FxOptionMultileg',
      legs:
        tile.strategyType === 'Vanilla' || tile.strategyType === 'Strategy'
          ? legs
          : {
              ['0']: {
                productName: tile.strategyType,
                legs,
              },
            },
    } as FxOptionMultilegModelChanges,
  };
}

export const optionTileResetToPatchWith: MapStateToActionChanges<OptionTileReset> =
  sl =>
  (state, { quoteId, currencyPair }) => {
    const { defaultHedgeType } = sl.getUserPreferenceData(state);
    const defaultPriceType = sl.getDefaultPriceTypeForOption(state, quoteId);

    const patch: OptionStoreModelChanges = {
      currencyPair,
      hedgeType: defaultHedgeType,
      hedges: {},
      legs: patchLegsFromAction(sl, state, quoteId, {
        defaultLeg: {
          premiumTypeString: defaultPriceType,
          premiumCurrency: 1,
        },
      }),
    };

    return [quoteId, patch];
  };

export const optionSolvingWantedToPatchWith: MapStateToActionChanges<OptionSolvingWanted> =
  sl => (state, action) => {
    const existingLegs = patchLegsFromAction(sl, state, action.quoteId, {
      [action.legId]: { productName: 'Vanilla', strike: '', premiumAsk: null, premiumBid: null },
    });
    const patch: OptionStoreModelChanges = {
      legs: existingLegs,
      hedges: {},
    };

    return [action.quoteId, patch];
  };

/**
 * Return a nested object that TC is waiting for a request
 * Contain the new value of the option/legs
 * @param sl
 * @param state
 * @param optionId
 * @param patch
 * @returns
 */
export function patchLegsFromAction(
  sl: TradeCapturePatchBuilderFromActionSelectors,
  state: AppState,
  optionId: string,
  patch: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputs> = {},
) {
  // Get Record of legs of target optionId
  const legsWithId = sl.getProductNameAndSublegIdsOfAllLegsOfOptionById(state.fxOptions, optionId);

  // Update legs with a patch of one or more legs - Patch is Record too
  const patchedLegs = mergeValues(legsWithId, patch);

  // Convert Record to a Nested object - TC is waiting for a nested object
  const unflattenLegs = unflattenObject(patchedLegs);

  const legIds = Object.keys(patchedLegs);

  // Remove naming key to keep only the last part (after the last "/")
  // Ex: "xxx-xxx-xxx/0/0/call" => "call"
  return updateStateLegKeyToTradeCaptureLegKey(unflattenLegs, legIds);
}

export function patchHedgesFromAction(
  patch: Record<string, Partial<IFxHedgeInputs>> = {},
): OptionHedgesStoreModelChanges {
  return Object.entries(patch).reduce((result, [hedgeId, hedge]) => {
    const [_, unflattenHedgeId ] = hedgeId.split(('/'));

    result[unflattenHedgeId] = {
      productName: 'FxOptionHedge',
      ...hedge
    };

    return result;
  }, {} as OptionHedgesStoreModelChanges)
}

function isVanillaSavedLeg(leg: OptionLegSavedTile): leg is OptionVanillaLegSavedTile {
  return leg.productName === 'Vanilla';
}
