import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import { type IFxOrderInputs, isAlgoOrder } from '../fxOrdersModel';

export function orderNotionalCurrencyChangedThunk(orderId: string, amount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: oldCurrency } = fieldData(sl.getOrderAmountCurrency(state, orderId));
    const { data: oldAmount } = fieldData(sl.getOrderAmount(state, orderId));

    const amountCurrency = ((oldCurrency % 2) + 1) as CurrencyChoice;

    if (oldAmount === null && amount === '') {
      dispatch(ac.orderAmountCurrencyChanged(orderId, amountCurrency));
    } else {
      dispatch(
        ac.orderPropertyChanged(orderId, {
          amount,
          amountCurrency,
        }),
      );
    }
  };
}

export function orderWayToggled(orderId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const way = sl.getOrderWay(state, orderId).value;

    dispatch(ac.orderPropertyChanged(orderId, { way: way === 'Buy' ? 'Sell' : 'Buy' }));
  };
}

export function orderCurrencyPairChangeThunk(quoteId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    dispatch(ac.orderPropertyChanged(quoteId, { currencyPair }));
    const state = getState();

    if (currencyPair !== undefined && currencyPair !== sl.getOrderCurrencyPair(state, quoteId).value) {
      dispatch(ac.espStreamRestartThunk(quoteId, currencyPair, undefined, undefined));
    }
  };
}

export function orderExpiryChangeThunk(quoteId: string, expiryDay: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);

    const date = parseWithCultureInfo(cultureInfo, expiryDay);
    dispatch(ac.orderPropertyChanged(quoteId, { expiryDay: date }));
  };
}

export function orderFixingDateChangeThunk(quoteId: string, fixingDateUtc: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);

    const date = parseWithCultureInfo(cultureInfo, fixingDateUtc);
    dispatch(ac.orderPropertyChanged(quoteId, { fixingDateUtc: date }));
  };
}

const defaultExpiryTime = '17:00:00';

export function orderGtcChangedThunk(quoteId: string, isGtc?: boolean): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const orderType = fieldData(sl.getOrderType(state, quoteId)).data;

    dispatch(
      ac.orderPropertyChanged(quoteId, {
        isGtc,
        expiryTime: isGtc || isAlgoOrder(orderType) ? null : defaultExpiryTime,
      }),
    );
  };
}

/**
 * Override orderPropertyChanged actions, add isInternalSales and feature flag allowOrderTakeProfitMargin
 * @TODO remove when featureToggle is enabled SGME-FX4466
 * @param quoteId {string}
 * @param patch {Partial<IFxOrderInputs>}
 * @returns {Thunk<void>}
 */
export function orderPropertyChangedTakeProfitMarginThunk(
  quoteId: string,
  patch: Partial<IFxOrderInputs>,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const isInternalSales = sl.getUserInfoUserType(state) === 'Internal Sales';
    const { orderTakeProfitMargin } = sl.getFeatureToggles(state);

    dispatch(ac.orderPropertyChanged(quoteId, { ...patch }, isInternalSales, orderTakeProfitMargin));
  };
}
