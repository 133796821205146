import { getMatomo } from 'bootstrap/matomo';

interface ICookiesConsent {
  apiId: string;
  sdkId: string;
  name: string;
  category: 'web-analytics' | 'video' | 'technical' | 'other';
  type: 'vendor' | 'purpose';
}

export interface IUserConsent extends ICookiesConsent {
  consent: boolean;
}

export interface SgFooterWidget extends Element {
  getCurrentUserConsent(): IUserConsent[] | null;

  getCurrentUserConsentAsync(): Promise<IUserConsent[]>;

  getUserConsentOnMatomo(): Promise<boolean>;
}

export let _footerWidget: SgFooterWidget;
export const onFooterSetRef = (footerWidget: SgFooterWidget | null) => {
  if (footerWidget) {
    _footerWidget = footerWidget;
    _footerWidget.addEventListener('sgwt-mini-footer--current-user-consent', () => {
      _footerWidget
        .getUserConsentOnMatomo()
        .then(_userConsentAsyncResolve)
        .catch(_userConsentAsyncReject);
    });

    _footerWidget.addEventListener('sgwt-mini-footer--user-consent-changed', () => {
      _footerWidget.getUserConsentOnMatomo().then(consent => {
        if (consent) {
          getMatomo().setWithUserConsent();
        } else {
          getMatomo().setWithoutUserConsent();
        }
      });
    });
  }
};

let _userConsentAsyncResolve: (value: boolean | PromiseLike<boolean>) => void;
let _userConsentAsyncReject: (reason?: any) => void;
export const userConsentAsync = new Promise<boolean>((resolve, reject) => {
  _userConsentAsyncResolve = resolve;
  _userConsentAsyncReject = reject;
});
