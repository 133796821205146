import { Component, type ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  FormattedMessage,
  type WrappedComponentProps as InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { isCrashError } from 'state/globalError/globalErrorSelectors';
import type { AppState } from 'state/model';
import { HelpButton } from './HelpButton';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { noop } from '@sgme/fp';
import { UncontrolledCollapsable } from 'components/share/formElements/Collapsable';
import styled from 'styled-components';
import type { SendHelpStatus } from 'state/globalError/globalErrorModel';
import { logger } from '../../logging/logger';

interface CrashModalProps {
  error: string | null;
}

interface CrashModalInternalState {
  userDetails: string;
  stackTrace: string | null;
}

const reload = () => window.location.reload();

const TechnicalDetails = styled.pre`
  min-height: 100px;
  max-height: 500px;
  overflow: auto;
`;

class CrashModalRaw extends Component<
  CrashModalProps & InjectedIntlProps,
  CrashModalInternalState
> {
  public state = {
    userDetails: '',
    stackTrace: null,
  };

  public componentDidMount() {
    if (this.props.error === null) {
      return;
    }
    try {
      mapStackTrace(this.props.error, stack => {
        const stackTrace = stack.reduce((acc, curr) => `${acc}\n${curr}`, this.props.error ?? '');
        this.setState(() => ({
          stackTrace,
        }));
      });
    } catch (e: any) {
      logger.logError('Unable to translate stack trace {message_s}', e.message);
    }
  }

  private setUserDetails = (event: ChangeEvent<HTMLTextAreaElement>) =>
    this.setState({ userDetails: event.currentTarget.value });

  private details = () =>
    this.state.userDetails !== ''
      ? `${this.state.userDetails}\n<b>Technical error:</b> ${this.props.error}`
      : null;

  private sendHelpRequestCallback = (status: SendHelpStatus) => {
    if (status === 'success') {
      reload();
    }
  };

  public render() {
    const { error, intl } = this.props;
    const { userDetails, stackTrace } = this.state;
    if (error === null) {
      return null;
    }
    return (
      <Modal isOpen toggle={noop} className="border border-danger">
        <ModalHeader tag="h4" className="text-danger">
          <FormattedMessage id="modals.crash.title" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage tagName="p" id="modals.crash.body" />
          <textarea
            className="form-control w-100"
            rows={4}
            placeholder={intl.formatMessage({
              id: 'modals.crash.userDetailsPlaceholder',
            })}
            onChange={this.setUserDetails}
            value={userDetails}
          />
          <UncontrolledCollapsable
            title={intl.formatMessage({ id: 'modals.crash.moreDetails' })}
            collapsed
            e2eHandle="details"
          >
            <TechnicalDetails className="border bg-lvl2 text-primary mt-3 p-3 text-left">
              <code>
                {stackTrace === null
                  ? error.startsWith('{') // Potentially json object
                    ? JSON.stringify(JSON.parse(error), null, 4)
                    : error
                  : `${error.substring(0, error.indexOf('\n'))}\n` + stackTrace}
              </code>
            </TechnicalDetails>
          </UncontrolledCollapsable>
        </ModalBody>
        <ModalFooter>
          {this.state.userDetails !== '' ? (
            <HelpButton
              labelId="modals.crash.sendAndReload"
              includeLastActions
              includeState
              details={this.details}
              statusUpdateCallBack={this.sendHelpRequestCallback}
            />
          ) : (
            <button type="button" className="btn btn-primary" onClick={reload}>
              <FormattedMessage id="modals.crash.reload" />
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState): CrashModalProps => ({
  error: isCrashError(state) ? state.globalError.error : null,
});

export const CrashModal = connect(mapStateToProps)(injectIntl(CrashModalRaw));
