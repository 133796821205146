import { FormattedMessage } from 'react-intl';
import type { ToastType } from 'state/toasts/toastsModel';

interface ToastProps {
  name: string;
  type: ToastType;
  values?: Record<string, string>;
  close: () => void;
}

const toastTypeToClass = (type: ToastType) => (type === 'failure' ? 'danger' : 'success');

export const Toast: React.FunctionComponent<ToastProps> = props => (
  <div className={`toast toast-${toastTypeToClass(props.type)} show`} data-e2e="toast">
    <div className="toast-header">
      <FormattedMessage id={`toasts.${props.name}.${props.type}.title`} values={props.values} />
      <button type="button" className="ml-2 mb-1 close" onClick={props.close}>
        <i className="icon">close</i>
      </button>
    </div>
    <div className="toast-body">
      <FormattedMessage id={`toasts.${props.name}.${props.type}.text`} values={props.values} />
    </div>
  </div>
);
