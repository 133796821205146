import type { ISgmeHttp } from 'utils/sgmeHttp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';
import { type TradeCaptureOptionResponseWrapper } from './tradeCapture/option/tradeCaptureOptionModel';

export interface ITradeCaptureSmartRfsApi {
  // todo-5153 do I need timeout ?
  timeout: number;
  create(sessionId: string, productStringRepresentation: string): Observable<TradeCaptureOptionResponseWrapper>;
}

export function smartRfsServiceApi(http: ISgmeHttp): ITradeCaptureSmartRfsApi {
  return {
    create(sessionId: string, productStringRepresentation: string) {
      // todo-5153 temp code to pass stringRepresentation because I'm a newbie with .net
      const endpoint = `api/tradeCapture/${sessionId}/chatMessage/${productStringRepresentation}`;
      return http
        .post<TradeCaptureOptionResponseWrapper>(endpoint, { productStringRepresentation })
        .pipe(map(extract('payload')));
    },
    // todo-5153 need this timeout ?
    timeout: 10_000,
  };
}
