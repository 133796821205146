import type { IMatomoConfig } from 'utils/matomoUtils';
import type { Theme } from 'utils/theme';
import type { ISignalRConfiguration } from '../../configuration.signalr';

export interface ISgmeConfiguration {
  sgConnect: {
    authorization_endpoint: string;
    client_id: string;
    scope: string;
    acr_values: 'L2' | 'L3';
    redirect_uri: string;
  };
  accessRequestUrl: string;
  SGWTWidgetConfiguration: {
    environment: string;
  };
  signalR: ISignalRConfiguration;
  piwik: IMatomoConfig | null;
  helpCenterSendMailto: string;
  useFakeSgConnect: boolean;
  pingIntervalMs: string;
  maxEspPingDelayMs: string;
  logIntervalMs: string;
  recommendedHedgeAmountInPercent: string;
  env: string;
  apiUrl: string;
  sgmContactsUrl: string;
  bamSearchFrequencyMs: string;
  fxtcaUrl: string;
}

export const getApiUrl = () => {
  let apiUrl = window.sgmeConfiguration.apiUrl;
  if (!apiUrl.endsWith('/')) {
    apiUrl += '/';
    window.sgmeConfiguration.apiUrl = apiUrl;
  }
  return apiUrl;
};

declare global {
  interface Window {
    sgmeConfiguration: ISgmeConfiguration;
    theme: Theme;
  }
}
