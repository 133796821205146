import type { FxExecutionsMap, FxExecutionState } from './executionsModel';
import { addKey, updateKey, removeKey } from 'utils/stateMap';
import type { Reducer } from 'redux';
import type { Action } from 'state/actions';

export const executionsReducer: Reducer<FxExecutionsMap> = (
  state: FxExecutionsMap = {},
  action: Action,
): FxExecutionsMap => {
  switch (action.type) {
    case 'CASH_ESP_EXECUTION_SENT':
    case 'CASH_RFS_EXECUTION_SENT':
    case 'SWAP_EXECUTION_SENT':
    case 'OPTION_EXECUTION_SENT':
    case 'BULK_EXECUTION_SENT':
    case 'ACCUMULATOR_EXECUTION_SENT':
      return addKey<FxExecutionState>(state, action.executionId, {
        status: 'Pending',
        ...action.execution,
      });
    case 'CASH_EXECUTION_RECEIVED':
    case 'SWAP_EXECUTION_RECEIVED':
    case 'OPTION_EXECUTION_RECEIVED':
    case 'BULK_EXECUTION_RECEIVED':
    case 'ACCUMULATOR_EXECUTION_RECEIVED':
      return updateKey<FxExecutionState>(state, action.executionId, execution =>
        execution.status === 'Pending' || execution.status === 'StillPending'
          ? { status: 'Success' }
          : null,
      );
    case 'CASH_FILL_REPORT_RECEIVED':
    case 'SWAP_FILL_REPORT_RECEIVED':
    case 'OPTION_FILL_REPORT_RECEIVED':
    case 'BULK_FILL_REPORT_RECEIVED':
    case 'ACCUMULATOR_FILL_REPORT_RECEIVED':
      return updateKey<FxExecutionState>(state, action.executionId, execution =>
        execution.status === 'Success' || execution.status === 'StillPending'
          ? action.tradeIds[0] === 'NOT_YET_INTEGRATED'
            ? { status: 'NotIntegrated' }
            : { status: 'BackOfficeValidated', tradeIds: action.tradeIds }
          : null,
      );
    case 'CASH_EXECUTION_FAILED':
    case 'SWAP_EXECUTION_FAILED':
    case 'OPTION_EXECUTION_FAILED':
    case 'BULK_EXECUTION_FAILED':
    case 'ACCUMULATOR_EXECUTION_FAILED':
      return updateKey<FxExecutionState>(state, action.executionId, execution =>
        execution.status === 'Pending' || execution.status === 'StillPending'
          ? {
              status: 'Error',
              streamError: action.streamError,
            }
          : null,
      );

    case 'EXECUTION_STILL_PENDING':
      return updateKey<FxExecutionState>(state, action.executionId, execution =>
        execution.status === 'Pending'
          ? {
              status: 'StillPending',
            }
          : null,
      );

    case 'EXECUTION_TIMEOUTED_DEAL':
      return updateKey<FxExecutionState>(state, action.executionId, execution =>
        execution.status === 'Pending'
          ? {
              status: 'TimeoutedDeal',
            }
          : null,
      );

    case 'ORDER_SUBMISSION_STILL_PENDING':
      return updateKey<FxExecutionState>(state, action.quoteId, execution =>
        execution.status === 'Pending'
          ? {
              status: 'StillPending',
            }
          : null,
      );

    case 'EXECUTION_CLEANUP':
      return removeKey(state, action.executionId);

    default:
      return state;
  }
};
