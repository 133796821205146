import type {
  BlotterOrderAllInputsKeys,
  BlotterOrderInputs,
  BlotterOrderValues,
  OrderBlotterEntry,
  OrderErrorCode,
  OrderResultMode,
} from '../blotterEntryModel';
import type { PropertyErrors } from 'state/share/productModel/litterals';
import type { OrderValidationReceivedDetails } from 'state/fxOrders/fxOrdersActions';
import type { BlotterOrderValidationDetail } from 'epics/blotter/utils';

export type BlotterOrderAction =
  | BlotterOrderCancellationEpic
  | BlotterOrderToggleMode
  | BlotterOrderResetProperty
  | BlotterOrderUpdateSuccess
  | BlotterOrderUpdateStillPending
  | BlotterOrderUpdateFailed
  | BlotterOrderErrorsReveived
  | BlotterOrderModifyEpic
  | BlotterOrderRejectedShowDialog
  | BlotterOrderRejectedHideDialog
  | BlotterOrderSaveErrorShowDialog
  | BlotterOrderSaveErrorHideDialog
  | BlotterOrderPauseEpic
  | BlotterOrderPauseSuccess
  | BlotterOrderPauseFailure
  | BlotterOrderResumeEpic
  | BlotterOrderResumeSuccess
  | BlotterOrderResumeFailure
  | BlotterOrderFillEpic
  | BlotterOrderFillSuccess
  | BlotterOrderFillFailure
  | BlotterEditedOrderValidationRequested
  | BlotterEditedOrderValidationReceived
  | BlotterEditedOrderCreateEntry
  | BlotterEditedOrderRemoveEntry
  | BlotterOrderValidationError
  | BlotterOrderOpenTile
  | BlotterEditedOrderPropertiesChanged;

export interface BlotterOrderCancellationEpic {
  type: 'BLOTTER_ORDER_CANCELLATION_EPIC';
  orderId: string;
  tileId?: string;
}

export function blotterOrderCancellationEpic(orderId: string, tileId?: string): BlotterOrderCancellationEpic {
  return {
    type: 'BLOTTER_ORDER_CANCELLATION_EPIC',
    orderId,
    tileId,
  };
}

export interface BlotterOrderToggleMode {
  type: 'BLOTTER_ORDER_TOGGLE_MODE';
  orderId: string;
  mode: OrderResultMode;
}

export function blotterOrderToggleMode(orderId: string, mode: OrderResultMode): BlotterOrderToggleMode {
  return {
    type: 'BLOTTER_ORDER_TOGGLE_MODE',
    orderId,
    mode,
  };
}

export interface BlotterOrderResetProperty {
  type: 'BLOTTER_ORDER_RESET_PROPERTY';
  orderId: string;
  field: BlotterOrderAllInputsKeys;
}

export function blotterOrderResetProperty(
  orderId: string,
  field: BlotterOrderAllInputsKeys,
): BlotterOrderResetProperty {
  return {
    type: 'BLOTTER_ORDER_RESET_PROPERTY',
    orderId,
    field,
  };
}

export interface BlotterOrderUpdateSuccess {
  type: 'BLOTTER_ORDER_UPDATE_SUCCESS';
  orderId: string;
}

export function blotterOrderUpdateSuccess(orderId: string): BlotterOrderUpdateSuccess {
  return {
    type: 'BLOTTER_ORDER_UPDATE_SUCCESS',
    orderId,
  };
}

export interface BlotterOrderUpdateStillPending {
  type: 'BLOTTER_ORDER_UPDATE_STILL_PENDING';
  orderId: string;
}

export function blotterOrderUpdateStillPending(orderId: string): BlotterOrderUpdateStillPending {
  return {
    type: 'BLOTTER_ORDER_UPDATE_STILL_PENDING',
    orderId,
  };
}

export interface BlotterOrderUpdateFailed {
  type: 'BLOTTER_ORDER_UPDATE_FAILED';
  orderId: string;
  errorCode: OrderErrorCode;
  isValidationError: boolean;
}

export function blotterOrderUpdateFailed(
  orderId: string,
  errorCode: OrderErrorCode,
  isValidationError = false,
): BlotterOrderUpdateFailed {
  return {
    type: 'BLOTTER_ORDER_UPDATE_FAILED',
    orderId,
    errorCode,
    isValidationError,
  };
}

export interface BlotterOrderErrorsReveived {
  type: 'BLOTTER_ORDER_ERRORS_RECEIVED';
  orderId: string;
  errors: PropertyErrors<BlotterOrderValues>;
}

export function blotterOrderErrorsReveived(
  orderId: string,
  errors: PropertyErrors<BlotterOrderValues>,
): BlotterOrderErrorsReveived {
  return {
    type: 'BLOTTER_ORDER_ERRORS_RECEIVED',
    orderId,
    errors,
  };
}

export interface BlotterOrderModifyEpic {
  type: 'BLOTTER_ORDER_MODIFY_EPIC';
  orderId: string;
}

export function blotterOrderModifyEpic(orderId: string): BlotterOrderModifyEpic {
  return {
    type: 'BLOTTER_ORDER_MODIFY_EPIC',
    orderId,
  };
}

export interface BlotterOrderRejectedShowDialog {
  type: 'BLOTTER_ORDER_REJECTED_SHOW_DIALOG';
  orderId: string;
}

export function blotterOrderRejectedShowDialog(orderId: string): BlotterOrderRejectedShowDialog {
  return {
    type: 'BLOTTER_ORDER_REJECTED_SHOW_DIALOG',
    orderId,
  };
}

export interface BlotterOrderRejectedHideDialog {
  type: 'BLOTTER_ORDER_REJECTED_HIDE_DIALOG';
}

export function blotterOrderRejectedHideDialog(): BlotterOrderRejectedHideDialog {
  return {
    type: 'BLOTTER_ORDER_REJECTED_HIDE_DIALOG',
  };
}

export interface BlotterOrderSaveErrorShowDialog {
  type: 'BLOTTER_ORDER_SAVE_ERROR_SHOW_DIALOG';
  quoteId?: string;
}

export function blotterOrderSaveErrorShowDialog(quoteId?: string): BlotterOrderSaveErrorShowDialog {
  return {
    type: 'BLOTTER_ORDER_SAVE_ERROR_SHOW_DIALOG',
    quoteId,
  };
}

export interface BlotterOrderSaveErrorHideDialog {
  type: 'BLOTTER_ORDER_SAVE_ERROR_HIDE_DIALOG';
}

export function blotterOrderSaveErrorHideDialog(): BlotterOrderSaveErrorHideDialog {
  return {
    type: 'BLOTTER_ORDER_SAVE_ERROR_HIDE_DIALOG',
  };
}

export interface BlotterOrderPauseEpic {
  type: 'BLOTTER_ORDER_PAUSE_EPIC';
  orderId: string;
}

export function blotterOrderPauseEpic(orderId: string): BlotterOrderPauseEpic {
  return {
    type: 'BLOTTER_ORDER_PAUSE_EPIC',
    orderId,
  };
}

export interface BlotterOrderPauseSuccess {
  type: 'BLOTTER_ORDER_PAUSE_SUCCESS';
  orderId: string;
}

export function blotterOrderPauseSuccess(orderId: string): BlotterOrderPauseSuccess {
  return {
    type: 'BLOTTER_ORDER_PAUSE_SUCCESS',
    orderId,
  };
}

export interface BlotterOrderPauseFailure {
  type: 'BLOTTER_ORDER_PAUSE_FAILURE';
  orderId: string;
}

export function blotterOrderPauseFailure(orderId: string): BlotterOrderPauseFailure {
  return {
    type: 'BLOTTER_ORDER_PAUSE_FAILURE',
    orderId,
  };
}

export interface BlotterOrderResumeEpic {
  type: 'BLOTTER_ORDER_RESUME_EPIC';
  orderId: string;
}

export function blotterOrderResumeEpic(orderId: string): BlotterOrderResumeEpic {
  return {
    type: 'BLOTTER_ORDER_RESUME_EPIC',
    orderId,
  };
}

export interface BlotterOrderResumeSuccess {
  type: 'BLOTTER_ORDER_RESUME_SUCCESS';
  orderId: string;
}

export function blotterOrderResumeSuccess(orderId: string): BlotterOrderResumeSuccess {
  return {
    type: 'BLOTTER_ORDER_RESUME_SUCCESS',
    orderId,
  };
}

export interface BlotterOrderResumeFailure {
  type: 'BLOTTER_ORDER_RESUME_FAILURE';
  orderId: string;
}

export function blotterOrderResumeFailure(orderId: string): BlotterOrderResumeFailure {
  return {
    type: 'BLOTTER_ORDER_RESUME_FAILURE',
    orderId,
  };
}

export interface BlotterOrderFillEpic {
  type: 'BLOTTER_ORDER_FILL_EPIC';
  orderId: string;
}

export function blotterOrderFillEpic(orderId: string): BlotterOrderFillEpic {
  return {
    type: 'BLOTTER_ORDER_FILL_EPIC',
    orderId,
  };
}

export interface BlotterOrderFillSuccess {
  type: 'BLOTTER_ORDER_FILL_SUCCESS';
  orderId: string;
}

export function blotterOrderFillSuccess(orderId: string): BlotterOrderFillSuccess {
  return {
    type: 'BLOTTER_ORDER_FILL_SUCCESS',
    orderId,
  };
}

export interface BlotterOrderFillFailure {
  type: 'BLOTTER_ORDER_FILL_FAILURE';
  orderId: string;
}

export function blotterOrderFillFailure(orderId: string): BlotterOrderFillFailure {
  return {
    type: 'BLOTTER_ORDER_FILL_FAILURE',
    orderId,
  };
}

// ███████╗  ██████╗   ██╗  ████████╗  ██╗   ██████╗   ███╗   ██╗
// ██╔════╝  ██╔══██╗  ██║  ╚══██╔══╝  ██║  ██╔═══██╗  ████╗  ██║
// █████╗    ██║  ██║  ██║     ██║     ██║  ██║   ██║  ██╔██╗ ██║
// ██╔══╝    ██║  ██║  ██║     ██║     ██║  ██║   ██║  ██║╚██╗██║
// ███████╗  ██████╔╝  ██║     ██║     ██║  ╚██████╔╝  ██║ ╚████║
// ╚══════╝  ╚═════╝   ╚═╝     ╚═╝     ╚═╝   ╚═════╝   ╚═╝  ╚═══╝

export interface BlotterEditedOrderValidationRequested {
  type: 'BLOTTER_EDITED_ORDER_VALIDATION_REQUESTED';
  orderId: string;
}

export function blotterEditedOrderValidationRequested(orderId: string): BlotterEditedOrderValidationRequested {
  return {
    type: 'BLOTTER_EDITED_ORDER_VALIDATION_REQUESTED',
    orderId,
  };
}

export type BlotterEditedOrderValidationReceived = {
  type: 'BLOTTER_EDITED_ORDER_VALIDATION_RECEIVED';
  orderId: string;
  allowOrderTakeProfitMargin: boolean;
} & OrderValidationReceivedDetails<BlotterOrderValidationDetail>;

export function blotterEditedOrderValidationReceived(
  orderId: string,
  details: OrderValidationReceivedDetails<BlotterOrderValidationDetail>,
  allowOrderTakeProfitMargin: boolean = false,
): BlotterEditedOrderValidationReceived {
  return {
    type: 'BLOTTER_EDITED_ORDER_VALIDATION_RECEIVED',
    orderId,
    ...details,
    allowOrderTakeProfitMargin,
  };
}
export interface BlotterEditedOrderPropertiesChanged {
  type: 'BLOTTER_EDITED_ORDER_PROPERTIES_CHANGED';
  orderId: string;
  patch: Partial<BlotterOrderInputs>;
  allowOrderTakeProfitMargin: boolean;
}

export function blotterEditedOrderPropertiesChanged(
  orderId: string,
  patch: Partial<BlotterOrderInputs>,
  allowOrderTakeProfitMargin: boolean = false,
): BlotterEditedOrderPropertiesChanged {
  return {
    type: 'BLOTTER_EDITED_ORDER_PROPERTIES_CHANGED',
    orderId,
    patch,
    allowOrderTakeProfitMargin,
  };
}

export interface BlotterEditedOrderCreateEntry {
  type: 'BLOTTER_EDITED_ORDER_CREATE_ENTRY';
  orderId: string;
  order: OrderBlotterEntry;
}

export function blotterEditedOrderCreateEntry(
  orderId: string,
  order: OrderBlotterEntry,
): BlotterEditedOrderCreateEntry {
  return {
    type: 'BLOTTER_EDITED_ORDER_CREATE_ENTRY',
    orderId,
    order,
  };
}

export interface BlotterEditedOrderRemoveEntry {
  type: 'BLOTTER_EDITED_ORDER_REMOVE_ENTRY';
  orderId: string;
}
export function blotterEditedOrderRemoveEntry(orderId: string): BlotterEditedOrderRemoveEntry {
  return {
    type: 'BLOTTER_EDITED_ORDER_REMOVE_ENTRY',
    orderId,
  };
}

export interface BlotterOrderValidationError {
  type: 'BLOTTER_ORDER_VALIDATION_ERROR';
  orderId: string;
  error: string;
}

export function blotterOrderValidationError(orderId: string, error: string): BlotterOrderValidationError {
  return {
    type: 'BLOTTER_ORDER_VALIDATION_ERROR',
    orderId,
    error,
  };
}

export interface BlotterOrderOpenTile {
  type: 'BLOTTER_ORDER_OPEN_TILE';
  orderId: string;
  mode: OrderResultMode;
  account: string | null;
  currentTabId: string;
}

export function blotterOrderOpenTile(
  orderId: string,
  mode: OrderResultMode,
  account: string | null,
  currentTabId: string,
): BlotterOrderOpenTile {
  return {
    type: 'BLOTTER_ORDER_OPEN_TILE',
    orderId,
    mode,
    account,
    currentTabId,
  };
}
